import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Paper, Grid, CssBaseline } from '@material-ui/core'
import { Widget } from '@uploadcare/react-widget'
import useStepper from '../../context/useStepper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/pro-light-svg-icons'
import { useAppContext } from '../../context/appContext'

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'bold'
  },
  docGrid: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 24
  }
}))

export default function Identification() {
  const classes = useStyles()
  const { appState } = useAppContext()
  const { updateIdUrl } = useStepper()

  return (
    <div>
      <CssBaseline />
      <Paper style={{ padding: 16, minHeight: 445 }}>
        <Typography
          variant="h4"
          align="center"
          component="h1"
          style={{ color: '#1957a6' }}
          gutterBottom
        >
          <FontAwesomeIcon icon={faAddressCard} />
          {'   '}Identification & Residency
        </Typography>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item xs={12} lg={12} xl={12}>
            <Typography variant="body1" align="center" gutterBottom>
              For your protection, CloudSolar needs to verify your identity and
              residency. Please upload identification and residency documents
              (examples shown below). CloudSolar is committed to protecting your
              data by utlizing industry standard security measures.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6} className={classes.docGrid}>
            <Typography variant="h6" align="center">
              Identification Document
            </Typography>
            <Typography variant="subtitle2" align="center" gutterBottom>
              Example: Passport, Driver's License, National ID
            </Typography>
            <Widget
              publicKey="85f4e36ea46be8ad63f0"
              id="idUrl"
              previewStep="true"
              crop
              onChange={info => updateIdUrl(info, 'identification')}
              tabs="file url gdrive gphotos dropbox evernote onedrive box"
              value={appState.user.idUrl}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6} className={classes.docGrid}>
            <Typography variant="h6" align="center">
              Residency Document
            </Typography>
            <Typography variant="subtitle2" align="center" gutterBottom>
              Example: Utility Bill, Tax Bill, Bank Statement
            </Typography>
            <Widget
              publicKey="85f4e36ea46be8ad63f0"
              id="residencyFileUrl"
              previewStep="true"
              crop
              onChange={info => updateIdUrl(info, 'residency')}
              tabs="file url gdrive gphotos dropbox evernote onedrive box"
              value={appState.user.residencyFileUrl}
            />
          </Grid>

          {/* <Grid
              item
              xs={12}
              lg={12}
              xl={12}
              style={{
                textAlign: 'center',
                fontSize: 300,
                color: 'Dodgerblue'
              }}
            > */}
          {/* <img
                  src={fields.idUrl}
                  alt="Cloud Solar"
                  style={{ maxWidth: 300, maxHeight: 350 }}
                /> */}
          {/* </Grid> */}
        </Grid>
      </Paper>
    </div>
  )
}
