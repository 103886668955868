import React, { useState } from 'react'
import {
  // Button,
  // Card,
  CardContent,
  Grid,
  // Paper,
  Typography
  // CardHeader,
  // TextField
} from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAddressCard } from '@fortawesome/pro-light-svg-icons'
import { useAppContext } from '../../../context/appContext'
// import EditIcon from '@material-ui/icons/Edit'
// import CloseIcon from '@material-ui/icons/Close'
// import Divider from '@material-ui/core/Divider'
import { pdfjs, Document, Page } from 'react-pdf'
import { replaceAt } from '../../../utils/replaceAt'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

// const useStyles = makeStyles(theme => ({
//   root: {
//     background: 'DodgerBlue',
//     marginBottom: 20
//   },
//   card: {
//     fontSize: 28,
//     fontWeight: 600,
//     color: '#3d5170',
//     padding: 16
//     // minHeight: 130
//   },
//   title: {
//     fontSize: 22,
//     color: 'DodgerBlue'
//   },
//   faIcon: {
//     fontSize: 36,
//     color: 'Dodgerblue'
//   }
// }))

export default function DisplayBankInfo(props) {
  const { appState } = useAppContext()
  const [, setNumPages] = useState(null)
  const [pageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const _renderBankNumber = () => {
    const replacementLength = appState.user.bankAccountNumber.length - 4
    const replacementStr = Array(replacementLength)
      .fill('*')
      .toString()
      .split(',')
      .join('')
    return replaceAt(appState.user.bankAccountNumber, 2, replacementStr)
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} lg={12} xl={12} md={12}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Name of Account Holder: {appState.user.bankAccountName}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Account Number: {_renderBankNumber()}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Bank Name: {appState.user.bankName}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Bank Location: {appState.user.bankLocation}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Bank Branch: {appState.user.bankBranch}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Residency Document:
          </Typography>
          {appState.user.residencyFileType === 'application/pdf' ? (
            <Document
              file={appState.user.residencyFileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={pageNumber}
                // height="600"
                // width="600"
                scale="0.9"
                renderMode="svg"
              />
            </Document>
          ) : (
            <>
              <img src={appState.user.residencyFileUrl} alt="ID" />
            </>
          )}
        </CardContent>
      </Grid>
    </Grid>
  )
}
