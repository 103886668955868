import React, { useState, useContext } from 'react'
import clsx from 'clsx'
import { makeStyles, Drawer, Button } from '@material-ui/core'
import { useHistory, Link } from 'react-router-dom'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'
import { useAppContext } from '../../context/appContext'
import MenuIcon from '@material-ui/icons/Menu'
import { Auth } from 'aws-amplify'
import { StepperContext, initState } from '../../context/StepperContext'

const useStyles = makeStyles({
  list: {
    width: 350
  },
  fullList: {
    width: 'auto'
  }
})

function HeaderMenuForMobile() {
  const classes = useStyles()
  const history = useHistory()
  const { appState, setAppState } = useAppContext()
  const [, setStepperState] = useContext(StepperContext)

  const handleLogout = async () => {
    await Auth.signOut()
    setAppState({
      ...appState,
      user: {
        isAuthenticated: false,
        isSignedUp: false,
        isConfirmed: false,
        accountMessage: '',
        sub: '',
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        parishProvinceState: '',
        country: '',
        zipPostal: '',
        phone: '',
        email: '',
        password: '',
        idUrl: '',
        idVerified: false,
        idFiileType: '',
        residencyFileType: '',
        activeStep: 99,
        activeSubStep: 0,
        autoWithdrawal: false,
        UserNotConfirmedException: false,
        bankAccountName: '',
        bankAccountNumber: '',
        residencyFileUrl: '',
        bankName: '',
        bankLocation: '',
        bankLocations: [],
        bankBranch: '',
        bankTerms: '',
        bankInfoSkipped: false,
        bankVerified: false,
        bankAccountVerified: false,
        initPurchaseAmount: 0,
        initialPanelAmount: 0,
        initialKwp: 0,
        panelLimit: 0,
        initialPricePerKw: 0,
        panelTotal: 0,
        creditsTotal: 0,
        kwpTotal: 0,
        returnsTotal: 0,
        contracts: [],
        transactions: [],
        contractsTotal: 0,
        returncount: 0,
        returnPercentage: 0
      },
      fields: {
        messageContent: '',
        icon: '',
        color: '',
        backgroundColor: '',
        displayMessage: false
      }
    })
    setStepperState(initState)
    localStorage.clear()
    history.push('/')
  }

  const [state, setState] = useState({
    right: false
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="menu-act">
        <div className="close-button">
          <Button onClick={toggleDrawer(anchor, false)}>
            <CancelPresentationIcon />
          </Button>
        </div>

        <ul>
          {appState.user.isAuthenticated ? (
            <>
              <li className="loginOuter">
                <Link color="inherit" to="/">
                  <Button color="inherit" variant="contained" className="login">
                    My Account
                  </Button>
                </Link>
              </li>
              <li className="loginOuter">
                <Link
                  color="inherit"
                  to="/contracts/"
                  onClick={() => console.log('Contracts')}
                >
                  <Button color="inherit" variant="contained" className="login">
                    Contracts
                  </Button>
                </Link>
              </li>
              <li className="loginOuter">
                <Link color="inherit" to="/profile/">
                  <Button color="inherit" variant="contained" className="login">
                    Profile
                  </Button>
                </Link>
              </li>

              <li className="loginOuter">
                <Link to="#" onClick={() => handleLogout()}>
                  <Button color="inherit" variant="contained" className="login">
                    LOGOUT
                  </Button>
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className="loginOuter">
                <Link to="/login/">
                  <Button color="inherit" variant="contained" className="login">
                    LOGIN
                  </Button>
                </Link>
              </li>

              <li className="get_startedOuter">
                <Link to="/get-started/">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="get_started"
                  >
                    GET STARTED
                  </Button>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  )

  return (
    <div className="wrapper-mobile">
      {['right'].map(anchor => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            style={{
              width: 33,
              minWidth: 33
            }}
          >
            <MenuIcon color="primary" style={{ fontSize: 44 }} />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}

export default HeaderMenuForMobile
