import React, { useEffect } from 'react'
import {
  Button,
  Grid,
  MenuItem,
  Typography,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useAppContext } from '../../../context/appContext'
import { Auth } from 'aws-amplify'
import swal from '@sweetalert/with-react'
import { Widget } from '@uploadcare/react-widget'
import { green, orange } from '@material-ui/core/colors'
import useStepper from '../../../context/useStepper'
import SaveIcon from '@material-ui/icons/Save'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'DodgerBlue',
    marginBottom: 20
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170',
    padding: 16
    // minHeight: 130
  },
  title: {
    fontSize: 22,
    color: 'DodgerBlue'
  },
  faIcon: {
    fontSize: 40,
    color: 'DodgerBlue'
  },
  faIconVerified: {
    fontSize: 40,
    color: green[500]
  },
  faIconNotVerified: {
    fontSize: 40,
    color: orange[500]
  },
  updateButton: {
    background: '#fbb116',
    border: '0 none',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.175)',
    borderRadius: '5px',
    cursor: 'pointer',
    fontFamily: 'ProximaNovaSemibold',
    fontSize: '18px',
    color: 'white',
    fontWeight: 'bold',
    height: '50px',
    lineHeight: '50px',
    padding: '0 23px',
    textDecoration: 'none',
    minWidth: '300px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#1957a6'
    }
  }
}))

export default function EditBankAccount(props) {
  const { appState, setAppState } = useAppContext()
  const { fields } = useStepper()

  useEffect(() => {
    async function onLoad() {
      let bankName = fields.banks.find(o => o.name === appState.user.bankName)
      let locations = []
      Object.keys(bankName.locations).forEach(function(key) {
        locations.push(bankName.locations[key].name)
      })
      setAppState(appState => ({
        ...appState,
        user: {
          ...appState.user,
          bankLocations: locations
        },
        update: !appState.update
      }))
    }
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateFields = e => {
    e.persist()
    setAppState(state => ({
      ...appState,
      user: {
        ...appState.user,
        [e.target.name]: e.target.value
      }
    }))
  }

  const handleSelectBankChange = e => {
    let bankName = fields.banks.find(o => o.name === e.target.value)
    let locations = []
    Object.keys(bankName.locations).forEach(function(key) {
      locations.push(bankName.locations[key].name)
    })
    setAppState(appState => ({
      ...appState,
      user: {
        ...appState.user,
        [e.target.name]: e.target.value,
        bankLocations: locations,
        bankLocation: '',
        bankBranch: '',
        bankCode: bankName.bank_code
      }
    }))
  }

  const handleSelectLocationChange = e => {
    let bankName = fields.banks.find(o => o.name === appState.user.bankName)
    let bankBranch = ''
    let bankCode = ''
    Object.keys(bankName.locations).forEach(function(key) {
      if (bankName.locations[key].name === e.target.value) {
        bankBranch = bankName.locations[key].branch
      }
    })

    setAppState(state => ({
      ...appState,
      user: {
        ...appState.user,
        [e.target.name]: e.target.value,
        bankBranch
      }
    }))
  }

  const handleChange = info => {
    setAppState(state => ({
      ...appState,
      user: {
        ...appState.user,
        residencyFileUrl: info.cdnUrl,
        residencyFileType: info.mimeType
      }
    }))
  }

  const updateBankInfo = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      const updatedUser = await Auth.updateUserAttributes(currentUser, {
        'custom:bankName': appState.user.bankName,
        'custom:bankLocation': appState.user.bankLocation,
        'custom:bankBranch': appState.user.bankBranch.toString(),
        'custom:bankCode': appState.user.bankCode,
        'custom:bankAccountName': appState.user.bankAccountName,
        'custom:bankAccountNumber': appState.user.bankAccountNumber.toString(),
        'custom:residencyFileUrl': appState.user.residencyFileUrl,
        'custom:residencyFileType': appState.user.residencyFileType
      })
      if (updatedUser === 'SUCCESS') {
        setAppState(state => ({
          ...state,
          update: !appState.update
        }))
        props.closeEdit()
        swal('Success!', 'Your bank details have been updated.', 'success')
      } else {
        swal(
          'Oh no!',
          "Something went wrong. We'll look into the issue as soon as possible but feel free to contact us.",
          'error'
        )
      }
    } catch (e) {
      console.log(e)
      swal(
        'Oh no!',
        "Something went wrong. We'll look into the issue as soon as possible but feel free to contact us.",
        'error'
      )
    }
  }

  const classes = useStyles()

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12} lg={8} xl={8}>
          <TextField
            onChange={e => handleSelectBankChange(e)}
            name="bankName"
            value={appState.user.bankName}
            label="Bank Name"
            variant="outlined"
            fullWidth
            required
            select
            InputLabelProps={{
              shrink: true
            }}
          >
            {fields.bankNames.map((item, idx) => (
              <MenuItem
                key={idx}
                value={item}
                style={{ padding: 5, fontSize: 16 }}
              >
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={8} xl={8}>
          <TextField
            value={appState.user.bankLocation}
            onChange={e => handleSelectLocationChange(e)}
            name="bankLocation"
            label="Bank Location"
            variant="outlined"
            fullWidth
            select
            required
            InputLabelProps={{
              shrink: true
            }}
          >
            {appState.user.bankLocations.map((item, idx) => (
              <MenuItem
                key={idx}
                value={item}
                style={{ padding: 5, fontSize: 16 }}
              >
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={8} xl={8}>
          <TextField
            fullWidth
            required
            disabled
            name="bankBranch"
            type="text"
            label="Bank Branch"
            variant="outlined"
            value={appState.user.bankBranch}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item xs={12} lg={8} xl={8}>
          <TextField
            fullWidth
            required
            name="bankAccountName"
            type="text"
            label="Bank Account Name"
            variant="outlined"
            onChange={e => updateFields(e)}
            value={appState.user.bankAccountName}
          />
        </Grid>
        <Grid item xs={12} lg={8} xl={8}>
          <TextField
            fullWidth
            required
            name="bankAccountNumber"
            type="text"
            label="Bank Account Number"
            variant="outlined"
            onChange={e => updateFields(e)}
            value={appState.user.bankAccountNumber}
          />
        </Grid>
        <Grid item xs={12} lg={8} xl={8}>
          <Typography
            variant="body1"
            align="left"
            gutterBottom
            style={{ marginTop: 20 }}
          >
            Need to upload a different residency document?{' '}
            <Widget
              publicKey="85f4e36ea46be8ad63f0"
              id="file"
              previewStep="true"
              crop
              onChange={info => handleChange(info)}
              tabs="file url gdrive gphotos dropbox evernote onedrive box"
              value={appState.user.residencyFileUrl}
            />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          xl={8}
          style={{
            display: 'inline-block',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Button
            variant="contained"
            size="large"
            onClick={() => updateBankInfo()}
            className={classes.updateButton}
            startIcon={<SaveIcon />}
            fullWidth
          >
            Update my bank info
          </Button>
        </Grid>

        {/* <CardContent style={{ marginTop: 40 }}>
          <img src={appState.user.residencyFileUrl} alt="ID" />
        </CardContent> */}
      </Grid>
    </>
  )
}
