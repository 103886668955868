import React from 'react'
import { useAppContext } from '../../context/appContext'
// import DashboardLoader from './DashBoardLoader'
import Home from './Home'
import { makeStyles } from '@material-ui/core/styles'
import { Container, MuiThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import BeatLoader from 'react-spinners/BeatLoader'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%'
    // backgroundColor: '#F5F7F9'
  },
  appBar: {
    backgroundColor: 'none'
  }
})

export default function Dashboard() {
  const { appState } = useAppContext()

  document.body.classList.remove(
    'ourproject',
    'question',
    'getStarted',
    'howItWork',
    'about-us',
    'log-in',
    'contact-us',
    'home'
  )
  document.body.classList.add('dashboard')

  const classes = useStyles()

  const homeTheme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: 14,
          backgroundColor: '#2b2b2b'
        },
        arrow: {
          color: '#2b2b2b'
        },
        tooltipPlacementBottom: {
          margin: '8px 0 !important'
        }
      }
    }
  })

  return appState.isLoading ? (
    <div style={{ textAlign: 'center', paddingTop: '200px' }}>
      <BeatLoader size={35} color={'DodgerBlue'} />
    </div>
  ) : (
    <div className={classes.root}>
      <Container fixed spacing={3} direction="row" justify="space-evenly">
        {/* <CssBaseline /> */}
        <MuiThemeProvider theme={homeTheme}>
          <Home />
        </MuiThemeProvider>
      </Container>
    </div>
  )
}
