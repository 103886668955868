import { useContext } from 'react'
import { DashboardContext } from './DashboardContext'
import { useAppContext } from './appContext'
import { API, Auth } from 'aws-amplify'
import useStepper from './useStepper'
import swal from '@sweetalert/with-react'

const useDashboard = () => {
  const [state, setState] = useContext(DashboardContext)
  const {
    // rates
    sendEmail
  } = useStepper()
  const { appState, setAppState } = useAppContext()

  async function getContracts() {
    return await API.get('contracts', '/contracts/user')
  }

  const mRates = new Map()
  mRates.set(1, { ppk: 5700, dc: 0.26 })
  mRates.set(2, { ppk: 5675, dc: 0.52 })
  mRates.set(3, { ppk: 5625, dc: 0.78 })
  mRates.set(4, { ppk: 5575, dc: 1.04 })
  mRates.set(5, { ppk: 5525, dc: 1.3 })
  mRates.set(6, { ppk: 5475, dc: 1.56 })
  mRates.set(7, { ppk: 5425, dc: 1.82 })
  mRates.set(8, { ppk: 5375, dc: 2.08 })
  mRates.set(9, { ppk: 5325, dc: 2.34 })
  mRates.set(10, { ppk: 5275, dc: 2.6 })
  mRates.set(11, { ppk: 5225, dc: 2.86 })
  mRates.set(12, { ppk: 5175, dc: 3.12 })
  mRates.set(13, { ppk: 5125, dc: 3.38 })
  mRates.set(14, { ppk: 5075, dc: 3.64 })
  mRates.set(15, { ppk: 5025, dc: 3.90 })
  mRates.set(16, { ppk: 4975, dc: 4.16 })
  mRates.set(17, { ppk: 4925, dc: 4.42 })
  mRates.set(18, { ppk: 4875, dc: 4.68 })
  mRates.set(19, { ppk: 4825, dc: 4.94 })
  mRates.set(20, { ppk: 4775, dc: 5.20 })
  mRates.set(21, { ppk: 4745, dc: 5.46 })
  mRates.set(22, { ppk: 4715, dc: 5.72 })
  mRates.set(23, { ppk: 4685, dc: 5.98 })
  mRates.set(24, { ppk: 4655, dc: 6.24 })
  mRates.set(25, { ppk: 4625, dc: 6.50 })
  mRates.set(26, { ppk: 4595, dc: 6.76 })
  mRates.set(27, { ppk: 4565, dc: 7.02 })
  mRates.set(28, { ppk: 4535, dc: 7.28 })
  mRates.set(29, { ppk: 4505, dc: 7.54 })
  mRates.set(30, { ppk: 4495, dc: 7.80 })

  const calculateReturn = panels => {
    const dc = mRates.get(panels).dc
    const ppk = mRates.get(panels).ppk
    const price = (ppk * dc).toFixed(2)
    const dollarReturn = dc * 1450 * 0.4275 * (1 - 0.2)
    const percentReturn = (dollarReturn / price) * 100
    let kWp = (price / ppk).toFixed(2)
    setState(state => ({
      ...state,
      pricePerKw: ppk,
      price,
      dollarReturn,
      percentageReturn: percentReturn,
      kWp
    }))
  }

  const handleDashboardInvestmentChange = (event, value) => {
    setState(state => ({
      ...state,
      panels: value
    }))

    calculateReturn(value)
  }

  // function separator(x) {
  //   x = x.toString()
  //   var pattern = /(-?\d+)(\d{3})/
  //   while (pattern.test(x)) x = x.replace(pattern, '$1,$2')
  //   return x
  // }

  function calculateReturnsTotal(transactions) {
    let total = 0
    let returnCount = 0
    if (transactions.length > 0) {
      transactions.map(t => {
        if (t.transactionType === 'Monthly Credits') {
          total += parseFloat(t.amount)
          ++returnCount
        }
        return t
      })
    }
    let returnPercentage = 0
    if (appState.user.contractsTotal > 0) {
      returnPercentage =
        (((total / returnCount) * 12) / appState.user.contractsTotal) * 100
    }

    setAppState(appState => ({
      ...appState,
      update: !appState.update,
      user: {
        ...appState.user,
        returnCount,
        returnPercentage
      }
    }))
    return total
  }

  async function purchaseContract() {
    try {
      if (parseInt(state.panels) > parseInt(appState.user.panelLimit)) {
        throw new Error(
          "Amount of panels can't be greater than your panel limit."
        )
      }
      const contract = {
        userId: appState.user.sub,
        amount: state.price,
        contractType: 'DSI',
        term: state.termValue,
        fitRate: state.fitRate,
        name: `${appState.user.firstName} ${appState.user.lastName}`,
        pricePerKw: state.pricePerKw,
        kWp: (state.price / state.pricePerKw).toFixed(2),
        panels: state.panels,
        active: false,
        activeDate: ''
      }
      const purchasePanels = await API.post('contracts', '/contracts', {
        body: contract
      })
      if (
        purchasePanels.httpStatus !== 'undefined' &&
        purchasePanels.httpStatus !== 400
      ) {
        const data = {
          email: appState.user.email,
          name: appState.user.firstName,
          type: 'newContract'
        }
        await sendEmail(data)
        const adminData = {
          email: 'info@cloud.solar',
          firstName: appState.user.firstName,
          lastName: appState.user.lastName,
          type: 'adminNewContractNotification'
        }
        await sendEmail(adminData)
        const contracts = await getContracts()
        contracts.sort(function(a, b) {
          return a.createdAt - b.createdAt
        })
        const user = await Auth.currentAuthenticatedUser()
        let kwpTotal = (
          parseFloat(user.attributes['custom:totalKwp']) +
          parseFloat(state.price / state.pricePerKw)
        )
          .toFixed(2)
          .toString()
        let totalContracts = (
          parseFloat(user.attributes['custom:totalContracts']) + 1
        ).toString()
        let panelTotal = (
          parseInt(user.attributes['custom:totalPanels']) +
          parseInt(state.panels)
        ).toString()
        let panelLimit = (
          30 -
          (parseInt(user.attributes['custom:totalPanels']) +
            parseInt(state.panels))
        ).toString()

        // contracts.forEach(contract => {
        //   contractsTotal += parseInt(contract.amount)
        //   panelTotal += parseInt(contract.panels)
        //   let kwp = parseInt(contract.amount) / parseInt(contract.pricePerKw)
        //   kwpTotal += kwp
        // })

        await Auth.updateUserAttributes(user, {
          'custom:panelLimit': panelLimit,
          'custom:totalKwp': kwpTotal,
          'custom:totalPanels': panelTotal,
          'custom:totalContracts': totalContracts
        })

        setAppState(appstate => ({
          ...appstate,
          user: {
            ...appstate.user,
            panelLimit,
            totalContracts,
            // contractsTotal,
            kwpTotal
          },
          update: !appState.update
        }))
        setState(state => ({
          ...state,
          price: 0,
          panels: 0,
          fitRate: 0.4275,
          kWp: 0,
          pricePerKw: 0,
          dollarReturn: 0,
          percentageReturn: 0
        }))
        swal(
          'Success!',
          'Your Digital Solar Panels have been submitted for payment and we will debit your bank account within several business days.',
          'success'
        )
      } else {
        swal(
          'On no, something went wrong!',
          purchasePanels.body.message,
          'error'
        )
      }
    } catch (e) {
      swal('On no, something went wrong!', e.toString(), 'error')
    }
  }

  return {
    handleDashboardInvestmentChange,
    name: state.name,
    user: state.user,
    price: state.price,
    panels: state.panels,
    contracts: state.contracts,
    panelLimit: parseInt(state.panelLimit),
    contractsTotal: state.contractsTotal,
    transactions: state.transactions,
    isLoading: state.isLoading,
    getContracts,
    purchaseContract,
    calculateReturnsTotal,
    kWp: state.kWp,
    dollarReturn: state.dollarReturn,
    fitRate: state.fitRate,
    percentageReturn: state.percentageReturn,
    pricePerKw: state.pricePerKw
    // snackBarOpen: state.snackBarOpen,
    // closeSnackBar: () => handleClose()
  }
}

export default useDashboard
