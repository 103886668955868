import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useAppContext } from '../../context/appContext'

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation()
  const { appState } = useAppContext()
  return (
    <Route {...rest}>
      {appState.user.isAuthenticated && appState.user.isSignedUp ? (
        children
      ) : (
        <Redirect to={`/login?redirect=${pathname}${search}`} />
      )}
    </Route>
  )
}
