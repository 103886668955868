import React, { useEffect, useRef, useState } from 'react'
import {
  createTheme,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DSALayout from './DSALayout'
import format from 'date-fns/format'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import CheckCircle from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'

const AcceptButton = withStyles(theme => ({
  root: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}))(Button)

const DeclineButton = withStyles(theme => ({
  root: {
    color: 'white',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700]
    }
  }
}))(Button)

// const SaveButton = withStyles(theme => ({
//   root: {
//     color: 'white',
//     backgroundColor: blue[500],
//     '&:hover': {
//       backgroundColor: blue[700]
//     }
//   }
// }))(Button)

const theme = createTheme({
  palette: {
    primary: green
  }
})

const date = format(new Date(), "do 'day of' MMMM', ' yyyy")

export default function DSADialog(props) {
  // const classes = useStyles()
  const [
    scroll
    // setScroll
  ] = useState('body')

  const descriptionElementRef = useRef(null)
  useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [props.open])

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.close}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle id="scroll-dialog-title">
          Please read and agree to the Digital Solar Agreement
        </DialogTitle>
        <DialogContent dividers={scroll === 'body'}>
          <DSALayout date={date} />
        </DialogContent>
        <DialogActions>
          <DeclineButton
            variant="outlined"
            size="small"
            onClick={props.close}
            startIcon={<CancelIcon />}
          >
            Decline
          </DeclineButton>
          <ThemeProvider theme={theme}>
            <AcceptButton
              variant="outlined"
              startIcon={<CheckCircle />}
              color="primary"
              size="small"
              onClick={props.agree}
            >
              Accept
            </AcceptButton>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </div>
  )
}
