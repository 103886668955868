import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Auth } from 'aws-amplify'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { CircularProgress, InputAdornment, makeStyles } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import validatePassword from '../../utils/passwordValidator'
import { green, red } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative'
  },
  loadingBlock: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#fff',
    cursor: 'wait',
    opacity: 0.6,
    zIndex: 2
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  errorIcon: {
    marginRight: theme.spacing(1)
  },
  warningText: {
    color: red[500]
  },
  outlineGreen: {
    borderColor: `${green[500]} !important`
  },
  outlineRed: {
    borderColor: `${red[500]} !important`
  },
  green: {
    color: green[500]
  },
  red: {
    color: red[500]
  }
}))

export default function ChangePasswordDialog(props) {
  const classes = useStyles()

  const [state, setState] = useState({
    isLoading: false,
    fields: {
      password: '',
      confirmPassword: '',
      error: false,
      errorMessage: '',
      showFields: false,
      dialogText: 'Enter your password.'
    },
    errors: {
      fields: {}
    }
  })

  const handleClose = () => {
    if (!state.isLoading) {
      props.onClose()
      clearState()
    }
  }

  function handleErrors(message) {
    setState(state => ({
      ...state,
      isLoading: false,
      fields: {
        ...state.fields,
        error: true,
        errorMessage: message
      }
    }))
  }

  function clearState() {
    setState(state => ({
      ...state,
      errors: {
        fields: {}
      },
      isLoading: false,
      fields: {
        ...state.fields,
        password: '',
        confirmPassword: '',
        error: false,
        errorMessage: '',
        showFields: false,
        dialogText: 'Enter your password.'
      }
    }))
  }

  const handleChange = e => {
    const name = e.target.name
    const value = e.target.value

    if (name === 'password') {
      const validPassword = validatePassword(value)
      setState(state => ({
        ...state,
        fields: {
          ...state.fields,
          [name]: value
        },
        errors: {
          fields: validPassword
            ? {
                ...state.errors.fields,
                password: {}
              }
            : {
                ...state.errors.fields,
                password: {
                  error: true,
                  message: 'Password is not valid.'
                }
              }
        }
      }))
      return
    }
    if (name === 'confirmPassword') {
      const validPassword = validatePassword(value)
      setState(state => ({
        ...state,
        fields: {
          ...state.fields,
          [name]: value
        },
        errors: {
          fields: validPassword
            ? {
                ...state.errors.fields,
                confirmPassword:
                  state.fields.password !== value
                    ? {
                        error: true,
                        message: 'Confirm password is not match with Password.'
                      }
                    : {}
              }
            : {
                ...state.errors.fields,
                confirmPassword: {
                  error: true,
                  message: 'Confirm password is not valid.'
                }
              }
        }
      }))
      return
    }

    setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [name]: value
      }
    }))
  }

  async function handleConfirm() {
    setState(state => ({
      ...state,
      isLoading: true
    }))
    try {
      const user = await Auth.completeNewPassword(
        props.user,
        state.fields.password,
        {}
      )
      if (user) {
        handleClose()
        props.updateMessage(
          'Your password has been set',
          <CheckCircleOutlineIcon />,
          '#4F8A10',
          '#DFF2BF'
        )
        props.onSubmit(user)
      }
    } catch (error) {
      handleErrors(error.message)
    }
  }

  const _greenCheck = () => (
    <InputAdornment position="end">
      <CheckCircleOutlineIcon className={classes.green} />
    </InputAdornment>
  )

  const _redCheck = () => (
    <InputAdornment position="end">
      <ErrorOutlineIcon className={classes.red} />
    </InputAdornment>
  )

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown={state.isLoading}
      >
        <DialogTitle id="form-dialog-title">New password?</DialogTitle>
        <DialogContent dividers>
          {state.isLoading && (
            <div className={classes.loadingBlock}>
              <CircularProgress size={24} className={classes.buttonProgress} />
            </div>
          )}
          <DialogContentText>{state.fields.dialogText}</DialogContentText>

          {state.fields.error ? (
            <div
              style={{
                margin: [10, 0],
                padding: 12,
                color: '#D8000C',
                backgroundColor: '#FFD2D2',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <ErrorOutlineIcon className={classes.errorIcon} />
              {state.fields.errorMessage}
            </div>
          ) : null}
          <div>
            <TextField
              margin="normal"
              name="password"
              label="Password"
              type="password"
              value={state.fields.password}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              error={state.errors.fields?.['password']?.error}
              helperText={
                state.errors.fields?.['password']?.error
                  ? state.errors.fields?.['password']?.message
                  : null
              }
              InputProps={{
                endAdornment: state.fields.password?.length
                  ? !state.errors.fields?.['password']?.error
                    ? _greenCheck()
                    : _redCheck()
                  : null,
                classes: {
                  notchedOutline: state.fields.password?.length
                    ? !state.errors.fields?.['password']?.error
                      ? classes.outlineGreen
                      : classes.outlineRed
                    : ''
                }
              }}
            />
            <TextField
              margin="normal"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              value={state.fields.confirmPassword}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              error={state.errors.fields?.['confirmPassword']?.error}
              helperText={
                state.errors.fields?.['confirmPassword']?.error
                  ? state.errors.fields?.['confirmPassword']?.message
                  : null
              }
              InputProps={{
                endAdornment: state.fields.confirmPassword?.length
                  ? !state.errors.fields?.['confirmPassword']?.error
                    ? _greenCheck()
                    : _redCheck()
                  : null,
                classes: {
                  notchedOutline: state.fields.confirmPassword?.length
                    ? !state.errors.fields?.['confirmPassword']?.error
                      ? classes.outlineGreen
                      : classes.outlineRed
                    : ''
                }
              }}
            />{' '}
            <DialogContentText className={classes.warningText}>
              Passwords must include an upper case letter, lower case letter, a
              number, a special symbol and must be at least 8 characters long.
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} variant="text" color="primary">
            Cancel
          </Button>
          <Button
            disabled={
              state.fields.password === '' ||
              state.errors.fields.password.error ||
              state.errors.fields.confirmPassword.error
            }
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            style={{ fontWeight: 700 }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
