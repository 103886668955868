import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardHeader, CardContent, Paper } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSackDollar } from '@fortawesome/pro-light-svg-icons'
import PanelCalculator from '../calculator'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 8
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 28,
    color: '#1957a6'
  },
  contract: {
    fontSize: 16,
    color: '#1957a6',
    marginBottom: 10
  },
  pos: {
    marginBottom: 12
  },
  faIcon: {
    fontSize: 36,
    color: 'Dodgerblue'
  },
  colorDefault: {
    backgroundColor: 'Dodgerblue'
  },
  divider: {
    background: 'DodgerBlue',
    marginBottom: 20
  },
  [theme.breakpoints.down('md')]: {
    cardContentRoot: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}))

export default function AddContracts() {
  const classes = useStyles()

  return (
    <div>
      <Paper elevation={3}>
        <Card className={classes.root}>
          <CardHeader
            avatar={
              <FontAwesomeIcon icon={faSackDollar} className={classes.faIcon} />
            }
            title="Purchase additional panels"
            classes={{
              title: classes.title
            }}
          />
          <Divider className={classes.divider} />
          <CardContent className={classes.cardContentRoot}>
            <PanelCalculator />
          </CardContent>
        </Card>
      </Paper>
    </div>
  )
}
