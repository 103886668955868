import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { useAppContext } from '../../context/appContext'
import HistoricalAccountValue from './HistoricalAccountValue'
import ListContracts from './ListContracts'
import ListTransactions from './ListTransactions'
import { Grid, Typography } from '@material-ui/core'
import CreditsTotal from './CreditsTotal'
import Returns from './Returns'
import SystemSize from './SystemSize'
import AccountType from './AccountType'
import clsx from 'clsx'
import { Link } from '@material-ui/core'
import { orange } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#F5F7F9'
  },
  h1: {
    fontSize: 70,
    fontWeight: 'bold',
    color: '#FFF',
    letterSpacing: 0.5,
    marginTop: 50
  },
  h1p1: {
    fontSize: 22,
    color: '#fff',
    lineHeight: '130%',
    fontWeight: 'normal'
  },
  heading: {
    fontSize: 18,
    color: '#1957a6'
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170',
    minHeight: 130
  },
  title: {
    fontSize: 28,
    color: '#1957a6'
  },
  accountTitle: {
    fontSize: '.40em',
    fontWeight: 800,
    color: '#3d5170'
  },
  greeting: {
    fontSize: 18,
    color: '#1957a6',
    marginTop: '14vh',
    marginBottom: '3vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: '14vh'
    }
  },
  faIcon: {
    fontSize: 36,
    color: 'Dodgerblue'
  },
  faInfo: {
    fontSize: 20,
    color: 'Dodgerblue'
  },
  card180: {
    height: 155
  },
  faIconNotVerified: {
    fontSize: 40,
    color: orange[500]
  },
  noticeIcon: {
    marginRight: 10
  },
  notice: {
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    fontSize: 30,
    color: '#1957a6'
  }
}))

export default function Home() {
  const { appState } = useAppContext()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.greeting}>
        <Typography gutterBottom variant={'h6'}>
          Hi {appState.user.firstName}, here's a glance at your account.
        </Typography>
        {!appState.user.isConfirmed ? (
          <Typography
            gutterBottom
            variant={'h6'}
            component="div"
            className={classes.notice}
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className={clsx(classes.faIconNotVerified, classes.noticeIcon)}
            />
            Email not verified.
          </Typography>
        ) : null}
      </div>
      <Grid
        container
        spacing={3}
        style={{ marginBottom: 20 }}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <CreditsTotal className={classes.card180} />
        <Returns className={classes.card180} />
        <SystemSize className={classes.card180} />
        <AccountType className={classes.card180} />
      </Grid>
      <Grid
        container
        spacing={3}
        style={{ marginBottom: 24 }}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={6} xl={3} lg={3} md={6}>
          <ListContracts />
        </Grid>
        <Grid item xs={12} sm={6} xl={3} lg={3} md={6}>
          <ListTransactions />
        </Grid>
        <Grid item xs={12} sm={12} xl={6} lg={6} md={12}>
          <HistoricalAccountValue />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        xl={12}
        lg={12}
        md={12}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Typography align="center" style={{ marginBottom: 40 }}>
          <Link
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://cloud.solar/performance"
          >
            View Cloud Solar's Platform Performance
          </Link>
        </Typography>
      </Grid>
    </div>
  )
}
