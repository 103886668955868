import React from 'react'
import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useStepper from '../../context/useStepper'
import Grid from '@material-ui/core/Grid'

export default function ErrorDialog(props) {
  const { errorDialog } = useStepper()

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{errorDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorDialog.body}</DialogContentText>
        </DialogContent>
        <Grid container direction="row" justify="center" alignItems="center">
          <DialogActions>
            <Button
              onClick={
                errorDialog.button1Action === null
                  ? props.onClose
                  : errorDialog.button1Action
              }
              color="primary"
            >
              {errorDialog.button1Text}
            </Button>
            <Button
              onClick={
                errorDialog.button2Action === null
                  ? props.onClose
                  : errorDialog.button2Action
              }
              color="primary"
            >
              {errorDialog.button2Text}
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  )
}
