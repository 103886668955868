import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import { Typography, Paper, Grid, Button, CssBaseline } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import useStepper from '../../context/useStepper'
import { useAppContext } from '../../context/appContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt } from '@fortawesome/pro-light-svg-icons'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'

const styles = theme => ({
  root: {
    fontWeight: 'bold'
  }
})

function ConfirmEmail(props) {
  const {
    confirmationCode,
    fields,
    resendCode,
    codeResentText,
    updateConfirmationCode
  } = useStepper()

  const { appState, setAppState } = useAppContext()
  const history = useHistory()

  const handleChange = e => {
    updateConfirmationCode(e)
  }

  return (
    <div>
      <CssBaseline />
      <Paper style={{ padding: 16, minHeight: 445 }} elevation={3}>
        <Typography
          variant="h4"
          align="center"
          component="h1"
          style={{ color: '#1957a6' }}
          gutterBottom
        >
          <FontAwesomeIcon icon={faAt} />
          {'   '}Confirm Your Email
        </Typography>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item xs={12} lg={8} xl={8} md={8}>
            <Typography variant="h6" align="center" gutterBottom>
              Please check your email for a confirmation code
            </Typography>
          </Grid>
          {appState.fields.displayMessage ? (
            <Grid
              item
              xs={12}
              lg={8}
              xl={8}
              md={8}
              style={{ marginBottom: 10 }}
            >
              <div
                style={{
                  // margin: [15, 0],
                  padding: 12,
                  color: appState.fields.color,
                  backgroundColor: appState.fields.backgroundColor
                }}
              >
                <span style={{ verticalAlign: 'middle' }}>
                  {appState.fields.icon}
                  {appState.fields.messageContent}
                </span>
              </div>
            </Grid>
          ) : null}
          <Grid item xs={12} lg={8} xl={8} md={8}>
            <TextField
              autoFocus
              fullWidth
              required
              name="confirmationCode"
              type="text"
              onChange={handleChange}
              value={confirmationCode}
              label="Confirmation Code"
              variant="outlined"
              style={{ marginBottom: 20 }}
            />
            <div>
              <Typography variant="body1" align="center">
                We emailed your code to {appState.user.email || fields.email},
                if you didn't receive the code, please check your junk folder.
              </Typography>
              <Typography variant="body1" align="center">
                Still didn't receive it or your code expired?
                <Button
                  color="secondary"
                  onClick={() => resendCode(fields.email)}
                >
                  Click here to resend Code
                </Button>
              </Typography>
              <Typography variant="body1" align="center">
                If you need to change or correct your email address, please{' '}
                <Button
                  color="secondary"
                  onClick={async () => {
                    await Auth.signOut()
                    setAppState({
                      ...appState,
                      user: {
                        ...appState.user,
                        isAuthenticated: false,
                        activeStep: 0,
                        activeSubStep: 0
                      }
                    })
                    history.push('/get-started/')
                    // updateSignUpState(99, 0, 'false')
                  }}
                >
                  Click here to restart the signup process
                </Button>
              </Typography>
              <Typography variant="body1">{codeResentText}</Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

ConfirmEmail.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfirmEmail)
