import React, { useRef, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  CardHeader,
  TextField,
  CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressCard,
  faCheck,
  faExclamationCircle
} from '@fortawesome/pro-light-svg-icons'
import { useAppContext } from '../../../context/appContext'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import { Auth } from 'aws-amplify'
import Divider from '@material-ui/core/Divider'
import swal from '@sweetalert/with-react'
import { green, orange } from '@material-ui/core/colors'
import clsx from 'clsx'
import DialogVerifyEmail from './dialogVerifyEmail'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'DodgerBlue',
    marginBottom: 20
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170',
    padding: 16,
    position: 'relative'
  },
  title: {
    fontSize: 22,
    color: 'DodgerBlue'
  },
  faIcon: {
    fontSize: 36,
    color: 'Dodgerblue'
  },
  nextButton: {
    fontSize: 20,
    fontWeight: 700
  },
  loadingBlock: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#fff',
    cursor: 'wait',
    opacity: 0.6,
    zIndex: 2
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  faIconVerified: {
    fontSize: 40,
    color: green[500]
  },
  faIconNotVerified: {
    fontSize: 40,
    color: orange[500]
  },
  noticeIcon: {
    marginRight: 10
  }
}))

export default function Name(props) {
  const classes = useStyles()
  const { appState, setAppState } = useAppContext()

  const [edit, setEdit] = useState({
    on: false,
    text: 'Edit your info'
  })
  const [isLoading, setIsLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const clickToVerify = useRef(false)

  const backupUser = useRef(appState.user)

  const showSuccess = () =>
    swal('Success!', 'Your info has been updated.', 'success')

  async function updateUser() {
    const user = {
      address: appState.user.address,
      'custom:city': appState.user.city,
      'custom:parishProvinceState': appState.user.parishProvinceState,
      'custom:country': appState.user.country,
      'custom:zipPostal': appState.user.zipPostal,
      phone_number: appState.user.phone,
      email: appState.user.email
    }

    setIsLoading(true)
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      const updatedUser = await Auth.updateUserAttributes(currentUser, user)
      if (updatedUser === 'SUCCESS') {
        setEdit(state => ({
          ...state,
          on: !state.on,
          text: 'Edit your info'
        }))

        if (appState.user.email?.trim() !== backupUser.current.email) {
          setOpenDialog(true)
          setAppState({
            ...appState,
            user: {
              ...appState.user,
              isConfirmed: false
            }
          })
        } else {
          showSuccess()
        }
      } else {
        swal(
          'Oh no!',
          "Something went wrong. We'll look into the issue as soon as possible but feel free to contact us.",
          'error'
        )
        setEdit(state => ({
          ...state,
          on: !state.on,
          text: 'Edit your info'
        }))
      }
      setIsLoading(false)
    } catch (error) {
      swal('Oh no! Something went wrong.', `${error.message}`, 'error')
      setEdit(state => ({
        ...state,
        on: !state.on,
        text: 'Cancel edits'
      }))
      setAppState({
        ...appState,
        user: {
          ...backupUser.current
        }
      })
      setIsLoading(false)
    }
  }

  const handleUserChange = e => {
    setAppState({
      ...appState,
      user: {
        ...appState.user,
        [e.target.name]: e.target.value
      }
    })
  }

  const toggleDialog = () => setOpenDialog(!openDialog)

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} lg={12} xl={12} md={12}>
        <Paper elevation={3}>
          <Card className={classes.card}>
            {isLoading ? (
              <div className={classes.loadingBlock}>
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              </div>
            ) : null}

            <CardHeader
              avatar={
                <FontAwesomeIcon
                  icon={faAddressCard}
                  className={classes.faIcon}
                />
              }
              title="Name & Address"
              classes={{
                title: classes.title
              }}
            />
            <Divider className={classes.root} />

            <Typography
              variant="h6"
              gutterBottom
              style={{
                marginBottom: 20,
                display: 'flex',
                alignItems: 'center'
              }}
              component="div"
            >
              <FontAwesomeIcon
                icon={appState.user.isConfirmed ? faCheck : faExclamationCircle}
                className={clsx(
                  appState.user.isConfirmed
                    ? classes.faIconVerified
                    : classes.faIconNotVerified,
                  classes.noticeIcon
                )}
              />
              {appState.user.isConfirmed ? (
                'Your email has been verified'
              ) : (
                <>
                  Your email has not beed verified.{' '}
                  <Button
                    color="primary"
                    onClick={() => {
                      toggleDialog()
                      clickToVerify.current = true
                    }}
                  >
                    Click to verify
                  </Button>
                </>
              )}
            </Typography>

            <Typography variant="body2" gutterBottom>
              {edit.text}
              {'  '}
              {!edit.on ? (
                <EditIcon
                  onClick={() => {
                    setEdit(state => ({
                      on: !state.on,
                      text: 'Cancel edits'
                    }))
                  }}
                  style={{ marginTop: -20 }}
                />
              ) : (
                <CloseIcon
                  onClick={() => {
                    setEdit(state => ({
                      on: !state.on,
                      text: 'Edit your info'
                    }))
                    setAppState({
                      ...appState,
                      user: {
                        ...backupUser.current
                      }
                    })
                  }}
                  style={{ marginTop: -20 }}
                />
              )}
            </Typography>
            {!edit.on ? (
              <>
                <CardContent>
                  {/* <Typography variant="body2" gutterBottom>
                  Edit your information
                  {'  '}
                  <EditIcon onClick={() => setIsEditing(true)} />
                </Typography> */}
                  <Typography variant="h6" gutterBottom>
                    {appState.user.firstName} {appState.user.lastName}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {appState.user.address}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {appState.user.city}, {appState.user.parishProvinceState}{' '}
                    {appState.user.zipPostal}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {appState.user.country}
                  </Typography>
                  <Typography variant="h6" gutterBottom></Typography>
                  <Typography variant="h6" gutterBottom>
                    {appState.user.email}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {appState.user.phone}
                  </Typography>
                </CardContent>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  xl={12}
                  md={12}
                  style={{ magrinTop: 20, marginBottom: 20 }}
                >
                  <TextField
                    fullWidth
                    disabled
                    label="First Name"
                    name="firstName"
                    value={appState.user.firstName}
                    type="text"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  xl={12}
                  md={12}
                  style={{ marginBottom: 20 }}
                >
                  <TextField
                    disabled
                    fullWidth
                    name="lastName"
                    value={appState.user.lastName}
                    type="text"
                    label="Last Name"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  xl={12}
                  md={12}
                  style={{ marginBottom: 20 }}
                >
                  <TextField
                    name="address"
                    // error={errors.emailError}
                    fullWidth
                    required
                    // onChange={formValidator.validateField}
                    // onBlur={formValidator.validateOnBlur}
                    value={appState.user.address}
                    onChange={e => handleUserChange(e)}
                    type="text"
                    label="Street Address"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  xl={12}
                  md={12}
                  style={{ marginBottom: 20 }}
                >
                  <TextField
                    fullWidth
                    required
                    name="city"
                    value={appState.user.city}
                    onChange={e => handleUserChange(e)}
                    type="text"
                    label="City"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  xl={12}
                  md={12}
                  style={{ marginBottom: 20 }}
                >
                  <TextField
                    fullWidth
                    required
                    name="parishProvinceState"
                    // error={errors.confirmPasswordError}
                    // helperText={errors.confirmPasswordHelperText}
                    value={appState.user.parishProvinceState}
                    onChange={e => handleUserChange(e)}
                    type="text"
                    label="Parish/Province/State"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  xl={12}
                  md={12}
                  style={{ marginBottom: 20 }}
                >
                  <TextField
                    fullWidth
                    required
                    name="zipPostal"
                    // error={errors.confirmPasswordError}
                    // helperText={errors.confirmPasswordHelperText}
                    value={appState.user.zipPostal}
                    onChange={e => handleUserChange(e)}
                    type="text"
                    label="Zip/Postal"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  xl={12}
                  md={12}
                  style={{ marginBottom: 20 }}
                >
                  <TextField
                    fullWidth
                    required
                    name="country"
                    // error={errors.confirmPasswordError}
                    // helperText={errors.confirmPasswordHelperText}
                    value={appState.user.country}
                    onChange={e => handleUserChange(e)}
                    type="text"
                    label="Country"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  xl={12}
                  md={12}
                  style={{ marginBottom: 20 }}
                >
                  <TextField
                    fullWidth
                    required
                    name="email"
                    // error={errors.confirmPasswordError}
                    // helperText={errors.confirmPasswordHelperText}
                    value={appState.user.email}
                    onChange={e => handleUserChange(e)}
                    type="email"
                    label="Email"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  xl={12}
                  md={12}
                  style={{ marginBottom: 20 }}
                >
                  <TextField
                    fullWidth
                    required
                    name="phone"
                    // error={errors.confirmPasswordError}
                    // helperText={errors.confirmPasswordHelperText}
                    value={appState.user.phone}
                    onChange={e => handleUserChange(e)}
                    type="text"
                    label="Phone"
                  />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12} xl={12} md={12}>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={updateUser}
                      className={classes.nextButton}
                      disabled={isLoading}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Card>
        </Paper>
      </Grid>

      <DialogVerifyEmail
        open={openDialog}
        toggle={toggleDialog}
        showSuccess={showSuccess}
        clickToVerify={clickToVerify.current}
      />
    </Grid>
  )
}
