import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { StepperContext } from './StepperContext'
import { useAppContext } from './appContext'
import { API, Auth } from 'aws-amplify'
import emailValidator from '../utils/emailValidator'
import validatePassword from '../utils/passwordValidator'
// import { find, orderBy } from 'lodash'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

const mRates = new Map()
 mRates.set(1, { ppk: 5700, dc: 0.26 })
  mRates.set(2, { ppk: 5675, dc: 0.52 })
  mRates.set(3, { ppk: 5625, dc: 0.78 })
  mRates.set(4, { ppk: 5575, dc: 1.04 })
  mRates.set(5, { ppk: 5525, dc: 1.3 })
  mRates.set(6, { ppk: 5475, dc: 1.56 })
  mRates.set(7, { ppk: 5425, dc: 1.82 })
  mRates.set(8, { ppk: 5375, dc: 2.08 })
  mRates.set(9, { ppk: 5325, dc: 2.34 })
  mRates.set(10, { ppk: 5275, dc: 2.6 })
  mRates.set(11, { ppk: 5225, dc: 2.86 })
  mRates.set(12, { ppk: 5175, dc: 3.12 })
  mRates.set(13, { ppk: 5125, dc: 3.38 })
  mRates.set(14, { ppk: 5075, dc: 3.64 })
  mRates.set(15, { ppk: 5025, dc: 3.9 })
  mRates.set(16, { ppk: 4975, dc: 4.16 })
  mRates.set(17, { ppk: 4925, dc: 4.42 })
  mRates.set(18, { ppk: 4875, dc: 4.68 })
  mRates.set(19, { ppk: 4825, dc: 4.94 })
  mRates.set(20, { ppk: 4775, dc: 5.2 })
  mRates.set(21, { ppk: 4745, dc: 5.46 })
  mRates.set(22, { ppk: 4715, dc: 5.72 })
  mRates.set(23, { ppk: 4685, dc: 5.98 })
  mRates.set(24, { ppk: 4655, dc: 6.24 })
  mRates.set(25, { ppk: 4625, dc: 6.50 })
  mRates.set(26, { ppk: 4595, dc: 6.76 })
  mRates.set(27, { ppk: 4565, dc: 7.02 })
  mRates.set(28, { ppk: 4535, dc: 7.28 })
  mRates.set(29, { ppk: 4505, dc: 7.54 })
  mRates.set(30, { ppk: 4495, dc: 7.80 })

const useStepper = () => {
  const {
    appState,
    setAppState,
    updateMessageState,
    hideStepLoading
  } = useAppContext()
  const history = useHistory()
  const [state, setState] = useContext(StepperContext)
  const setErrorDialogInfo = type => {
    if (type === 'email') {
      setState(state => ({
        ...state,
        errorDialog: {
          ...state.errorDialog,
          title: 'You may have already signed up for an account',
          body:
            'An account with this email address already exists. Would you like to try to login or sign up with a different email address?',
          button1Action: null,
          button1Text: 'Signup with a different email address',
          button2Text: 'Login',
          button2Action: null
        }
      }))
    } else if (type === 'code') {
      setState(state => ({
        ...state,
        errorDialog: {
          ...state.errorDialog,
          title: 'You entered an incorrect code',
          body: "Sorry, that wasn't the correct code. Please try again.",
          button1Text: 'OK',
          button1Action: null
          // button2Text: 'Send a new code',
          // button2Action: resendCode
        }
      }))
    }
  }
  const _basicInfoComplete =
    (appState.user.UserNotConfirmedException &&
      state.confirmationCode &&
      state?.confirmationCode?.length > 5) ||
    (appState.user.activeSubStep === 0 &&
      state.fields?.firstName?.length > 2 &&
      state.fields?.lastName?.length > 2 &&
      state.fields?.phone?.length > 9 &&
      emailValidator(state.fields.email) &&
      validatePassword(state.fields.password) &&
      state.fields.password === state.fields.confirmPassword &&
      state.fields.checkedTerms) |
      (appState.user.activeSubStep === 1 &&
        state?.confirmationCode?.length === 6) |
      (appState.user.activeSubStep === 2 &&
        state.fields?.address?.length > 3 &&
        state.fields?.city?.length > 3 &&
        state.fields?.parishProvinceState?.length > 1 &&
        state.fields?.zipPostal?.length > 2 &&
        state.fields?.country?.length > 3) |
      (appState.user.activeSubStep === 3 &&
        appState.user.idUrl &&
        appState.user.idUrl.length > 0 &&
        appState.user.residencyFileUrl &&
        appState.user.residencyFileUrl.length > 0) |
      (appState.user.activeStep === 1 &&
        state.fields.termValue > 2 &&
        state.fields.panels >= 1) |
      (appState.user.activeStep === 2 &&
        state.fields.bankAccountType !== '' &&
        state.fields.bankAccountNumber !== '' &&
        state.fields.bankName !== '' &&
        state.fields.bankLocation !== '' &&
        state.fields.bankBranch !== '') ||
    state.fields.bankInfoSkipped |
      (appState.user.activeStep === 3 && state.fields.agreeDSA)
      ? true
      : appState.user.isConfirmed &&
        appState.user.activeSubStep === 0 &&
        state.fields?.firstName?.length > 2 &&
        state.fields?.lastName?.length > 2 &&
        state.fields?.phone?.length > 9 &&
        emailValidator(state.fields.email) &&
        state.fields.checkedTerms
      ? true
      : false
  const _emailValidated = value => {
    return emailValidator(value)
  }

  const _passwordsMatch = value => {
    return value === state.fields.password
  }

  const confirnPasswordHelperText =
    'Passwords must include an upper case letter, lower case letter, a number, a special symbol and must be at least 8 charaters long.'
  const confirmPasswordNotMatchedHelperText =
    "Passwords don't match and must include an upper case letter, lower case letter, a number, a special symbol and must be at least 8 charaters long."

  const validateField = e => {
    e.persist()
    const name = e.target.name
    const value = e.target.value
    if (
      (name === 'firstName' || name === 'lastName') &&
      e.target.value.length < 2
    ) {
      _setNameState(e, name, value, false)
    } else if (
      (name === 'firstName' || name === 'lastName') &&
      e.target.value.length >= 2
    ) {
      _setNameState(e, name, value, true, false)
    } else if (name === 'email') {
      _setEmailState(e, name, value, true, false)
    } else if (name === 'phone') {
      _setPhoneState(e, name, value, true, false)
    } else if (name === 'password') {
      _setPasswordState(e, name, value, true, false)
    } else if (name === 'confirmPassword') {
      _setConfirmPasswordState(e, name, value, true, false)
    }
  }

  const formValidator = {
    enableButton: _basicInfoComplete,
    validateOnBlur: e => {
      e.persist()
      const name = e.target.name
      const value = e.target.value
      if ((name === 'firstName' || name === 'lastName') && value.length < 2) {
        _setNameState(e, name, value, true, true)
      } else if (
        (name === 'firstName' || name === 'lastName') &&
        value.length >= 2
      ) {
        _setNameState(e, name, value, true, false)
      } else if (name === 'email') {
        const valid = _emailValidated(value)
        valid === true
          ? _setEmailState(e, name, value, true, false)
          : _setEmailState(e, name, value, true, true)
      } else if (name === 'phone') {
        const valid = value.length > 9
        valid === true
          ? _setPhoneState(e, name, value, true, false)
          : _setPhoneState(e, name, value, true, true)
      } else if (name === 'password') {
        validatePassword(e.target.value)
          ? _setPasswordState(e, name, value, true, false)
          : _setPasswordState(e, name, value, true, true)
      } else if (name === 'confirmPassword') {
        validatePassword(e.target.value) & _passwordsMatch(e.target.value)
          ? _setConfirmPasswordState(e, name, value, true, false)
          : _setConfirmPasswordState(e, name, value, true, true)
      }
    },
    validateField
  }

  function _setNameState(e, name, value, errors, error) {
    const nameError = `${name}Error`
    const helperText = `${name}HelperText`
    const helperTextName = name === 'firstName' ? 'First name' : 'Last name'
    if (errors) {
      setState(state => ({
        ...state,
        fields: {
          ...state.fields,
          [name]: value
        },
        errors: {
          ...state.errors,
          [nameError]: error ? true : false,
          [helperText]: error
            ? `${helperTextName} must be at least two letters`
            : ''
        }
      }))
    } else {
      setState(state => ({
        ...state,
        fields: {
          ...state.fields,
          [name]: value
        }
      }))
    }
  }

  function _setEmailState(e, name, value, errors, error) {
    e.persist()
    updateFields(e)
    if (errors && error) {
      setState(state => ({
        ...state,
        errors: {
          ...state.errors,
          emailError: true,
          emailHelperText: `Email address is not valid`
        }
      }))
    }
    if (errors && !error) {
      setState(state => ({
        ...state,
        errors: {
          ...state.errors,
          emailError: false,
          emailHelperText: ''
        }
      }))
    }
  }

  function _setPhoneState(e, name, value, errors, error) {
    e.persist()
    updateFields(e)
    if (errors) {
      setState(state => ({
        ...state,
        errors: {
          ...state.errors,
          phoneError: error ? true : false,
          phoneHelperText: error ? `Phone number is not valid` : ''
        }
      }))
    }
  }

  function _setPasswordState(e, name, value, errors, error) {
    e.persist()
    updateFields(e)
    if (errors && error) {
      console.log(errors, error)
      setState(state => ({
        ...state,
        errors: {
          ...state.errors,
          passwordError: true,
          passwordHelperText: error ? `Password is not valid` : ''
        }
      }))
    }
    if (errors && !error) {
      setState(state => ({
        ...state,
        errors: {
          ...state.errors,
          passwordError: false,
          passwordHelperText: ''
        }
      }))
    }
  }

  function _setConfirmPasswordState(e, name, value, errors, error) {
    e.persist()
    updateFields(e)
    const passwordsMatch = _passwordsMatch(value)
    const _passwordHelperText = passwordsMatch
      ? confirnPasswordHelperText
      : confirmPasswordNotMatchedHelperText
    if (errors && error) {
      setState(state => ({
        ...state,
        errors: {
          ...state.errors,
          confirmPasswordError: true,
          confirmPasswordHelperText: _passwordHelperText
        }
      }))
    }
    if (errors && !error) {
      setState(state => ({
        ...state,
        errors: {
          ...state.errors,
          confirmPasswordError: false,
          confirmPasswordHelperText:
            'Passwords must include an upper case letter, lower case letter, a number, a special symbol and must be at least 8 charaters long.'
        }
      }))
    }
  }

  async function createUpdateUser(attributes) {
    if (attributes) {
      try {
        const updatedUser = await _updateUserApi(attributes)
        setState({
          ...state,
          user: updatedUser,
          fields: {
            ...state.fields,
            displayMessage: false,
            messageContent: '',
            icon: '',
            color: '',
            backgroundColor: ''
          }
        })
        return updatedUser
      } catch (e) {
        updateMessageState(
          e.message,
          <ErrorOutlineIcon></ErrorOutlineIcon>,
          '#D8000C',
          '#FFD2D2'
        )
      }
    } else {
      try {
        const newUser = await Auth.signUp({
          username: state.fields.email,
          password: state.fields.password,
          attributes: {
            given_name: state.fields.firstName,
            family_name: state.fields.lastName,
            phone_number: state.fields.phone,
            'custom:activeSubStep': '1',
            'custom:activeStep': '0',
            'custom:signupCompleted': 'false'
          }
        })
        setAppState({
          ...appState,
          user: {
            ...appState.user,
            // isAuthenticated: true,
            sub: newUser.userSub,
            firstName:
              state.fields !== undefined && state.fields.firstName !== ''
                ? state.fields.firstName
                : appState.user.firstName,
            email:
              state.fields !== undefined && state.fields.email !== ''
                ? state.fields.email
                : appState.user.email,
            activeSubStep: 1
          },
          fields: {
            ...state.fields,
            displayMessage: false,
            messageContent: '',
            icon: '',
            color: '',
            backgroundColor: ''
          }
        })
        return newUser
      } catch (e) {
        history.push('/login/')
        updateMessageState(
          'An account with the given email already exists. Please login to your account.',
          <ErrorOutlineIcon></ErrorOutlineIcon>,
          '#663c00',
          '#fff4e5'
        )
      }
    }
  }

  async function createContract() {
      try {
        const newContract = await _createContractApi({
          userId: appState.user.sub,
          name: `${appState.user.firstName} ${appState.user.lastName}`,
          amount: appState.user.initPurchaseAmount,
          contractType: 'DSI',
          term: 25,
          fitRate: state.fields.fitRate,
          pricePerKw: appState.user.initialPricePerKw,
          kWp: appState.user.initialKwp,
          panels: appState.user.initialPanelAmount,
          active: false,
          activeDate: ''
        })
        setState(state => ({
          ...state,
          fields: {
            ...state.fields,
            price: 0,
            displayMessage: false,
            messageContent: '',
            icon: '',
            color: '',
            backgroundColor: ''
          }
        }))
      } catch (e) {
        updateMessageState(
          e.message,
          <ErrorOutlineIcon></ErrorOutlineIcon>,
          '#D8000C',
          '#FFD2D2'
        )
        hideStepLoading()
      }
  }

  function _createContractApi(contract) {
    return API.post('contracts', '/contracts', {
      body: contract
    })
  }

  async function updateSignUpState(
    activeStep,
    activeSubStep,
    isSignedUp,
    attributes = {}
  ) {
    try {
      await _updateUserApi({
        ...attributes,
        'custom:activeStep': activeStep?.toString() ?? '0',
        'custom:activeSubStep': activeSubStep?.toString() ?? '0'
      })
      setAppState(appState => ({
        ...appState,
        update: !appState.update,
        user: {
          ...appState.user,
          isAuthenticated: true,
          firstName:
            appState.user.firstName !== undefined ||
            appState.user.firstName !== ''
              ? appState.user.firstName
              : attributes.firstName || attributes.attributes.given_name,
          lastName:
            appState.user.lastName !== undefined ||
            appState.user.lastName !== ''
              ? appState.user.lastName
              : attributes.lastName || attributes.attributes.family_name,
          sub:
            appState.user.firstName !== undefined || appState.user.sub !== ''
              ? appState.user.sub
              : attributes.sub,
          activeStep:
            activeStep && activeStep > -1 ? parseInt(activeStep, 10) : 0,
          activeSubStep:
            activeSubStep && activeSubStep > -1
              ? parseInt(activeSubStep, 10)
              : 0,
          isSignedUp,
          price:
            attributes && parseInt(attributes['custom:price']) > 0
              ? parseInt(attributes['custom:price'])
              : 0,
          panelLimit:
            attributes && parseInt(attributes['custom:panelLimit'])
              ? parseInt(attributes['custom:panelLimit'])
              : 0,
          bankAccountType:
            attributes?.['custom:bankAccountType'] ??
            appState.user?.bankAccountType,
          bankAccountName:
            attributes?.['custom:bankAccountName'] ??
            appState.user?.bankAccountName,
          bankAccountNumber:
            attributes?.['custom:bankAccountNumber'] ??
            appState.user.bankAccountNumber,
          idUrl:
            appState.user.idUrl !== undefined || appState.user.idUrl !== ''
              ? appState.user.idUrl
              : '',
          residencyFileUrl:
            attributes?.['custom:residencyFileUrl'] ??
            appState.user.residencyFileUrl,
          bankName: attributes?.['custom:bankName'] ?? appState.user.bankName,
          bankLocation:
            attributes?.['custom:bankLocation'] ?? appState.user.bankLocation,
          bankBranch:
            attributes?.['custom:bankBranch'] ?? appState.user.bankBranch
        }
      }))
    } catch (e) {
      console.log(e)
      updateMessageState(
        e.message,
        <ErrorOutlineIcon></ErrorOutlineIcon>,
        '#D8000C',
        '#FFD2D2'
      )
    }
  }

  async function _updateUserApi(attributes) {
    let user = await Auth.currentAuthenticatedUser()
    return Auth.updateUserAttributes(user, attributes)
  }

  async function verifyCurrentUser(scrollToTop, hideStepLoading) {
    try {
      await Auth.verifyCurrentUserAttributeSubmit(
        'email',
        state.confirmationCode
      )
      const data = {
        email: appState.user.email,
        name: appState.user.firstName,
        type: 'emailVerified'
      }
      await sendEmail(data)

      updateSignUpState('0', '2', 'true')
    } catch (error) {
      updateMessageState(
        error.message,
        <ErrorOutlineIcon></ErrorOutlineIcon>,
        '#D8000C',
        '#FFD2D2'
      )
      scrollToTop && scrollToTop()
      hideStepLoading && hideStepLoading()
    }
  }

  async function confirmUser(scrollToTop, hideStepLoading) {
    try {
      await Auth.confirmSignUp(
        state.fields.email || appState.user.email,
        state.confirmationCode
      )
      await Auth.signIn(
        state.fields.email || appState.user.email,
        state.fields.password || appState.user.password
      )

      const data = {
        email: appState.user.email,
        name: appState.user.firstName,
        type: 'emailVerified'
      }
      await sendEmail(data)

      updateSignUpState('0', '2', 'true')
      scrollToTop()
      // updateSignUpState(0, appState.user.activeSubStep + 1, signedIn.attributes)
      // return signedIn
    } catch (e) {
      updateMessageState(
        e.message,
        <ErrorOutlineIcon></ErrorOutlineIcon>,
        '#D8000C',
        '#FFD2D2'
      )
      scrollToTop()
      hideStepLoading()
    }
  }

  async function resendCode(email) {
    try {
      await Auth.resendSignUp(
        email || appState.user.email || state.fields.email
      )
      updateMessageState(
        'We emailed you a new code, please check your email.',
        <ErrorOutlineIcon></ErrorOutlineIcon>,
        '#4F8A10',
        '#DFF2BF'
      )
    } catch (e) {
      // alert(e.message)

      updateMessageState(
        e.message === 'User is already confirmed.'
          ? "You've already confirmed your account, please try logging in."
          : e.message,
        <ErrorOutlineIcon></ErrorOutlineIcon>,
        '#D8000C',
        '#FFD2D2'
      )
    }
  }

  const updateConfirmationCode = e => {
    setState({
      ...state,
      confirmationCode: e.target.value
    })
  }

  const updatePhoneNumber = value => {
    setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        phone: value.replace(/[()-\s]/gi, '')
      }
    }))
  }

  const updateFields = e => {
    e.persist()
    if (e.target.name === 'bankAccountNumber') {
      const pattern = /^[0-9\b]+$/
      const val = e.target.value
      if (val === '' || pattern.test(val)) {
        setState(state => ({
          ...state,
          fields: {
            ...state.fields,
            bankAccountNumber: val
          }
        }))
      }
    } else {
      setState(state => ({
        ...state,
        fields: {
          ...state.fields,
          [e.target.name]:
            e.target.name === 'email'
              ? e.target.value.toLowerCase()
              : e.target.value
        }
      }))
    }
  }

  const updateAppStateUser = e => {
    e.persist()
    setAppState(state => ({
      ...appState,
      user: {
        ...appState.user,
        [e.target.name]: e.target.value
      }
    }))
  }

  const updateIdUrl = (info, type) => {
    const idOrRes = type === 'identification' ? 'idUrl' : 'residencyFileUrl'
    const fileType =
      type === 'identification' ? 'idFileType' : 'residencyFileType'
    setAppState(appState => ({
      ...appState,
      user: {
        ...appState.user,
        [idOrRes]: info.cdnUrl,
        [fileType]: info.mimeType
      }
    }))
  }

  const updateUploadUrl = info => {
    setAppState(appState => ({
      ...appState,
      user: {
        ...appState.user,
        residencyFileUrl: info.cdnUrl
      }
    }))
  }

  const calcDollarReturn = panels => {
    const dc = mRates.get(panels)?.dc
    return dc * 1450 * 0.4275 * (1 - 0.2)
  }

  const calcPercentReturn = panels => {
    const dc = mRates.get(panels)?.dc
    const ppk = mRates.get(panels)?.ppk
    const price = (ppk * dc).toFixed(2)
    const dollarReturn = dc * 1450 * 0.4275 * (1 - 0.2)
    return dollarReturn / price
  }

  const handleInvestmentChange = name => (e, value) => {
    const panels = value
    const dc = mRates.get(panels)?.dc
    const ppk = mRates.get(panels)?.ppk
    const price = (ppk * dc).toFixed(2)
    const dollarReturn = dc * 1450 * 0.4275 * (1 - 0.2)
    const percentReturn = dollarReturn / price
    let kWp = (price / ppk).toFixed(2)

    setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        pricePerKw: ppk,
        panels,
        price,
        dollarReturn,
        percentageReturn: percentReturn,
        kWp
      }
    }))
  }

  const updateRates = (name, value) => {
    setState({
      ...state,
      fields: {
        ...state.fields,
        [name]: value
      }
    })
  }

  const setLoginInfo = e => {
    setState({
      ...state,
      login: {
        ...state.login,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleLogin = async () => {
    try {
      const signedInUser = await Auth.signIn(
        state.fields.email,
        state.fields.password
      )
      return signedInUser
    } catch (e) {
      return e
    }
  }

  const handleCheckBoxChange = name => event => {
    setState(state => ({
      ...state,
      fields: { ...state.fields, [name]: event?.target?.checked }
    }))
  }

  async function finishReturn() {
    try {
      await _updateUserApi({
        'custom:signupCompleted': 'true',
        'custom:activeStep': '99',
        'custom:creditsTotal': '0'
      })
      const data = {
        email: appState.user.email,
        name: appState.user.firstName,
        type: 'signUpCompleted'
      }
      const adminData = {
        firstName: appState.user.firstName,
        lastName: appState.user.lastName,
        type: 'adminNewSignUpNotification'
      }
      await sendEmail(data)
      await sendEmail(adminData)
    } catch (e) {
      const message =
        "Oh no something went wrong but we'll get it fixed asap. Please try again soon."
      updateMessageState(
        message,
        <ErrorOutlineIcon></ErrorOutlineIcon>,
        '#D8000C',
        '#FFD2D2'
      )
    }
  }

  async function sendEmail(data) {
    await _sendEmailApi(data)
  }

  function _sendEmailApi(data) {
    return API.post('email', '/email', {
      body: data
    })
  }

  return {
    sub: state.sub,
    errors: state.errors,
    errorDialog: state.errorDialog,
    formValidator,
    confirmationCode: state.confirmationCode,
    confirmUser,
    createContract,
    createUpdateUser,
    fields: state.fields,
    finishReturn,
    handleCheckBoxChange,
    handleLogin,
    handleInvestmentChange,
    login: state.login,
    resendCode,
    resendText: state.resendText,
    signupCompleted: state.signupCompleted,
    updateConfirmationCode,
    updateFields,
    updateIdUrl,
    updateUploadUrl,
    updateAppStateUser,
    updateRates,
    rates: state.fields.rates,
    updatePhoneNumber,
    validateField,
    sendEmail,
    setErrorDialogInfo,
    setLoginInfo,
    setState,
    updateSignUpState,
    verifyCurrentUser,
    calcDollarReturn,
    calcPercentReturn
  }
}

export default useStepper
