import React, { useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  CardHeader,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/pro-light-svg-icons'
import { Auth } from 'aws-amplify'
import swal from '@sweetalert/with-react'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'DodgerBlue',
    marginBottom: 20
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170',
    padding: 16
    // minHeight: 130
  },
  title: {
    fontSize: 22,
    color: 'DodgerBlue'
  },
  faIcon: {
    fontSize: 36,
    color: 'Dodgerblue'
  },
  paper: {
    padding: 16
  },
  nextButton: {
    marginTop: 40,
    fontSize: 20,
    fontWeight: 700
  }
}))

export default function Password(props) {
  const [state, setState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  })

  const classes = useStyles()

  async function changePassword() {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(
        currentUser,
        state.oldPassword,
        state.newPassword
      )
      swal('Success!', "You're password has been changed.", 'success')
      setState(state => ({
        ...state,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      }))
    } catch (error) {
      console.log(error)
      swal('Error', error.message.toString(), 'error')
      setState(state => ({
        ...state,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      }))
    }
  }

  const handlePasswordChange = e => {
    e.persist()
    setState(state => ({
      ...state,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <>
      <Paper elevation={0}>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <FontAwesomeIcon icon={faLock} className={classes.faIcon} />
            }
            title="Change My Password"
            classes={{
              title: classes.title
            }}
          />
          <Divider className={classes.root} />
          <CardContent>
            <Paper className={classes.paper} elevation={0}>
              <Grid
                item
                container
                alignItems="flex-start"
                spacing={2}
                xs={12}
                lg={12}
                xl={12}
                md={12}
              >
                <Grid item xs={12} lg={12} xl={12} md={12}>
                  <TextField
                    fullWidth
                    required
                    label="Current Password"
                    name="oldPassword"
                    value={state.oldPassword}
                    onChange={e => handlePasswordChange(e)}
                    type="password"
                  />
                </Grid>
                <Grid item xs={12} lg={12} xl={12} md={12}>
                  <TextField
                    fullWidth
                    required
                    name="newPassword"
                    value={state.newPassword}
                    onChange={e => handlePasswordChange(e)}
                    type="password"
                    label="New Password"
                  />
                </Grid>
                <Grid item xs={12} lg={12} xl={12} md={12}>
                  <TextField
                    name="confirmNewPassword"
                    // error={errors.emailError}
                    fullWidth
                    required
                    // onChange={formValidator.validateField}
                    // onBlur={formValidator.validateOnBlur}
                    onChange={e => handlePasswordChange(e)}
                    value={state.confirmNewPassword}
                    type="password"
                    label="Confirm New Password"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Grid
                  item
                  container
                  xs={10}
                  lg={6}
                  xl={6}
                  md={12}
                  direction="row"
                  justifyContent="center"
                >
                  <Button
                    // disabled={!formValidator.enableButton}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={changePassword}
                    className={classes.nextButton}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </CardContent>
        </Card>
      </Paper>
    </>
  )
}
