import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../header/index'
import { Container, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import format from 'date-fns/format'

const date = format(new Date(), "do 'day of' MMMM', ' yyyy")

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'bold'
  },
  paper: {
    padding: 30,
    textAlign: 'flex-start',
    marginTop: 40
  },
  titleLeft: {
    display: 'block',
    align: 'center',
    width: 100
  },
  terms: {
    ...theme.typography.body1
  },
  headline: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '40px'
    }
  }
}))

export default function PAD() {
  const classes = useStyles()

  return (
    <>
      <Header />
      <Container fixed className="container">
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={2}
        >
          <Paper className={classes.paper} elevation={3}>
            <Typography
              variant="h2"
              align="center"
              style={{ color: '#1957a6' }}
              className={classes.headline}
            >
              Pre-Authorized Debits/Credits Form (PADs)
            </Typography>

            <Typography variant="h6" className="term-heading">
              BARBADOS PRE-AUTHORIZED DEBIT/CREDIT AUTHORISATION AND DIRECTION
              (Direct Debit/Credit Mandate)
            </Typography>
            <Typography variant="body1" gutterBottom>
              This Payor Authorization (the "Authorization") is executed this{' '}
              {date} by the undersigned in its capacity as Customer pursuant to
              one or more Digital Solar Agreements (the "Payor") and is granted
              in favour of CloudSolar Inc. (the "Payee") and the Processing
              Institution (defined below) pursuant to the Website Terms of Use
              (the "Terms of Use") for the{' '}
              <a href="https://cloud.solar">
                <strong>https://cloud.solar</strong>
              </a>{' '}
              website (the "Site").
            </Typography>
            <br></br>

            <Typography variant="body1" gutterBottom>
              In this Authorisation, capitalised terms used and not otherwise
              defined herein shall bear the meanings given in the{' '}
              <strong>Terms of Use</strong>.
            </Typography>

            <Typography variant="h6" className="term-heading">
              1. Payor's Name and Address
            </Typography>
            <Typography variant="body1" gutterBottom>
              I/We warrant and represent that the following information input by
              me/us (Including Name of Payor's Financial Institution (the
              "Processing Institution") the Processing Institution's Number,
              Transit Number and Account Number information) is all accurate.
            </Typography>

            <ol className={clsx('list', classes.terms)}>
              <li>
                <span>First Name</span>
              </li>
              <li>
                <span>Last Name</span>
              </li>
              <li>
                <span>Address</span>
              </li>
              <li>
                <span>Telephone Number</span>
              </li>
              <li>
                <span>Bank Account Name</span>
              </li>
              <li>
                <span>Bank Name</span>
              </li>
              <li>
                <span>Bank Branch</span>
              </li>
              <li>
                <span>Bank Transit</span>
              </li>
              <li>
                <span>Bank Account Number</span>
              </li>
            </ol>

            <Typography variant="body1" gutterBottom>
              I/We agree to inform the Payee, in writing, of any change in the
              information provided in this section of the Authorization at least
              10 days prior to the next use of the Pre-Authorized Debit/Credit,
              the operation of which is more particularly described in section 8
              below ("PAD").
            </Typography>

            <Typography variant="h6" className="term-heading">
              2. The Payee's contact and banking information is as follows:
            </Typography>

            <ol className={clsx('list', classes.terms)}>
              <li>
                <span>CloudSolar Inc.</span>
              </li>
              <li>
                <span>Ocean Hotels Corporate Centre</span>
              </li>
              <li>
                <span>Maxwell Main Road</span>
              </li>
              <li>
                <span>Christ Church</span>
              </li>
              <li>
                <span>Christ Church</span>
              </li>
              <li>
                <span>Barbados</span>
              </li>
              <li>
                <span>BB17075</span>
              </li>
              <li>
                <span>1 246 418 8088</span>
              </li>
              <li>
                <span>Bank Account Name: Chequing</span>
              </li>
              <li>
                <span>Bank Name: CIBC First Caribbean International Bank</span>
              </li>
              <li>
                <span>Bank Branch: Warrens</span>
              </li>
              <li>
                <span>Bank Transit: 09326</span>
              </li>
              <li>
                <span>Bank Account Number: 1001199826</span>
              </li>
            </ol>

            <Typography variant="body1" gutterBottom>
              I/We acknowledge that the Authorization is provided for the
              benefit of the Payee and the Processing Institution to permit the
              Processing Institution agreeing to process debits or electronic
              representation of debits against my/our account, as listed above,
              (the "Account") in accordance with any Clearing Regulations.
            </Typography>
            <br></br>

            <Typography variant="body1" gutterBottom>
              I/We warrant and guarantee that I/we are the only persons whose
              signatures are required to authorize debits from the Account and
              that my/our signature hereto represents our acceptance of the
              terms of this Authorization.
            </Typography>
            <br></br>
            <Typography variant="body1" gutterBottom>
              I/We hereby authorize the Payee to make debits from the Account
              from time to time in accordance with the PAD for the payment by
              the Payor of solar panel(s) purchased under a Digital Solar
              Agreement on the Site from the Payee. I/We hereby authorize and
              direct the Processing Institution to transfer the amount stated in
              the PAD to the Payee. Debits under this Authorization may be made
              in any manner required or permitted by the laws of Barbados.
            </Typography>
            <br></br>
            <Typography variant="body1" gutterBottom>
              I/We may cancel the authorization at any time upon providing 10
              days' written notice to the Payee and the Processing Institution.
            </Typography>
            <br></br>
            <Typography variant="body1" gutterBottom>
              I/We acknowledge that provision and delivery of this Authorization
              to the Payee constitutes delivery by me/us to the Processing
              Institution. Any delivery of the Authorization to the Payee,
              regardless of the method of delivery, constitutes delivery by
              me/us.
            </Typography>

            <br></br>
            <Typography variant="body1" gutterBottom>
              The Payee will provide to me/us, at the address provided in
              Section 1 and by way of a notice in my account on the Site with
              respect to fixed amount PADs, written notice of the amount to be
              debited (the "Payment Amount") and the date(s) on which the
              Payment Amount debited will be posted to my/our Account (the
              "Payment Date"), at least 10 calendar days before the Payment Date
              of the PAD, and such notice shall be provided every time there is
              a change in the Payment Amount or the Payment Date(s).
            </Typography>
            <br></br>
            <Typography variant="body1" gutterBottom>
              The Payee may issue a PAD as frequently as agreed in accordance
              with the terms agreed under any payment plan for any Solar
              Services Agreement for the payment of solar panels from the Payor
              arranged via the Site.
            </Typography>
            <br></br>
            <Typography variant="body1" gutterBottom>
              I/We warrant that the Processing Institution is not required to
              verify that a PAD has been issued in accordance with the
              particulars of this Authorization including, but not limited to,
              the amount, or that any purpose of payments for which the PAD was
              issued has been fulfilled by the Payee as a condition to honoring
              a PAD issued or caused to be issued by the Payee on the Account.
            </Typography>

            <br></br>
            <Typography variant="body1" gutterBottom>
              Revocation of this Authorization does not terminate the Terms of
              Use that exist between me/us and the Payee or the Digital Solar
              Agreement(s) I/We am/are party to. The Authorization applies only
              to the method of payment and does not otherwise have any bearing
              on the Terms of Use or the Digital Solar Agreement(s) I/We am/are
              party to.
            </Typography>
            <br></br>
            <Typography variant="body1" gutterBottom>
              I/We may dispute a PAD only under the following conditions:
            </Typography>
            <ol className={clsx('list', classes.terms)}>
              <li>
                <span>
                  the PAD was not drawn in accordance with this Authorization;
                </span>
              </li>
              <li>
                <span>this Authorization was revoked;</span>
              </li>
              <li>
                <span>
                  pre-notification, as required under section 8 was not
                  received.
                </span>
              </li>
            </ol>

            <Typography variant="body1" gutterBottom>
              I/We acknowledge that in order to be reimbursed a declaration to
              the effect that either (A), (B) or (C) took place, must be
              completed and presented to the branch of the Processing
              Institution holding the Account up to and including 90 calendar
              days after the date on which the PAD in dispute was posted to the
              Account. I/We acknowledge that when disputing any PAD beyond the
              time allowed in this section it is a matter to be resolved solely
              between me/us and the Payee, outside the payments system.
            </Typography>

            <br></br>
            <Typography variant="body1" gutterBottom>
              I/We agree that the information contained in this Authorization
              may be disclosed to CIBC First Caribbean International Bank
              (Barbados) Limited as required to complete any PAD transaction.
            </Typography>

            <br></br>
            <Typography variant="body1" gutterBottom>
              I/We understand and accept the terms of participating in the
              PAD(s) facilitated under this Authorization.
            </Typography>
          </Paper>
        </Grid>
      </Container>
    </>
  )
}
