import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../header/index'
import { Container, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'bold'
  },
  paper: {
    padding: 30,
    textAlign: 'flex-start',
    marginTop: 40
  },
  titleLeft: {
    display: 'block',
    align: 'center',
    width: 100
  },
  terms: {
    ...theme.typography.body1
  }
}))

export default function Terms() {
  const classes = useStyles()

  return (
    <>
      <Header />
      <Container fixed className="container">
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={2}
        >
          <Paper className={classes.paper} elevation={3}>
            <Typography
              variant="h2"
              align="center"
              style={{ color: '#1957a6' }}
            >
              Terms Of Use
            </Typography>
            <Typography variant="h6" className="term-heading">
              OUR TERMS OF USE
            </Typography>
            <Typography variant="body1" gutterBottom>
              By accessing the website at{' '}
              <Link to="/">
                <strong>https://cloud.solar</strong>
              </Link>
              , you are agreeing to be bound by these terms of service, all
              applicable laws and regulations, and agree that you are
              responsible for compliance with any applicable local laws. If you
              do not agree with any of these terms, you are prohibited from
              using or accessing this site. The materials contained in this
              website are protected by applicable copyright and trademark law.
            </Typography>

            <Typography variant="h6" className="term-heading">
              WHAT’S IN THESE TERMS?
            </Typography>
            <Typography variant="body1" gutterBottom>
              These terms tell you the rules for using our website{' '}
              <Link to="/">
                <strong>https://cloud.solar</strong>
              </Link>{' '}
              (“site”). Click on the links below to go straight to more
              information on each area:
            </Typography>

            <ol className={clsx('list', classes.terms)}>
              <li>Who we are and how to contact us.</li>
              <li>By using our site you accept these terms.</li>
              <li>There are other terms that may apply to you.</li>
              <li>We may make changes to these terms.</li>
              <li>We may make changes to our site.</li>
              <li>We may suspend or withdraw our site.</li>
              <li>What are our services.</li>
              <li>Our site is only for certain users.</li>
              <li>You must keep your account details safe.</li>
              <li>How you may use materials on our site.</li>
              <li>Do not rely on information on our site.</li>
              <li>User-generated content is not approved by us.</li>
              <li>Our responsibility for loss of damage suffered by you.</li>
              <li>How we may use your personal information.</li>
              <li>Rules about uploading content to our site.</li>
              <li>
                We are not responsible for viruses and you must not introduce
                them.
              </li>
              <li>Rules about linking to our site.</li>
              <li>Which country’s laws apply to any disputes?</li>
            </ol>

            <Typography variant="h6" className="term-heading">
              WHO WE ARE AND HOW TO CONTACT US
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Link to="/">
                <strong>https://cloud.solar</strong>
              </Link>{' '}
              is a site operated by <strong>CloudSolar Inc</strong>. (”we” or
              “us”). We are a company incorporated under the laws of{' '}
              <strong>Barbados</strong>
              with our registered office at{' '}
              <strong>Highway 7, Maxwell, Christ Church, Barbados</strong>.
            </Typography>
            <Typography variant="h6" className="term-heading">
              BY USING OUR SITE YOU ACCEPT THESE TERMS
            </Typography>
            <Typography variant="body1" gutterBottom>
              By accessing the website at{' '}
              <Link to="/">
                <strong>https://cloud.solar</strong>
              </Link>
              , you are agreeing to be bound by these terms of use, all
              applicable laws and regulations, and agree that you are
              responsible for compliance with any applicable local laws. You
              also agree to comply with and be bound by all policies
              incorporated into these terms of use by reference including the
              <Link to="/">
                <strong> Privacy Policy</strong>
              </Link>
              . We recommend that you print a copy of these terms for future
              reference. If you do not agree with any of these terms, you are
              prohibited from using or accessing this site. The materials
              contained in this website are protected by applicable copyright
              and trademark law.
            </Typography>
            <Typography variant="h6" className="term-heading">
              THERE ARE OTHER TERMS THAT MAY APPLY TO YOU
            </Typography>
            <Typography variant="body1" gutterBottom>
              These terms of use refer to the following additional terms, which
              also apply to your use of our site:
            </Typography>

            <ol className={clsx('list', classes.terms)}>
              <li>
                <span>
                  Our{' '}
                  <Link to="/">
                    <strong> Privacy Policy</strong>
                  </Link>
                  , sets out the terms on which we process any personal data we
                  collect from you, or that you provide to us. By using our
                  site, you consent to such processing and you warrant that all
                  data provided by you is accurate.
                </span>
              </li>
              <li>
                <span>
                  Our Cookie Policy, which sets out information about the
                  cookies on our site.
                </span>
              </li>
            </ol>
            <Typography variant="h6" className="term-heading">
              WE MAY MAKE CHANGES TO THESE TERMS
            </Typography>
            <Typography variant="body1" gutterBottom>
              We amend these terms from time to time. Every time you wish to use
              our site, please check these terms to ensure you understand the
              terms that apply at that time.
            </Typography>

            <Typography variant="h6" className="term-heading">
              WE MAY MAKE CHANGES TO OUR SITE
            </Typography>
            <Typography variant="body1" gutterBottom>
              We may update and change our site from time to time to reflect
              changes to our users’ needs, our business priorities or to comply
              with applicable law. We will try to give you reasonable notice of
              any major changes.
            </Typography>

            <Typography variant="h6" className="term-heading">
              WE MAY SUSPEND OR WITHDRAW OUR SITE
            </Typography>

            <Typography variant="body1" gutterBottom>
              We do not guarantee that our site, or any content on it, will
              always be available or be uninterrupted. We may suspend or
              withdraw or restrict the availability of all or any part of our
              site for business and operational reasons. We will try to give you
              reasonable notice of any suspension or withdrawal. You are also
              responsible for ensuring that all persons who access our site
              through your internet connection are aware of these terms of use
              and other applicable terms and conditions, and that they comply
              with them.
            </Typography>

            <Typography variant="h6" className="term-heading">
              OUR SITE IS ONLY FOR CERTAIN USERS
            </Typography>

            <Typography variant="body1" gutterBottom>
              Our site is directed only to persons resident in{' '}
              <strong>Barbados</strong>. We do not represent that content
              available on or through our site is appropriate for use or
              available in other locations. Our site should only be used by
              persons who are legally able to conclude binding contracts and who
              have reached the age of majority at the time of registration for
              use of this site.
            </Typography>

            <Typography variant="h6" className="term-heading">
              YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you choose, or you are provided with, a user identification
              code, password or any other piece of information as part of our
              security procedures, you must treat such information as
              confidential. You must not disclose it to any third party. We have
              the right to disable any user identification code or password,
              whether chosen by you or allocated by us, at any time, if in our
              reasonable opinion you have failed to comply with any of the
              provisions of these terms of use. We also have the right at any
              time to temporarily or permanently remove any users account for
              violating these <strong>Terms of Use</strong>. If you know or
              suspect that anyone other than you knows your user identification
              code or password, you must promptly notify us at{' '}
              <a href="tel:2464188088">
                <strong>(246) 418-8088</strong>
              </a>
              .
            </Typography>

            <Typography variant="h6" className="term-heading">
              DO NOT RELY ON INFORMATION ON THIS SITE
            </Typography>
            <Typography variant="body1" gutterBottom>
              The content on our site is provided for general information only.
              It is not intended to amount to advice on which you should rely.
              You must obtain professional or specialist advice before taking,
              or refraining from, any action on the basis of the content on our
              site. Although we make reasonable efforts to update the
              information on our site, we make no representations, warranties or
              guarantees, whether express or implied, that the content on our
              site is accurate, complete or up to date.
            </Typography>

            <Typography variant="h6" className="term-heading">
              HOW WE MAY USE YOUR PERSONAL INFORMATION
            </Typography>
            <Typography variant="body1" gutterBottom>
              We will only use your personal information as set out in our
              <Link to="/">
                <strong> Privacy Policy</strong>
              </Link>
              .
            </Typography>

            <Typography variant="h6" className="term-heading">
              WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM
            </Typography>
            <Typography variant="body1" gutterBottom>
              We do not guarantee that our site will be secure or free from bugs
              or viruses. You are responsible for configuring your information
              technology, computer programmes and platform to access our site.
              You should use your own virus protection software. You must not
              misuse our site by knowingly introducing viruses, trojans, worms,
              logic bombs or other material that is malicious or technologically
              harmful. You must not attempt to gain unauthorized access to our
              site, the server on which our site is stored or any server,
              computer or database connected to our site. You must not attack
              our site via a denial-of-service attack or a distributed denial-of
              service attack. We will report any breach of this provision to the
              relevant law enforcement authorities and we will co-operate with
              those authorities by disclosing your identity to them. In the
              event of such a breach, your right to use our site will cease
              immediately.
            </Typography>

            <Typography variant="h6" className="term-heading">
              HOW YOU MAY USE MATERIAL ON OUR SITE
            </Typography>
            <Typography variant="body1" gutterBottom>
              We are the owner or the licensee of all intellectual property
              rights in our site, and in the material published on it. Those
              works are protected by intellectual property laws. All such rights
              are reserved. The materials appearing on{' '}
              <strong>CloudSolar Inc</strong>.'s website could include
              technical, typographical, or photographic errors.{' '}
              <strong>CloudSolar Inc</strong>. does not warrant that any of the
              materials on its website are accurate, complete or current.{' '}
              <strong>CloudSolar Inc</strong>. may make changes to the materials
              contained on its website at any time without notice. However{' '}
              <strong>CloudSolar Inc</strong>. does not make any commitment to
              update the materials. Permission is granted to temporarily
              download one copy of the materials (information or software) on{' '}
              <strong>CloudSolar Inc</strong>.'s website for personal,
              non-commercial transitory viewing only. This is the grant of a
              license, not a transfer of title, and under this license you may
              not:
            </Typography>

            <ol className={clsx('list', classes.terms)}>
              <li>
                <span>Modify or copy the materials;</span>
              </li>
              <li>
                <span>
                  Use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);
                </span>
              </li>
              <li>
                <span>
                  Attempt to decompile or reverse engineer any software
                  contained on <strong>CloudSolar Inc</strong>.'s website;
                </span>
              </li>
              <li>
                <span>
                  Remove any copyright or other proprietary notations from the
                  materials; or
                </span>
              </li>
              <li>
                <span>
                  Transfer the materials to another person or "mirror" the
                  materials on any other server.
                </span>
              </li>
            </ol>
            <Typography variant="body1" gutterBottom>
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by{' '}
              <strong>CloudSolar Inc</strong>. at any time. Upon terminating
              your viewing of these materials or upon the termination of this
              license, you must destroy any downloaded materials in your
              possession whether in electronic or printed format.
            </Typography>

            <Typography variant="h6" className="term-heading">
              OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU
            </Typography>
            <Typography variant="body1" gutterBottom>
              The materials on <strong>CloudSolar Inc</strong>.'s website are
              provided on an 'as is' basis. Use of this site is at your own
              risk. Subject to applicable law, <strong>CloudSolar Inc</strong>.
              makes no warranties, expressed or implied, and hereby disclaims
              and negates all other warranties including, without limitation,
              implied warranties or conditions of merchantability, fitness for a
              particular purpose, or non-infringement of intellectual property
              or other violation of rights. Subject to applicable law, in no
              event shall <strong>CloudSolar Inc</strong>. or its suppliers be
              liable for any damages arising out of the use or inability to use
              the materials on <strong>CloudSolar Inc</strong>.'s website,. In
              particular, we will not be liable for:
            </Typography>

            <ol className={clsx('list', classes.terms)}>
              <li>
                <span>Loss of profits, sales, business, or revenue;</span>
              </li>
              <li>
                <span>Business interruption;</span>
              </li>
              <li>
                <span>Loss of anticipated savings;</span>
              </li>
              <li>
                <span>
                  Loss of business opportunity, goodwill or reputation; or
                </span>
              </li>
              <li>
                <span>Any indirect or consequential loss or damage.</span>
              </li>
            </ol>
            <Typography variant="body1" gutterBottom>
              For the avoidance of doubt, we do not exclude or limit in any way
              our liability to you where it would be unlawful to do so.
            </Typography>

            <Typography variant="h6" className="term-heading">
              WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO
            </Typography>
            <Typography variant="body1" gutterBottom>
              We have not reviewed all of the sites linked to our website and we
              are not responsible for the contents of any such linked site. The
              inclusion of any link does not imply endorsement or approval by{' '}
              <strong>CloudSolar Inc</strong>. of the site. Use of any such
              linked website is at the user's own risk.
            </Typography>

            <Typography variant="h6" className="term-heading">
              WHICH COUNTRY’S LAWS APPLY TO ANY DISPUTES?
            </Typography>
            <Typography variant="body1" gutterBottom>
              These terms of use, their subject matter and their formation (and
              any non-contractual disputes or claims) are governed by Barbados
              law. We both agree to the exclusive jurisdiction of the courts of
              Barbados. If any provision hereof is declared by any judicial or
              other competent authority to be illegal or otherwise
              unenforceable, such provision shall be severed from these terms.
              The remaining provisions of these terms shall continue in full
              force and effect and such severance shall not (to the greatest
              possible extent) affect the validity or operation of any other
              provision of these terms. These terms constitute the entire
              agreement of the parties with respect to the subject matter hereof
              and supersedes and cancels all prior agreements, representations
              and understandings of the parties in connection with the subject
              matter hereof.
            </Typography>

            <Typography variant="h6" className="term-heading">
              ACCEPTANCE
            </Typography>
            <Typography variant="body1" gutterBottom>
              By accessing, using and/or creating an account on this website I
              accept and agree to the above-mentioned terms.
            </Typography>
          </Paper>
        </Grid>
      </Container>
    </>
  )
}
