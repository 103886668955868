import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Paper,
  Grid,
  CssBaseline,
  InputAdornment
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import useStepper from '../../context/useStepper'
import MuiPhoneNumber from 'material-ui-phone-number'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/pro-light-svg-icons'
import { useAppContext } from '../../context/appContext'
import { Link } from 'react-router-dom'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { green, red } from '@material-ui/core/colors'
import validatePassword from '../../utils/passwordValidator'

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'bold'
  },
  paper: {
    padding: 30,
    textAlign: 'center'
    // color: 'pink'
  },
  titleLeft: {
    display: 'block',
    align: 'center',
    width: 100
  },
  termsLabel: {
    marginTop: '1.5vh',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      marginTop: '4.5vh'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '4vh'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '2vh'
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '1.5vh'
    }
  },
  gridRow: {
    marginLeft: '1.5vh',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-4.5vh'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-3vh'
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '-2vh'
    }
    // [theme.breakpoints.down('md')]: {
    //   marginTop: '2vh'
    // }
  },
  green: {
    color: green[500]
  },
  red: {
    color: red[500]
  },
  outlineGreen: {
    borderColor: `${green[500]} !important`
  },
  outlineRed: {
    borderColor: `${red[500]} !important`
  }
}))

export default function BasicInfo() {
  const classes = useStyles()
  const { appState } = useAppContext()
  const {
    errors,
    fields,
    formValidator,
    handleCheckBoxChange,
    updatePhoneNumber,
    setState
  } = useStepper()
  const { isConfirmed } = appState.user

  const hidePassword = isConfirmed

  useEffect(() => {
    if (isConfirmed) {
      const {
        firstName,
        lastName,
        email,
        phone,
        bankInfoSkipped
      } = appState.user
      setState(state => ({
        ...state,
        fields: {
          ...state.fields,
          firstName,
          lastName,
          email,
          phone,
          checkedTerms: bankInfoSkipped
        }
      }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const label = (
    <div className={classes.termsLabel}>
      {' '}
      <Typography variant="caption">I agree to CloudSolar's </Typography>{' '}
      <Link to={'/terms/'} style={{ color: '#1957a6' }}>
        <Typography variant="caption"> Terms of Use</Typography>
      </Link>
      {', '}
      <Link to={'/privacy/'} style={{ color: '#1957a6' }}>
        <Typography variant="caption"> Privacy Policy</Typography>
      </Link>
      {', '}
      <Link to={'/pad/'} style={{ color: '#1957a6' }}>
        <Typography variant="caption">
          {' '}
          Pre-Authorized Debit Agreement
        </Typography>
      </Link>
      <Typography variant="caption">{'.'}</Typography>
    </div>
  )

  const _greenCheck = () => (
    <InputAdornment position="end">
      <CheckCircleOutlineIcon className={classes.green} />
    </InputAdornment>
  )

  const _redCheck = () => (
    <InputAdornment position="end">
      <ErrorOutlineIcon className={classes.red} />
    </InputAdornment>
  )

  const getStarted = () => {
    return (
      <div>
        <CssBaseline />
        <Paper className={classes.paper} elevation={3}>
          <Typography
            variant="h4"
            align="center"
            component="h1"
            style={{ color: '#1957a6' }}
          >
            <FontAwesomeIcon icon={faPlay} />
            {'   '}Let's Get Started
          </Typography>
          <Typography
            variant="subtitle2"
            align="center"
            gutterBottom
            style={{ marginBottom: 20 }}
          >
            Need help? We're here, call, email or chat.
          </Typography>
          {appState.fields.displayMessage ? (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{ marginBottom: 10 }}
            >
              <div
                style={{
                  // margin: [15, 0],
                  padding: 12,
                  color: appState.fields.color,
                  backgroundColor: appState.fields.backgroundColor
                }}
              >
                <span style={{ verticalAlign: 'middle' }}>
                  {appState.fields.icon}
                  {appState.fields.messageContent}
                </span>
              </div>
            </Grid>
          ) : null}
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} lg={8} xl={8} md={8}>
              <TextField
                autoFocus
                fullWidth
                required
                name="firstName"
                onChange={formValidator.validateField}
                value={fields.firstName}
                type="text"
                label="First Name"
                variant="outlined"
                error={errors.firstNameError}
                // onBlur={formValidator.validateOnBlur}
                helperText={errors.firstNameHelperText}
                disabled={isConfirmed}
              />
            </Grid>
            <Grid item xs={12} lg={8} xl={8} md={8}>
              <TextField
                fullWidth
                required
                name="lastName"
                onChange={formValidator.validateField}
                value={fields.lastName}
                type="text"
                label="Last Name"
                variant="outlined"
                error={errors.lastNameError}
                // onBlur={formValidator.validateOnBlur}
                helperText={errors.lastNameHelperText}
                disabled={isConfirmed}
              />
            </Grid>
            <Grid item xs={12} lg={8} xl={8} md={8}>
              <TextField
                name="email"
                error={errors.emailError}
                helperText={errors.emailHelperText}
                fullWidth
                required
                onChange={formValidator.validateField}
                // onBlur={formValidator.validateOnBlur}
                value={fields.email}
                type="email"
                label="Email"
                variant="outlined"
                autoCapitalize="none"
              />
            </Grid>
            <Grid item xs={12} lg={8} xl={8} md={8}>
              <MuiPhoneNumber
                disableAreaCodes={true}
                value={fields.phone}
                name="phone"
                label="Phone"
                fullWidth
                required
                defaultCountry={'bb'}
                onChange={updatePhoneNumber}
                // onBlur={formValidator.validateOnBlur}
                variant="outlined"
                type="phone"
                preferredCountries={['bb', 'ca', 'us', 'gb']}
                // placeholder={errors.phoneHelperText}
              />
            </Grid>

            {hidePassword ? null : (
              <>
                <Grid item xs={12} lg={8} xl={8} md={8}>
                  <TextField
                    fullWidth
                    required
                    name="password"
                    error={
                      !!fields.password?.length &&
                      !validatePassword(fields.password)
                    }
                    helperText={errors.passwordHelperText}
                    onChange={formValidator.validateField}
                    // onBlur={formValidator.validateOnBlur}
                    value={fields.password}
                    type="password"
                    label="Password"
                    variant="outlined"
                    InputProps={{
                      endAdornment: fields.password?.length
                        ? validatePassword(fields.password)
                          ? _greenCheck()
                          : _redCheck()
                        : null,
                      classes: {
                        notchedOutline: fields.password?.length
                          ? validatePassword(fields.password)
                            ? classes.outlineGreen
                            : classes.outlineRed
                          : ''
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={8} xl={8} md={8}>
                  <TextField
                    fullWidth
                    required
                    name="confirmPassword"
                    error={errors.confirmPasswordError}
                    helperText={errors.confirmPasswordHelperText}
                    onChange={formValidator.validateField}
                    // onBlur={formValidator.validateOnBlur}
                    value={fields.confirmPassword}
                    type="password"
                    label="Confirm Password"
                    variant="outlined"
                    InputProps={{
                      endAdornment: fields.confirmPassword?.length
                        ? validatePassword(fields.confirmPassword) &&
                          fields.confirmPassword === fields.password
                          ? _greenCheck()
                          : _redCheck()
                        : null,
                      classes: {
                        notchedOutline: fields.confirmPassword?.length
                          ? validatePassword(fields.confirmPassword) &&
                            fields.confirmPassword === fields.password
                            ? classes.outlineGreen
                            : classes.outlineRed
                          : ''
                      }
                    }}
                  />
                </Grid>
              </>
            )}

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                lg={8}
                xl={8}
                md={8}
                className={classes.gridRow}
              >
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fields.checkedTerms}
                        onChange={handleCheckBoxChange('checkedTerms')}
                        name="bankInfoSkipped"
                      />
                    }
                    label={label}
                    disabled={isConfirmed}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* </Card> */}
      </div>
    )
  }

  return getStarted()
}

// BasicInfo.propTypes = {
//   classes: PropTypes.object.isRequired
// }
