const dev = {
  apiGateway: {
    REGION: 'us-east-1',
    banksURL: 'https://jwaq2ti5g8.execute-api.us-east-1.amazonaws.com/dev',
    contractsURL: 'https://l5zqb5zlte.execute-api.us-east-1.amazonaws.com/dev',
    emailURL: 'https://c3c6u0d3ql.execute-api.us-east-1.amazonaws.com/dev',
    leadsURL: 'https://c5gdkglkn9.execute-api.us-east-1.amazonaws.com/dev',
    ratesURL: 'https://zitc4tu1i8.execute-api.us-east-1.amazonaws.com/dev',
    returnsURL: 'https://t0mxy60t6d.execute-api.us-east-1.amazonaws.com/dev'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_Svmbj5SIM',
    APP_CLIENT_ID: '6i0jad1bdfggkqfiannh0794vm',
    IDENTITY_POOL_ID: 'us-east-1:67a1c374-4969-4e19-9a30-aed3ba15533e'
  }
}

const prod = {
  apiGateway: {
    REGION: 'us-east-1',
    emailURL: 'https://ih0ih22rc2.execute-api.us-east-1.amazonaws.com/prod',
    leadsURL: 'https://5hld65gnj1.execute-api.us-east-1.amazonaws.com/prod',
    contractsURL: 'https://gsw5wvaybl.execute-api.us-east-1.amazonaws.com/prod',
    ratesURL: 'https://zdxw95ic0h.execute-api.us-east-1.amazonaws.com/prod',
    returnsURL: 'https://23kykf5qge.execute-api.us-east-1.amazonaws.com/prod'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_Teb2DEB6l',
    APP_CLIENT_ID: '2gqfcqb2vlko991h0dv758n325',
    IDENTITY_POOL_ID: 'us-east-1:aba945f3-3bea-4f6e-bc97-dcaf0b7fc828'
  }
}

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
}
