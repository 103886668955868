import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Auth } from 'aws-amplify'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { CircularProgress, InputAdornment, makeStyles } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import validateEmail from '../../utils/emailValidator'
import validatePassword from '../../utils/passwordValidator'
import { green, red } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative'
  },
  loadingBlock: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#fff',
    cursor: 'wait',
    opacity: 0.6,
    zIndex: 2
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  errorIcon: {
    marginRight: theme.spacing(1)
  },
  warningText: {
    color: red[500]
  },
  outlineGreen: {
    borderColor: `${green[500]} !important`
  },
  outlineRed: {
    borderColor: `${red[500]} !important`
  },
  green: {
    color: green[500]
  },
  red: {
    color: red[500]
  }
}))

const BTN_TEXT = ['Send confirmation code', 'Reset Password']

export default function PasswordDialog(props) {
  const classes = useStyles()

  const [state, setState] = useState({
    isLoading: false,
    fields: {
      email: '',
      code: '',
      password: '',
      confirmPassword: '',
      buttonText: BTN_TEXT[0],
      error: false,
      errorMessage: '',
      showFields: false,
      dialogText:
        "Enter your email address and we'll send you a confirmation code to reset your password."
    },
    errors: {
      fields: {}
    }
  })

  const handleClose = () => {
    if (!state.isLoading) {
      props.onClose()
      clearState()
    }
  }

  function handleErrors(message) {
    setState(state => ({
      ...state,
      isLoading: false,
      fields: {
        ...state.fields,
        error: true,
        errorMessage: message
      }
    }))
  }

  function clearState() {
    setState(state => ({
      ...state,
      errors: {
        fields: {}
      },
      isLoading: false,
      fields: {
        ...state.fields,
        email: '',
        code: '',
        password: '',
        confirmPassword: '',
        buttonText: BTN_TEXT[0],
        error: false,
        errorMessage: '',
        showFields: false,
        dialogText:
          "Enter your email address and we'll send you a confirmation code to reset your password."
      }
    }))
  }

  const handleChange = e => {
    const name = e.target.name
    const value = e.target.value

    if (name === 'email') {
      const validEmail = validateEmail(value)
      setState(state => ({
        ...state,
        fields: {
          ...state.fields,
          [name]: value
        },
        errors: {
          fields: validEmail
            ? {
                ...state.errors.fields,
                email: {}
              }
            : {
                ...state.errors.fields,
                email: {
                  error: true,
                  message: 'Email is not valid.'
                }
              }
        }
      }))
      return
    }
    if (name === 'password') {
      const validPassword = validatePassword(value)
      setState(state => ({
        ...state,
        fields: {
          ...state.fields,
          [name]: value
        },
        errors: {
          fields: validPassword
            ? {
                ...state.errors.fields,
                password: {}
              }
            : {
                ...state.errors.fields,
                password: {
                  error: true,
                  message: 'Password is not valid.'
                }
              }
        }
      }))
      return
    }
    if (name === 'confirmPassword') {
      const validPassword = validatePassword(value)
      setState(state => ({
        ...state,
        fields: {
          ...state.fields,
          [name]: value
        },
        errors: {
          fields: validPassword
            ? {
                ...state.errors.fields,
                confirmPassword:
                  state.fields.password !== value
                    ? {
                        error: true,
                        message: 'Confirm password is not match with Password.'
                      }
                    : {}
              }
            : {
                ...state.errors.fields,
                confirmPassword: {
                  error: true,
                  message: 'Confirm password is not valid.'
                }
              }
        }
      }))
      return
    }

    setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [name]: value
      }
    }))
  }

  async function sendCode() {
    setState(state => ({
      ...state,
      isLoading: true
    }))
    try {
      await Auth.forgotPassword(state.fields.email)
      setState(state => ({
        ...state,
        isLoading: false,
        fields: {
          ...state.fields,
          showFields: true,
          buttonText: BTN_TEXT[1],
          dialogText:
            'Check your email for the code and enter it below with your new password.'
        }
      }))
    } catch (error) {
      handleErrors(error.message)
    }
  }

  const onEnterEmail = e => {
    if (
      state.fields.buttonText === BTN_TEXT[0] &&
      e.charCode === 13 &&
      !!state.errors.fields?.['email']?.error === false
    ) {
      sendCode()
    }
  }

  function handleButtonClick() {
    if (state.fields.buttonText === BTN_TEXT[0]) {
      sendCode()
    } else {
      resetPassword()
    }
  }

  async function resetPassword() {
    setState(state => ({
      ...state,
      isLoading: true
    }))
    try {
      const res = await Auth.forgotPasswordSubmit(
        state.fields.email,
        state.fields.code,
        state.fields.password
      )
      if (res === undefined) {
        handleClose()
        // handleOpenSnack()
        props.updateMessage(
          'Your password has been reset',
          <CheckCircleOutlineIcon></CheckCircleOutlineIcon>,
          '#4F8A10',
          '#DFF2BF'
        )
      }
    } catch (error) {
      handleErrors(error.message)
    }
  }

  const _greenCheck = () => (
    <InputAdornment position="end">
      <CheckCircleOutlineIcon className={classes.green} />
    </InputAdornment>
  )

  const _redCheck = () => (
    <InputAdornment position="end">
      <ErrorOutlineIcon className={classes.red} />
    </InputAdornment>
  )

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown={state.isLoading}
      >
        <DialogTitle id="form-dialog-title">Forgot your password?</DialogTitle>
        <DialogContent dividers>
          {state.isLoading && (
            <div className={classes.loadingBlock}>
              <CircularProgress size={24} className={classes.buttonProgress} />
            </div>
          )}
          <DialogContentText>{state.fields.dialogText}</DialogContentText>

          {state.fields.error ? (
            <div
              style={{
                margin: [10, 0],
                padding: 12,
                color: '#D8000C',
                backgroundColor: '#FFD2D2',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <ErrorOutlineIcon
                className={classes.errorIcon}
              ></ErrorOutlineIcon>
              {state.fields.errorMessage}
            </div>
          ) : null}

          <TextField
            autoFocus
            fullWidth
            margin="normal"
            name="email"
            label="Email Address"
            type="email"
            value={state.fields.email}
            onChange={e => handleChange(e)}
            variant="outlined"
            onKeyPress={onEnterEmail}
            error={state.errors.fields?.['email']?.error}
            helperText={
              state.errors.fields?.['email']?.error
                ? state.errors.fields?.['email']?.message
                : null
            }
          />
          {state.fields.showFields ? (
            <div>
              <TextField
                margin="normal"
                name="code"
                label="Confirmation Code"
                type="text"
                value={state.fields.code}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
              <TextField
                margin="normal"
                name="password"
                label="Password"
                type="password"
                value={state.fields.password}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={state.errors.fields?.['password']?.error}
                helperText={
                  state.errors.fields?.['password']?.error
                    ? state.errors.fields?.['password']?.message
                    : null
                }
                InputProps={{
                  endAdornment: state.fields.password?.length
                    ? !state.errors.fields?.['password']?.error
                      ? _greenCheck()
                      : _redCheck()
                    : null,
                  classes: {
                    notchedOutline: state.fields.password?.length
                      ? !state.errors.fields?.['password']?.error
                        ? classes.outlineGreen
                        : classes.outlineRed
                      : ''
                  }
                }}
              />
              <TextField
                margin="normal"
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                value={state.fields.confirmPassword}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={state.errors.fields?.['confirmPassword']?.error}
                helperText={
                  state.errors.fields?.['confirmPassword']?.error
                    ? state.errors.fields?.['confirmPassword']?.message
                    : null
                }
                InputProps={{
                  endAdornment: state.fields.confirmPassword?.length
                    ? !state.errors.fields?.['confirmPassword']?.error
                      ? _greenCheck()
                      : _redCheck()
                    : null,
                  classes: {
                    notchedOutline: state.fields.confirmPassword?.length
                      ? !state.errors.fields?.['confirmPassword']?.error
                        ? classes.outlineGreen
                        : classes.outlineRed
                      : ''
                  }
                }}
              />{' '}
              <DialogContentText className={classes.warningText}>
                Passwords must include an upper case letter, lower case letter,
                a number, a special symbol and must be at least 8 characters
                long.
              </DialogContentText>
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} variant="text" color="primary">
            Cancel
          </Button>
          <Button
            disabled={state.fields.email === ''}
            onClick={handleButtonClick}
            variant="contained"
            color="primary"
            style={{ fontWeight: 700 }}
          >
            {state.fields.buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
