import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../header/index'
import { Container, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'bold'
  },
  paper: {
    padding: 30,
    textAlign: 'flex-start',
    marginTop: 40
  },
  titleLeft: {
    display: 'block',
    align: 'center',
    width: 100
  },
  terms: {
    ...theme.typography.body1
  }
}))

export default function Privacy() {
  const classes = useStyles()

  return (
    <>
      <Header />
      <Container fixed className="container">
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={2}
        >
          <Paper className={classes.paper} elevation={3}>
            <Typography
              variant="h2"
              align="center"
              style={{ color: '#1957a6', marginBottom: 30 }}
            >
              Privacy Policy
            </Typography>
            <Typography variant="body1" gutterBottom>
              Your privacy is important to us. It is CloudSolar Inc.'s policy to
              respect your privacy regarding any information we may collect from
              you across our website,{' '}
              <Link to="/">
                <strong>http://www.cloud.solar</strong>
              </Link>
              (“site”), and other sites we own and operate.<br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              This Privacy Policy (together with our Terms of Use and any other
              documents referred to in it) sets out the basis on which any
              personal data we collect from you, or that you provide to us, will
              be processed by us. Please read the following carefully to
              understand our views and practices regarding your personal data
              and how we will treat it.<br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              By using our site and agreeing to our Terms of Use you are
              accepting and consenting to the practices described in this
              Privacy Policy and the processing of your personal data by us. You
              have the right to withdraw your consent to our processing of your
              personal data by written notice to us at{' '}
              <a href="mailto:info@cloud.solar">
                <strong>info@cloud.solar</strong>
              </a>
              .<br></br>
              <br></br>
            </Typography>

            <Typography variant="body1" gutterBottom>
              Our site is not intended for children and we do not knowingly
              collect data relating to children. We only ask for personal
              information when we truly need it to provide a service to you. We
              collect it by fair and lawful means, with your knowledge and
              consent.<br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              We will collect and process the following data about you:
            </Typography>
            <ol className={clsx('list', classes.terms)}>
              <li>
                <span>
                  Information you give us. This is information about you that
                  you give us by filling in forms on our site or by
                  corresponding with us by phone, e-mail or otherwise. It
                  includes information you provide when you register to use our
                  site, subscribe to our service and when you report a problem
                  with our site. The information you give us may include your
                  name, address, e-mail address and phone number, financial and
                  credit card information, personal description and photograph.
                </span>
              </li>
              <li>
                <span>
                  Information we collect about you. With regard to each of your
                  visits to our site we may automatically collect technical
                  information, including the Internet protocol (IP) address used
                  to connect your computer to the Internet and your login
                  information.
                </span>
              </li>
            </ol>
            <br></br>
            <br></br>
            <Typography variant="body1" gutterBottom>
              We use information held about you in the following ways:
            </Typography>
            <ol className={clsx('list', classes.terms)}>
              <li>
                <span>
                  Information you give us. We will use this information: to
                  carry out our obligations arising from any contracts entered
                  into between you and us and to provide you with the
                  information and services that you request from us; to provide
                  you with information about goods or services we feel may
                  interest you; to notify you about changes to our service; to
                  ensure that content from our site is presented in the most
                  effective manner for you and for your computer.
                </span>
              </li>
              <li>
                <span>
                  Information we collect about you. We will use this
                  information: to administer our site and for internal
                  operations, including troubleshooting, data analysis, testing,
                  research, statistical and survey purposes; to improve our site
                  to ensure that content is presented in the most effective
                  manner for you and for your computer; to allow you to
                  participate in interactive features of our service, when you
                  choose to do so; as part of our efforts to keep our site safe
                  and secure; to measure or understand the effectiveness of
                  advertising we serve to you and others, and to deliver
                  relevant advertising to you; to make suggestions and
                  recommendations to you and other users of our site about goods
                  or services that may interest you or them.
                </span>
              </li>
            </ol>
            <br></br>
            <br></br>
            <Typography variant="body1" gutterBottom>
              We only retain collected information for as long as necessary to
              provide you with your requested service. What data we store, we
              will protect within commercially acceptable means to prevent loss
              and theft, as well as unauthorized access, disclosure, copying,
              use or modification.<br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              We do not share any personally identifying information publicly or
              with third-parties, except when required to by law.
              <br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Our website may link to external sites that are not operated by
              us. Please be aware that we have no control over the content and
              practices of these sites, and cannot accept responsibility or
              liability for their respective privacy policies. Additionally, our
              site may, from time to time, contain links to and from the
              websites of our advertisers and affiliates. If you follow a link
              to any of these websites, please note that these websites have
              their own privacy policies and that we do not accept any
              responsibility or liability for these policies. Please check these
              policies before you submit any personal data to these websites.
              <br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              You are free to refuse our request for your personal information,
              with the understanding that we may be unable to provide you with
              some of your desired services. Where we have given you (or where
              you have chosen) a password which enables you to access certain
              parts of our site, you are responsible for keeping this password
              confidential. We ask you not to share a password with anyone.
              <br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Any payment transactions will be encrypted. Unfortunately, the
              transmission of information via the internet is not completely
              secure. Although we will do our best to protect your personal
              data, we cannot guarantee the security of your data transmitted to
              our site; any transmission is at your own risk. Once we have
              received your information, we will use strict procedures and
              security features to try to prevent unauthorized access.
              <br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Our site uses cookies to distinguish you from other users of our
              site. This helps us to provide you with a good experience when you
              browse our website and also allows us to improve our site. For
              detailed information on the cookies we use and the purposes for
              which we use them see our <strong>Cookie Policy</strong>.<br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you have any questions about how we handle user data and
              personal information, feel free to contact us at{' '}
              <a href="mailto:info@cloud.solar">
                <strong>info@cloud.solar</strong>
              </a>
              .<br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Any changes we make to this <strong>Privacy Policy</strong> in the
              future will be posted on this page and, where appropriate,
              notified to you by e-mail. Please check back frequently to see any
              updates or changes to this <strong>Privacy Policy</strong>.
              <br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              These terms and conditions are governed by and construed in
              accordance with the laws of <strong>Barbados</strong> and you
              irrevocably submit to the exclusive jurisdiction of the courts of{' '}
              <strong>Barbados</strong>.<br></br>
              <br></br>
            </Typography>
            <Typography variant="body1" gutterBottom>
              This policy is effective as of <strong>1 October 2020</strong>.
            </Typography>
          </Paper>
        </Grid>
      </Container>
    </>
  )
}
