import React, { useState } from 'react'

const DashboardContext = React.createContext([0, () => {}])

const DashboardProvider = props => {
  const [state, setState] = useState({
    user: {},
    name: '',
    price: 1482,
    kWp: 0.26,
    creditsTotal: 0,
    contractsTotal: 0,
    panelLimit: 0,
    contracts: [],
    transactions: [],
    update: false,
    isLoading: false,
    snackBarOpen: false,
    dollarReturn: 124.49,
    fitRate: 0.4275,
    percentageReturn: 9.98,
    pricePerKw: 5700,
    panels: 1,
    termValue: 25
  })

  return (
    <DashboardContext.Provider value={[state, setState]}>
      {props.children}
    </DashboardContext.Provider>
  )
}

export { DashboardContext, DashboardProvider }
