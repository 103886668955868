import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Container, Grid } from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import BasicInfo from './BasicInfo'
import BankInfo from './BankInfo'
import SelectPanels from './SelectPanels'
import ReviewFinish from './ReviewFinish'
import ConfirmEmail from './ConfirmEmail'
import Address from './Address'
import Identification from './Identification'
import Dashboard from '../dashboard'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useAppContext } from '../../context/appContext'
import useStepper from '../../context/useStepper'
import ErrorDialog from '../dialogs/ErrorDialog'
import LoginDialog from '../dialogs/LoginDialog'
import './Signup.css'
import clsx from 'clsx'
import { StepperContext } from '../../context/StepperContext'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: 800,
    maxHeight: 800
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: 445,
    maxHeight: 445
  },
  help: {
    marginTop: -60,
    marginLeft: -40
  },
  bottomButton: {
    border: '0 none',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.175)',
    borderRadius: '5px',
    color: '#ffffff',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '18px',
    fontWeight: 'bold',
    height: '50px',
    lineHeight: '50px',
    padding: '0 23px',
    textDecoration: 'none',
    width: 'auto',
    minWidth: '160px',
    textAlign: 'center'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  card: {
    maxWidth: 75
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 16
  },
  pos: {
    marginBottom: 12
  },
  nextButton: {
    background: '#fbb116'
  },
  backButton: {
    marginRight: theme.spacing(1.5)
  },
  wrapper: {
    position: 'relative'
  },
  loadingBlock: {
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
    right: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
    backgroundColor: '#fff',
    cursor: 'wait',
    opacity: 0.6,
    zIndex: 2
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

function getSteps() {
  return ['Basic Info', 'Select Panels', 'Bank Info', 'Review & Finish']
}

export default function SignupStepper(props) {
  const {
    formValidator,
    confirmUser,
    createContract,
    createUpdateUser,
    fields,
    finishReturn,
    // sendEmail,
    updateSignUpState,
    verifyCurrentUser
  } = useStepper()
  const {
    appState,
    updateMessageState,
    setAppState,
    hideMessageState,
    goToSteps,
    setStepLoading,
    hideStepLoading
  } = useAppContext()
  const classes = useStyles()
  const steps = getSteps()

  const activeSubStep = parseInt(appState.user.activeSubStep)
  const bActiveSubStep = activeSubStep < 5
  const [errorOpen, setErrorOpen] = useState(false)
  const [loginOpen, setLoginOpen] = useState(false)
  const { isAuthenticated, isConfirmed, activeStep } = appState.user
  const [state, setState] = useContext(StepperContext)

  useEffect(() => {
    return () => {
      hideMessageState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const handleErrorClose = () => {
    setErrorOpen(false)
    setLoginOpen(true)
  }

  const handleLoginClose = () => {
    setLoginOpen(false)
  }

  const handleNext = () => {
    hideMessageState()
    scrollToTop()
    bActiveSubStep ? handleNextSubStep() : handleNextStep()
  }

  const handleBack = async () => {
    hideMessageState()
    scrollToTop()
    if (activeStep === 0) {
      switch (activeSubStep) {
        case 2:
          setStepLoading()
          const user = {
            address: fields.address,
            'custom:city': fields.city,
            'custom:parishProvinceState': fields.parishProvinceState,
            'custom:zipPostal': fields.zipPostal,
            'custom:country': fields.country,
            'custom:signupCompleted': 'false'
          }
          try {
            await updateSignUpState(0, 0, 'false', user)
          } catch (error) {
            hideStepLoading()
          }
          break
        case 3:
          setStepLoading()
          let attributes = {}
          if (appState?.user?.idUrl && appState?.user?.idFileType) {
            attributes = {
              'custom:idUrl': appState.user.idUrl,
              'custom:idFileType': appState.user.idFileType
            }
          }
          if (
            appState?.user?.residencyFileUrl &&
            appState?.user?.residencyFileType
          ) {
            attributes = {
              ...attributes,
              'custom:residencyFileUrl': appState.user.residencyFileUrl,
              'custom:residencyFileType': appState.user.residencyFileType
            }
          }
          try {
            await updateSignUpState(0, 2, 'false', attributes)
          } catch (error) {
            hideStepLoading()
          }
          break
        default:
          goToSteps(activeStep, activeSubStep > 1 ? activeSubStep - 1 : 0)
          break
      }
    } else {
      if (activeStep === 1) {
        goToSteps(0, 3)
        return
      }
      if (activeStep > 1) {
        goToSteps(activeStep - 1, 99)
      }
    }
  }

  const handleNextStep = async () => {
    setStepLoading()
    // eslint-disable-next-line default-case
    switch (parseInt(appState.user.activeStep)) {
      case 1:
        try {
          let attributes = {
            'custom:panelLimit': (30 - parseInt(state.fields.panels)).toString(),
            'custom:initPurchaseAmount': state.fields.price.toString(),
            'custom:initialPanelAmount': state.fields.panels.toString(),
            'custom:initialKwp': state.fields.kWp.toString(),
            'custom:totalKwp': state.fields.kWp.toString(),
            'custom:totalPanels': state.fields.panels.toString(),
            'custom:totalContracts': '1'
          }
          await updateSignUpState(
            appState.user.activeStep + 1,
            '99',
            'false',
            attributes
          )
        } catch (e) {
          updateMessageState(
            e.message,
            <ErrorOutlineIcon></ErrorOutlineIcon>,
            '#D8000C',
            '#FFD2D2'
          )
        }
        break
      case 2:
        let attributes = {}

        fields.bankInfoSkipped
          ? (attributes = { 'custom:bankInfoSkipped': 'true' })
          : (attributes = {
              'custom:bankAccountType':
                fields?.bankAccountType ?? appState.user?.bankAccountType,
              'custom:bankAccountName':
                fields?.bankAccountName ?? appState.user?.bankAccountName,
              'custom:bankAccountNumber':
                fields?.bankAccountNumber?.toString() ??
                appState.user?.bankAccountNumber.toString(),
              'custom:residencyFileUrl': appState.user.residencyFileUrl,
              'custom:bankCode': fields?.bankCode ?? appState.user?.bankCode,
              'custom:bankName': fields?.bankName ?? appState.user?.bankName,
              'custom:bankLocation':
                fields?.bankLocation ?? appState.user?.bankLocation,
              'custom:bankBranch':
                fields?.bankBranch?.toString() ??
                appState.user?.bankBranch.toString(),
              'custom:bankInfoSkipped': 'false',
              'custom:bankAccountVerified': 'false'
            })
        await updateSignUpState(
          appState.user.activeStep + 1,
          99,
          'false',
          attributes
        )
        break
      case 3:
        setAppState({
          ...appState,
          isLoading: true
        })
        await createContract()
        await finishReturn()
        props.history.push('/')
        setAppState({
          ...appState,
          user: {
            ...appState.user,
            isSignedUp: true
          },
          isLoading: false,
          update: !appState.update
        })
        hideStepLoading()
    }
  }

  const handleNextSubStep = async () => {
    setStepLoading()
    // eslint-disable-next-line default-case
    switch (activeSubStep) {
      case 0:
        if (isConfirmed) {
          // check dirty data
          const isDirty =
            appState.user.firstName !== fields.firstName ||
            appState.user.lastName !== fields.lastName ||
            appState.user.phone !== fields.phone
          const isDirtyEmail = appState.user.email !== fields.email

          /**
           * If nothing changes then go to sub step 2
           */
          if (!isDirty && !isDirtyEmail) {
            await updateSignUpState(0, activeSubStep + 2, 'false')
            return
          }
          /**
           * If the user changes one of the fields: first name, last name, phone.
           * We only update the registration information. if the user changes more email,
           * in addition to updating registration information,
           * the user needs to verify the email
           */
          if (isDirty || isDirtyEmail) {
            let user = {}
            if (isDirty) {
              user = {
                given_name: fields.firstName,
                family_name: fields.lastName,
                phone_number: fields.phone
              }
            }

            if (isDirtyEmail) {
              user.email = fields.email
              user.email_verified = false
              await updateSignUpState(0, 1, 'false', user)
            } else {
              await updateSignUpState(0, 2, 'false', user)
            }
            return
          }
        } else {
          try {
            const user = await createUpdateUser()
            hideStepLoading()
            scrollToTop()
            if (user && user['code'] === 'UsernameExistsException') {
              updateMessageState(
                'An account with this email address already exists. Try logging in.',
                <ErrorOutlineIcon></ErrorOutlineIcon>,
                '#D8000C',
                '#FFD2D2'
              )
            }
          } catch (e) {
            updateMessageState(
              e.message,
              <ErrorOutlineIcon></ErrorOutlineIcon>,
              '#D8000C',
              '#FFD2D2'
            )
            hideStepLoading()
            scrollToTop()
          }
        }

        break
      case 1:
        if (isAuthenticated) {
          await verifyCurrentUser(scrollToTop, hideStepLoading)
        } else {
          await confirmUser(scrollToTop, hideStepLoading)
        }

        break
      case 2:
        try {
          const user = {
            address: fields.address,
            'custom:city': fields.city,
            'custom:parishProvinceState': fields.parishProvinceState,
            'custom:zipPostal': fields.zipPostal,
            'custom:country': fields.country,
            'custom:signupCompleted': 'false'
          }
          await updateSignUpState(
            0,
            appState.user.activeSubStep + 1,
            'false',
            user
          )
        } catch (e) {
          updateMessageState(
            e.message,
            <ErrorOutlineIcon></ErrorOutlineIcon>,
            '#D8000C',
            '#FFD2D2'
          )
          hideStepLoading()
        }
        break
      case 3:
        try {
          const attributes = {
            'custom:idUrl': appState.user.idUrl,
            'custom:residencyFileUrl': appState.user.residencyFileUrl,
            'custom:idFileType': appState.user.idFileType,
            'custom:residencyFileType': appState.user.residencyFileType
          }
          updateSignUpState(
            appState.user.activeStep + 1,
            99,
            'false',
            attributes
          )
        } catch (e) {
          updateMessageState(
            e.message,
            <ErrorOutlineIcon></ErrorOutlineIcon>,
            '#D8000C',
            '#FFD2D2'
          )
          hideStepLoading()
        }
        break
    }
  }

  function getSubStepContent() {
    switch (activeSubStep) {
      case 0:
        return <BasicInfo />
      case 1:
        return <ConfirmEmail />
      case 2:
        return <Address />
      case 3:
        return <Identification />
      case 99:
        return props.history.push('/')
      default:
        return 'Unknown stepIndex'
    }
  }

  function getStepContent() {
    switch (parseInt(appState.user.activeStep)) {
      case 0:
        return <BasicInfo />
      case 1:
        return <SelectPanels />
      case 2:
        return <BankInfo />
      case 3:
        return <ReviewFinish />
      case 4:
        return <Dashboard />
      case 99:
        return props.history.push('/')
      default:
        return 'Unknown stepIndex'
    }
  }

  return (
    <>
      <Container fixed className="container">
        <div className={classes.root}>
          <Grid
            container
            spacing={3}
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <Grid
              item
              xs={12}
              lg={10}
              xl={10}
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fafafa'
              }}
            >
              <Stepper
                activeStep={
                  parseInt(appState.user.activeStep) > 5
                    ? 0
                    : parseInt(appState.user.activeStep)
                }
                alternativeLabel
                style={{
                  backgroundColor: '#f5f7f9',
                  margin: -12
                }}
              >
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <ErrorDialog open={errorOpen} onClose={() => handleErrorClose()} />
            <LoginDialog
              open={loginOpen}
              onClose={() => handleLoginClose()}
              appProps={props}
            />
            <Grid
              item
              xs={12}
              lg={8}
              xl={8}
              style={{ alignItems: 'center', justifyContent: 'center' }}
              className={classes.wrapper}
            >
              {bActiveSubStep ? getSubStepContent() : getStepContent()}
              {/* {getNewStepContent()} */}
              {appState.stepLoading && (
                <div className={classes.loadingBlock}>
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <Grid
              item
              container
              xs={12}
              lg={8}
              xl={8}
              direction="row"
              justifyContent="flex-end"
            >
              {activeSubStep > 1 ? (
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={clsx(classes.bottomButton, classes.backButton)}
                  onClick={handleBack}
                  disabled={appState.stepLoading}
                >
                  Back
                </Button>
              ) : null}

              <Button
                disabled={!formValidator.enableButton || appState.stepLoading}
                variant="contained"
                size="large"
                color="primary"
                onClick={handleNext}
                className={clsx(classes.bottomButton, classes.nextButton)}
              >
                {appState.user.activeStep === steps.length - 1
                  ? 'Finish'
                  : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  )
}
