import passwordValidator from 'password-validator'

export default function validatePassword(password) {
  let schema = new passwordValidator()
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits() // Must have digits
    .has()
    .symbols() // Must have a symbol
    .has()
    .not()
    .spaces() // Should not have spaces
  return schema.validate(password)
}
