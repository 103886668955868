import React, { useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Paper,
  Grid,
  Slider
} from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import numeral from 'numeral'
import { useAppContext } from '../../context/appContext'
import useDashboard from '../../context/useDashboard'
import swal from '@sweetalert/with-react'
import { Bar } from 'react-chartjs-2'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BeatLoader from 'react-spinners/BeatLoader'

export default function PanelCalculator() {
  const {
    price,
    handleDashboardInvestmentChange,
    panels,
    kWp,
    purchaseContract,
    dollarReturn,
    percentageReturn
  } = useDashboard()
  const { appState } = useAppContext()
  const panelLimit = parseInt(appState.user.panelLimit)
  const matchedMax1024 = useMediaQuery('(max-width:1024px)')
  const matchedMin961 = useMediaQuery('(min-width:961px)')
  const matchedMax700 = useMediaQuery('(max-width:700px)')
  const matchedMax600 = useMediaQuery('(max-width:600px)')
  const matchedMax480 = useMediaQuery('(max-width:479px)')
  const matchedMax400 = useMediaQuery('(max-width:399px)')

  const handlePurchase = () => {
    swal({
      title: 'Please confirm your purchase',
      text: `By clicking ok you are confirming your purchase for an additional CloudSolar digital system in the amount of $${price.toLocaleString()}. We will initiate a debit from your bank account on file within 3 business days.`,
      icon: 'warning',
      buttons: true
    }).then(willPurchase => {
      if (willPurchase) {
        swal({
          title: 'Purchasing your panels',
          text: `Please wait while we purchase your additional panels...`,
          icon: 'info',
          buttons: false,
          closeOnClickOutside: false
        })
        purchaseContract()
      } else {
      }
    })
  }

  const AmountSlider = createTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: '#1957A6',
          height: 8
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: '#fff',
          border: '2px solid currentColor',
          marginTop: -8,
          marginLeft: -12,
          '&:focus,&:hover,&$active': {
            boxShadow: 'inherit'
          }
        },
        active: {},
        valueLabel: {
          left: 'calc(-50% + 14px)',
          top: -22,
          '& *': {
            background: 'transparent',
            color: '#000'
          }
        },
        track: {
          height: 8,
          borderRadius: 4
        },
        rail: {
          height: 8,
          borderRadius: 4
        }
      }
    }
  })

  const labelsFor400 = [1, 5, 9, 13, 17, 21, 25]

  const chartData = useMemo(() => {
    let data = [dollarReturn]
    let tempData = [dollarReturn]

    for (let i = 1; i < 25; i++) {
      if (matchedMax400) {
        const lastYear = tempData[i - 1]
        const minus = tempData[0] * 0.006 * i
        tempData.push(lastYear + (tempData[0] - minus))
        if (labelsFor400.indexOf(i) !== -1) {
          data.push(lastYear + (tempData[0] - minus))
        }
      } else if (matchedMax480) {
        const lastYear = tempData[i - 1]
        const minus = tempData[0] * 0.006 * i
        tempData.push(lastYear + (tempData[0] - minus))

        if (i % 2 === 0) {
          data.push(lastYear + (tempData[0] - minus))
        }
      } else {
        const lastYear = data[i - 1]
        const minus = data[0] * 0.006 * i
        data.push(lastYear + (data[0] - minus))
      }
    }
    return data
  }, [dollarReturn, labelsFor400, matchedMax400, matchedMax480])

  const getLabels = useMemo(() => {
    let labels = []
    if (matchedMax400) {
      labels = labelsFor400
    } else {
      for (let i = 0; i <= 25; i++) {
        if (i % 2 === 1) {
          labels.push(i)
        }
      }
    }
    return labels
  }, [labelsFor400, matchedMax400])

  const data = {
    labels: matchedMax480
      ? getLabels
      : Array(25)
          .fill()
          .map((_, index) => index + 1),
    fill: 'start',
    datasets: [
      {
        label: 'Projected 25 Year Cumulative Returns',
        data: chartData,
        backgroundColor: 'rgba(251,177,22,1)',
        borderColor: 'rgba(251,177,22,1)',
        pointBackgroundColor: '#ffffff',
        pointHoverBackgroundColor: 'rgb(251,177,22)',
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      }
    ]
  }

  const options = {
    maintainAspectRatio: true,
    legend: {
      display: true
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem) {
          return numeral(tooltipItem.yLabel).format('$0,0.00')
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(label, index, labels) {
              return numeral(label).format('$0,0.0a')
            }
          },
          scaleLabel: {
            display: matchedMax700
              ? !matchedMax700
              : !(matchedMax1024 && matchedMin961),
            labelString: 'BBD $'
          }
        }
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: 'transparent'
          },
          scaleLabel: {
            display: matchedMax700
              ? !matchedMax700
              : !(matchedMax1024 && matchedMin961),
            labelString: 'Years'
          }
        }
      ]
    }
  }

  return (
    <>
      {panelLimit !== 0 ? (
        <Paper
          style={{
            padding: matchedMax600 ? 0 : 16
            // minHeight: 300,
            // maxHeight: 500
          }}
          elevation={0}
        >
          <Card
            style={{
              // minHeight: 300,
              // maxHeight: 500,
              marginBottom: 16
            }}
            elevation={0}
          >
            <CardContent>
              <CardHeader
                title={`You can purchase up to ${appState.user.panelLimit} additional panels`}
                style={{ marginLeft: -16 }}
              ></CardHeader>
              <Typography variant="h6" gutterBottom style={{ color: '#000' }}>
                Selected number of panels: {`${panels} | ${kWp} kWp`}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                style={{ color: '#000', fontWeight: 600 }}
              >
                Price {numeral(price).format('$0,0')}
              </Typography>
              <ThemeProvider theme={AmountSlider}>
                <Slider
                  valueLabelDisplay="off"
                  defaultValue={0}
                  name="panels"
                  value={panels}
                  onChange={handleDashboardInvestmentChange}
                  step={1}
                  min={1}
                  max={parseInt(appState.user.panelLimit)}
                />
              </ThemeProvider>

              <div
                className="chart-step"
                style={{
                  position: 'relative'
                }}
              >
                <Bar data={data} options={options} />
              </div>

              <Grid
                item
                style={{
                  marginTop: 16,
                  alignContent: 'left',
                  alignItems: 'left'
                }}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ color: '#000', fontWeight: 600 }}
                >
                  Monthly Solar Credits:{' '}
                  {numeral(dollarReturn / 12).format('$0,0.00')}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ color: '#000', fontWeight: 600 }}
                >
                  Year 1 Solar Credits:{' '}
                  {numeral(dollarReturn).format('$0,0.00')}
                </Typography>
                <div style={{ marginBottom: 20 }}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ color: '#000', fontWeight: 600 }}
                  >
                    Estimated Annual Return: {percentageReturn.toFixed(2)}%
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom>
                    *Returns are illustrative and based on the purchase of a
                    digital solar panel for 25 years.
                  </Typography>
                </div>
              </Grid>
              <Grid container justifyContent="center" spacing={3}>
                <Grid
                  item
                  style={{
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="get_started"
                    onClick={handlePurchase}
                    // onClick={() => {
                    //   setAppState(appState => ({
                    //     ...appState,
                    //     showPurchaseAlert: true
                    //   }))
                    // }}
                    disabled={price === 0}
                  >
                    Purchase Panels
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      ) : (
        <>
          <Paper
            style={{
              minHeight: 265
            }}
            elevation={0}
          >
            <Card elevation={0}>
              <CardContent>
                <CardHeader
                  title={`You've purchased the maximum amount of solar panels
                  (30) that we currently allow per person. We plan to
                  increase this amount in the near future so stay tuned.`}
                ></CardHeader>
              </CardContent>
            </Card>
          </Paper>
        </>
      )}
    </>
  )
}
