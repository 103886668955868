import React from 'react'
// import CssBaseline from '@material-ui/core/CssBaseline'
import Header from '../header'
import { makeStyles } from '@material-ui/core/styles'

import { Container, Paper, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3))]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    justify: 'center',
    alignItems: 'center'
  },
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
    backgroundColor: '#f5f7f9'
  }
}))

export default function NotFound(props) {
  document.body.classList.remove(
    'ourproject',
    'question',
    'getStarted',
    'about-us',
    'contact-us',
    'howItWork',
    'dashboard'
  )
  document.body.classList.add('log-in')

  const classes = useStyles()
  return (
    <div id="wrapper">
      <Header />
      <Container fixed className="container">
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
            <Paper className={classes.paper} elevation={0}>
              <Typography variant="h2">
                Oops, we can't find that page
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
