import React, { useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import Popper from '@material-ui/core/Popper'
import { Link } from 'react-router-dom'
import Button from './Button.js'
import styles from './customDropdownStyle.js'
import { useAppContext } from '../../context/appContext'
import { Auth } from 'aws-amplify'

export default function CustomDropdown(props) {
  const history = useHistory()
  const useStyles = makeStyles(styles)
  const { appState, setAppState } = useAppContext()
  const handleLogout = async () => {
    await Auth.signOut()
    setAppState({
      ...appState,
      user: {
        ...appState.user,
        isAuthenticated: false,
        isSignedUp: false,
        sub: '',
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        parishProvinceState: '',
        country: '',
        zipPostal: '',
        phone: '',
        email: '',
        password: '',
        idUrl: '',
        idVerified: false,
        activeStep: 99,
        activeSubStep: 0,
        autoWithdrawal: false,
        UserNotConfirmedException: false,
        bankAccountName: '',
        bankAccountNumber: '',
        residencyFileUrl: '',
        bankName: '',
        bankLocation: '',
        bankLocations: [],
        bankBranch: '',
        bankTerms: '',
        bankInfoSkipped: false,
        bankVerified: false,
        bankAccountVerified: false,
        initialPanelAmount: 1,
        panelLimit: 0,
        creditsTotal: 0,
        acTotal: 0,
        returnsTotal: 0,
        contracts: [],
        transactions: [],
        contractsTotal: 0,
        isConfirmed: false
      },
      update: !appState.update,
      fields: {
        messageContent: '',
        icon: '',
        color: '',
        backgroundColor: '',
        displayMessage: false
      }
    })
    localStorage.clear()
    history.push('/')
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = event => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleCloseAway = event => {
    if (anchorEl.contains(event.target)) {
      return
    }
    setAnchorEl(null)
  }
  const classes = useStyles()
  const {
    buttonText,
    buttonIcon,
    dropdownList,
    buttonProps,
    dropup,
    dropdownHeader,
    caret,
    hoverColor,
    left,
    rtlActive,
    noLiPadding,
    navDropdown
  } = props

  useEffect(() => {
    const unListen = history.listen(() => {
      setAnchorEl(null)
    })

    return unListen
  }, [history])

  const caretClasses = classNames({
    [classes.caret]: true,
    [classes.caretActive]: Boolean(anchorEl),
    [classes.caretRTL]: rtlActive
  })
  const dropdownItem = classNames({
    [classes.dropdownItem]: true,
    [classes[hoverColor + 'Hover']]: true,
    [classes.noLiPadding]: noLiPadding,
    [classes.dropdownItemRTL]: rtlActive
  })
  let icon = null
  switch (typeof buttonIcon) {
    case 'object':
      icon = <props.buttonIcon className={classes.buttonIcon} />
      break
    case 'string':
      icon = <Icon className={classes.buttonIcon}>{props.buttonIcon}</Icon>
      break
    default:
      icon = null
      break
  }
  return (
    <div>
      <div>
        <Button
          aria-label="Notifications"
          aria-owns={anchorEl ? 'menu-list' : null}
          aria-haspopup="true"
          {...buttonProps}
          onClick={handleClick}
        >
          {icon}
          {buttonText !== undefined ? buttonText : null}
          {caret ? <b className={caretClasses} /> : null}
        </Button>
      </div>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement={
          dropup
            ? left
              ? 'top-start'
              : 'top'
            : left
            ? 'bottom-start'
            : 'bottom'
        }
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
          [classes.pooperNav]: Boolean(anchorEl) && navDropdown
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={
              dropup
                ? { transformOrigin: '0 100% 0' }
                : { transformOrigin: '0 0 0' }
            }
          >
            <Paper className={classes.dropdown} elevation={3}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <MenuList role="menu" className={classes.menuList}>
                  {dropdownHeader !== undefined ? (
                    <MenuItem
                      // onClick={() => handleClose(dropdownHeader)}
                      className={classes.dropdownHeader}
                    >
                      {dropdownHeader}
                    </MenuItem>
                  ) : null}
                  {dropdownList.map((prop, key) => {
                    if (prop.divider) {
                      return (
                        <Divider
                          key={key}
                          // onClick={() => handleClose('divider')}
                          className={classes.dropdownDividerItem}
                        />
                      )
                    }
                    if (prop.key === 'logout') {
                      return (
                        <Link to="#" onClick={() => handleLogout()} key={key}>
                          <MenuItem key={key} className={dropdownItem}>
                            {prop}
                          </MenuItem>
                        </Link>
                      )
                    } else if (prop.key === 'contracts') {
                      return (
                        <Link to="/contracts/" key={key}>
                          <MenuItem key={key} className={dropdownItem}>
                            {prop}
                          </MenuItem>
                        </Link>
                      )
                    } else if (prop.key === 'profile') {
                      return (
                        <Link to="/profile/" key={key}>
                          <MenuItem key={key} className={dropdownItem}>
                            {prop}
                          </MenuItem>
                        </Link>
                      )
                    } else
                      return (
                        <Link to="/" key={key}>
                          <MenuItem
                            key={key}
                            // onClick={() => handleClose(prop)}
                            className={dropdownItem}
                          >
                            {prop}
                          </MenuItem>
                        </Link>
                      )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

CustomDropdown.defaultProps = {
  caret: true,
  hoverColor: 'primary'
}

CustomDropdown.propTypes = {
  hoverColor: PropTypes.oneOf([
    'black',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ]),
  buttonText: PropTypes.node,
  buttonIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  left: PropTypes.bool,
  noLiPadding: PropTypes.bool,
  navDropdown: PropTypes.bool,
  // function that retuns the selected item
  onClick: PropTypes.func
}
