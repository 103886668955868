import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Grid, Hidden } from '@material-ui/core'
import { useAppContext } from '../../context/appContext'
import CustomDropdown from '../reusable/CustomDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileContract,
  faUserCog,
  faTachometerAltFastest,
  faSignOut
} from '@fortawesome/pro-light-svg-icons'
import { makeStyles } from '@material-ui/core/styles'
import useStepper from '../../context/useStepper'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    },
    button: {
      color: '',
      boxShadow: 'none',
      borderRadius: 'none',
      backgroundColor: 'none'
    }
    // a: {
    //   color: '#333'
    // }
  }
}))

export default function LoginHeader() {
  const { appState } = useAppContext()
  const { fields } = useStepper()

  const name =
    fields !== undefined && fields.firstName !== ''
      ? fields.firstName
      : appState.user.firstName

  const classes = useStyles()
  return (
    <>
      {!appState.user.isSignedUp && appState.user.isAuthenticated ? (
        <Hidden only={['xs', 'sm']}>
          <Grid item lg={12} xl={10}>
            <div className={classes.root}>
              <CustomDropdown
                className={classes.button}
                buttonText={`Hi ${name}`}
                buttonProps={{
                  color: 'primary'
                }}
                dropdownList={[
                  <React.Fragment key={'logout'}>
                    <FontAwesomeIcon
                      icon={faSignOut}
                      style={{
                        fontSize: 22,
                        color: 'Dodgerblue',
                        marginRight: 10
                      }}
                    />
                    Logout
                  </React.Fragment>
                ]}
              />
            </div>
          </Grid>
        </Hidden>
      ) : !appState.isLoading &&
        appState.user.isAuthenticated &&
        appState.user.isSignedUp ? (
        <Hidden only={['xs', 'sm']}>
          <Grid item lg={12} xl={10}>
            <div className={classes.root}>
              <CustomDropdown
                className={classes.button}
                buttonText={`Hi ${name}`}
                buttonProps={{
                  color: 'primary'
                }}
                dropdownList={[
                  <React.Fragment key={'dashboard'}>
                    <FontAwesomeIcon
                      icon={faTachometerAltFastest}
                      style={{
                        fontSize: 18,
                        color: 'Dodgerblue',
                        marginRight: 10
                      }}
                    />
                    Dashboard
                  </React.Fragment>,
                  <React.Fragment key={'contracts'}>
                    <FontAwesomeIcon
                      icon={faFileContract}
                      style={{
                        fontSize: 18,
                        color: 'Dodgerblue',
                        marginRight: 16
                      }}
                    />
                    Contracts
                  </React.Fragment>,
                  <React.Fragment key={'profile'}>
                    <FontAwesomeIcon
                      icon={faUserCog}
                      style={{
                        fontSize: 18,
                        color: 'Dodgerblue',
                        marginRight: 10
                      }}
                    />
                    Profile
                  </React.Fragment>,
                  { divider: true },
                  <React.Fragment key={'logout'}>
                    <FontAwesomeIcon
                      icon={faSignOut}
                      style={{
                        fontSize: 22,
                        color: 'Dodgerblue',
                        marginRight: 10
                      }}
                    />
                    Logout
                  </React.Fragment>
                ]}
              />
            </div>
          </Grid>
        </Hidden>
      ) : (
        <>
          <Link to="/login/">
            <Button
              color="inherit"
              variant="contained"
              className="login"
              // disabled={appState.isLoading}
            >
              LOGIN
            </Button>
          </Link>

          <Link to="/get-started/">
            <Button
              variant="contained"
              color="secondary"
              className="get_started"
              // disabled={appState.isLoading}
            >
              GET STARTED
            </Button>
          </Link>
        </>
      )}
    </>
  )
}
