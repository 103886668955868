import React, { useState } from 'react'
import {
  makeStyles,
  createTheme,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles'
import numeral from 'numeral'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import useStepper from '../../context/useStepper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CheckCircle from '@material-ui/icons/CheckCircle'
import {
  faAddressCard,
  faDollarSign,
  faLandmark,
  faCloudUpload,
  faFlagCheckered
} from '@fortawesome/pro-light-svg-icons'
import { useAppContext } from '../../context/appContext'
import green from '@material-ui/core/colors/green'
import { pdfjs, Document, Page } from 'react-pdf'
import DSADialog from './DSADialog'

const useStyles = makeStyles({
  h1: {
    fontSize: 70,
    fontWeight: 'bold',
    color: '#FFF',
    letterSpacing: 0.5,
    marginTop: 50
  },
  h1p1: {
    fontSize: 22,
    color: '#fff',
    lineHeight: '130%',
    fontWeight: 'normal'
  },
  heading: {
    fontSize: 18,
    color: '#1957a6'
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170'
    // minHeight: 130
  },
  title: {
    fontSize: 28,
    color: '#1957a6'
  },
  accountTitle: {
    fontSize: '.40em',
    fontWeight: 800,
    color: '#3d5170'
  },
  greeting: {
    fontSize: 18,
    color: '#1957a6',
    marginBottom: '3vh'
  },
  faIcon: {
    fontSize: 36,
    color: 'Dodgerblue'
  }
})

const theme = createTheme({
  palette: {
    primary: green
  }
})

const ColorButton = withStyles(theme => ({
  root: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}))(Button)

export default function ReviewFinish() {
  const [open, setOpen] = useState(false)
  const { appState } = useAppContext()
  const { calcDollarReturn, calcPercentReturn } = useStepper()
  const classes = useStyles()
  const [
    ,
    // numPages
    setNumPages
  ] = useState(null)
  const [
    pageNumber
    // setPageNumber
  ] = useState(1)
  const { fields, setState } = useStepper()

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const setAgree = () => {
    setState(state => ({
      ...state,
      fields: { ...state.fields, agreeDSA: true }
    }))
    setOpen(false)
  }

  const save = () => {}

  // const label = (
  //   <div className={classes.termsLabel}>
  //     <Button onClick={() => handleClickOpen()}>
  //       Please click here to read and agree to the Digital Solar Agreement
  //     </Button>
  //   </div>
  // )

  const _dollarReturn = () => {
    const panels = appState.user?.initialPanelAmount ?? 0
    return calcDollarReturn(parseInt(panels, 10))
  }
  const _percentReturn = () => {
    const panels = appState.user?.initialPanelAmount ?? 0
    return calcPercentReturn(parseInt(panels, 10))
  }

  return (
    <div>
      <CssBaseline />
      <Paper style={{ padding: 16 }} elevation={0}>
        <DSADialog
          open={open}
          close={handleClose}
          agree={setAgree}
          save={save}
        />
        <Typography
          variant="h4"
          align="center"
          component="h1"
          style={{ color: '#1957a6' }}
          gutterBottom
        >
          <FontAwesomeIcon icon={faFlagCheckered} />
          {'   '}Review & Finish
        </Typography>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12} lg={6} xl={6}>
            <Paper elevation={0}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <FontAwesomeIcon
                      icon={faAddressCard}
                      className={classes.faIcon}
                    />
                  }
                  title="Basic Info"
                  classes={{
                    title: classes.title
                  }}
                />
                <CardContent style={{ marginTop: '-2vh' }}>
                  <Typography>
                    {fields.firstName || appState.user.firstName}{' '}
                    {fields.lastName || appState.user.lastName}
                  </Typography>
                  <Typography>
                    {fields.address || appState.user.address}
                  </Typography>
                  <Typography>
                    {fields.city || appState.user.city}{' '}
                    {fields.parishProvinceState ||
                      appState.user.parishProvinceState}{' '}
                    {fields.zipPostal || appState.user.zipPostal}
                  </Typography>
                  <Typography>{fields.phone || appState.user.phone}</Typography>
                  <Typography>{fields.email || appState.user.email}</Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6} xl={6}>
            <Paper elevation={0}>
              <Card className={classes.card} style={{ minHeight: 216 }}>
                <CardHeader
                  avatar={
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      className={classes.faIcon}
                    />
                  }
                  title="Panel Info"
                  classes={{
                    title: classes.title
                  }}
                />
                <CardContent style={{ marginTop: '-2vh' }}>
                  <Typography variant="body1">
                    Number of panels: {appState.user.initialPanelAmount} |{' '}
                    {appState.user.initialKwp} kWp
                  </Typography>
                  <Typography variant="body1">
                    Purchase price:{' '}
                    {numeral(appState.user.initPurchaseAmount).format(
                      '$0,0.00'
                    )}
                  </Typography>
                  <Typography variant="body1">
                    Estimated Monthly Solar Credits:{' '}
                    {numeral(_dollarReturn() / 12).format('$0,0.00')}
                  </Typography>
                  <Typography variant="body1">
                    Estimated Year 1 Solar Credits:{' '}
                    {numeral(_dollarReturn()).format('$0,0.00')}
                  </Typography>
                  <Typography variant="body1">
                    *Projected Return:{' '}
                    {numeral(_percentReturn()).format('0.00%')}
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6} xl={6}>
            <Paper elevation={0}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <FontAwesomeIcon
                      icon={faLandmark}
                      className={classes.faIcon}
                    />
                  }
                  title="Bank Info"
                  classes={{
                    title: classes.title
                  }}
                />
                <CardContent style={{ marginTop: '-2vh' }}>
                  <Typography>
                    Bank name: {fields.bankName || appState.user.bankName}
                  </Typography>
                  <Typography>
                    Location:{' '}
                    {fields.bankLocation || appState.user.bankLocation}
                  </Typography>
                  <Typography>
                    Account holder:{' '}
                    {fields.firstName || appState.user.firstName}{' '}
                    {fields.lastName || appState.user.lastName}
                  </Typography>
                  <Typography>
                    Account number:{' '}
                    {fields.bankAccountNumber ||
                      appState.user.bankAccountNumber}
                  </Typography>
                  <Typography>
                    Account type:{' '}
                    {fields.bankAccountType || appState.user.bankAccountType}
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6} xl={6}>
            <Paper elevation={0}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <FontAwesomeIcon
                      icon={faCloudUpload}
                      className={classes.faIcon}
                    />
                  }
                  title="Documents"
                  classes={{
                    title: classes.title
                  }}
                />
                <CardContent style={{ marginTop: '-2vh' }}>
                  {appState.user.idFileType === 'application/pdf' ? (
                    <Document
                      file={appState.user.idUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                      className={classes.pdfDocument}
                    >
                      <Page
                        pageNumber={pageNumber}
                        scale="0.9"
                        renderMode="svg"
                      />
                    </Document>
                  ) : (
                    <>
                      <img src={appState.user.idUrl} alt="ID doc" />
                    </>
                  )}
                  {appState.user.residencyFileType === 'application/pdf' ? (
                    <Document
                      file={appState.user.residencyFileUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page
                        pageNumber={pageNumber}
                        scale={0.9}
                        renderMode="svg"
                      />
                    </Document>
                  ) : (
                    <>
                      <img
                        src={appState.user.residencyFileUrl}
                        alt="Residency doc"
                      />
                    </>
                  )}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          lg={12}
          xl={12}
          md={12}
          style={{ marginTop: 20, justifyContent: 'center' }}
        >
          <div style={{ justifyContent: 'center' }}>
            {/* <Button color="primary" onClick={() => handleClickOpen()}>
              {' '}
              Please click here to read and agree to the Digital Solar Agreement
            </Button> */}
            <ThemeProvider theme={theme}>
              <Button
                variant="outlined"
                startIcon={<CheckCircle />}
                color="primary"
                size="small"
                onClick={() => handleClickOpen()}
              >
                Please click here to read and agree to the Digital Solar
                Agreement
              </Button>
            </ThemeProvider>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
