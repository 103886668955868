import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Container, CssBaseline, Grid } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import Bank from './profile/bank'
import Id from './profile/id'
import Name from './profile/name'
import Password from './profile/password'
import { useAppContext } from '../../context/appContext'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    // backgroundColor: '#F5F7F9',
    marginTop: '7vh'
  },
  appBar: {
    backgroundColor: 'none'
  }
})

export default function Profile() {
  document.body.classList.remove(
    'ourproject',
    'question',
    'howItWork',
    'getStarted',
    'about-us',
    'contact-us',
    'log-in',
    'home'
  )
  document.body.classList.add('dashboard')
  const { appState } = useAppContext()

  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container fixed spacing={3} direction="row" className="container">
        <Grid
          container
          spacing={3}
          style={{ marginTop: 20 }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} xl={8} lg={8} md={8}>
            <div className={classes.root}>
              <div className={classes.greeting}>
                <Typography gutterBottom variant={'h6'}>
                  {appState.user.accountMessage}
                </Typography>
              </div>
              <AppBar position="static" color="inherit" elevation={1}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="on"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    label="Address"
                    icon={<HomeOutlinedIcon />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Password"
                    icon={<LockOutlinedIcon />}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label="Identification"
                    icon={<ContactMailOutlinedIcon />}
                    {...a11yProps(2)}
                  />
                  <Tab
                    label="Bank Info"
                    icon={<AccountBalanceOutlinedIcon />}
                    {...a11yProps(3)}
                  />
                  {/* <Tab
                    label="Electricity Bill"
                    icon={<OfflineBoltOutlinedIcon />}
                    {...a11yProps(4)}
                  /> */}
                  {/* <Tab
                    label="Withdrawals"
                    icon={<AttachMoneyOutlinedIcon />}
                    {...a11yProps(4)}
                  /> */}
                  {/* <Tab
                    label="Email Preferences"
                    icon={<Notifications />}
                    {...a11yProps(4)}
                  /> */}
                  {/* <Tab
                    label="Security"
                    icon={<AttachMoneyOutlinedIcon />}
                    {...a11yProps(5)}
                  /> */}
                </Tabs>
              </AppBar>
              <Grid container spacing={0} justify="center" alignItems="center">
                <Grid item xs={12} sm={12} xl={10} lg={10} md={8}>
                  <TabPanel value={value} index={0} className="tabs">
                    <Name />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Password />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Id />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Bank />
                  </TabPanel>
                  {/* <TabPanel value={value} index={4}>
                    <Withdrawals />
                  </TabPanel> */}
                  {/* <TabPanel value={value} index={4}>
                    <Alerts />
                  </TabPanel> */}
                  {/* <TabPanel value={value} index={5}>
                    <Security />
                  </TabPanel> */}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
