import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import styled from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'
import useStepper from '../../context/useStepper'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import {
  FormControl,
  Input,
  InputLabel,
  Button as MuiButton,
  Divider as MuiDivider,
  Grid
} from '@material-ui/core'
import { spacing } from '@material-ui/system'

const Button = styled(MuiButton)(spacing)

const Divider = styled(MuiDivider)(spacing)

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    justify: 'center',
    alignItems: 'center'
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(8)}px ${theme.spacing(10)}px ${theme.spacing(
      10
    )}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  }
}))

export default function LoginInDialog(props) {
  const userHasAuthenticated = props.appProps.userHasAuthenticated
  const history = props.appProps.history
  const [isLoading, setIsLoading] = useState(false)
  const { setLoginInfo, login } = useStepper()
  const classes = useStyles()
  function validateForm() {
    return login.email.length > 0 && login.password.length > 0
  }

  async function handleSubmit(event) {
    event.preventDefault()

    setIsLoading(true)

    try {
      await Auth.signIn(login.email, login.password)
      userHasAuthenticated(true)
      history.push('/dashboard')
    } catch (e) {
      alert(e.message)
      //Need to hanlde incorrect username/password error:
      //{"__type":"NotAuthorizedException","message":"Incorrect username or password."}
      setIsLoading(false)
    }
  }
  return (
    <Grid container spacing={2} justifyContent="center" direction="column">
      <Grid item xs={12} align="center">
        <main className={classes.main}>
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Login to Cloud Solar
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <CssBaseline />
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img alt="Cloud solar Logo" src="logo.png" align="center" />
                </Grid>
                <form onSubmit={handleSubmit}>
                  <Divider
                    className={classes.submit}
                    variant="fullWidth"
                    light={false}
                  />
                  <FormControl
                    margin="normal"
                    required
                    fullWidth
                    autoFocus
                    type="email"
                    value={login.email}
                    onChange={setLoginInfo}
                  >
                    <InputLabel htmlFor="email">User Name</InputLabel>
                    <Input
                      id="email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    required
                    fullWidth
                    type="password"
                    value={login.password}
                    onChange={setLoginInfo}
                  >
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                      name="password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    isloading={isLoading ? 1 : 0}
                    disabled={!validateForm()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    mb={2}
                    className={classes.submit}
                  >
                    Sign in
                  </Button>
                </form>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </main>
      </Grid>
    </Grid>
  )
}
