import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography
} from '@material-ui/core'
import numeral from 'numeral'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { useAppContext } from '../../context/appContext'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '../common/FontAwesomeIcon'
import { pdfjs } from 'react-pdf'
import ContractPdfGenerate from './ContractPDFGenerate'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    padding: 8,
    position: 'relative'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 28,
    color: '#1957a6'
  },
  contract: {
    fontSize: 16,
    color: '#1957a6',
    marginBottom: 10
  },
  pos: {
    marginBottom: 12
  },
  faIcon: {
    fontSize: 36,
    color: 'Dodgerblue'
  },
  faInfo: {
    fontSize: 20,
    color: 'Dodgerblue',
    position: 'absolute',
    right: 8,
    '@media (max-width:576px)': {
      fontSize: 30
    }
  },
  colorDefault: {
    backgroundColor: 'Dodgerblue'
  },
  divider: {
    background: 'DodgerBlue',
    marginBottom: 20
  }
})

export default function ListContracts() {
  const { appState } = useAppContext()
  const classes = useStyles()

  return (
    <>
      <Paper elevation={3}>
        <Card className={classes.root}>
          <Tooltip
            interactive
            enterTouchDelay={150}
            leaveTouchDelay={3000}
            title="The various contracts for solar panels you own"
          >
            <Icon icon={faInfoCircle} className={classes.faInfo} />
          </Tooltip>
          <CardHeader
            avatar={
              <FontAwesomeIcon
                icon={faFileContract}
                className={classes.faIcon}
              />
            }
            title="Contracts"
            classes={{
              title: classes.title
            }}
          />
          <Divider className={classes.divider} />
          <CardContent>
            <Typography>
              Total solar contracts: {appState.user.totalContracts}
            </Typography>
            {appState.user?.contracts?.length
              ? appState.user.contracts.map((contract, index) => (
                  <Box
                    key={contract.contractId}
                    display="block"
                    style={{
                      fontSize: 40,
                      fontWeight: 600,
                      color: '#3d5170',
                      minHeight: 80
                    }}
                  >
                    <List dense>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            style={{ marginLeft: -20 }}
                            classes={{
                              colorDefault: classes.colorDefault
                            }}
                          >
                            {index + 1}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          style={{ marginLeft: -20 }}
                          primary={`${
                            contract.panels > 1
                              ? `${contract.panels} panels`
                              : `${contract.panels} panel`
                          } |  kWp: ${contract.kWp}`}
                          secondary={
                            <>
                              Price:{' '}
                              {numeral(contract.amount).format('$0,0.00')}
                              <br />
                              Purchased on:{' '}
                              <Moment format="MM/DD/YYYY">
                                {contract.createdAt}
                              </Moment>
                              {}
                              <br />
                              Active date:{' '}
                              {contract.activeDate !== '' ? (
                                <Moment format="MM/DD/YYYY">
                                  {contract.activeDate}
                                </Moment>
                              ) : (
                                'Not active yet'
                              )}
                              <br />
                              End date:{' '}
                              {contract.activeDate !== '' ? (
                                <Moment format="MM/DD/YYYY">
                                  {contract.endDate}
                                </Moment>
                              ) : (
                                'Not active yet'
                              )}
                              <br />
                              <Link
                                component="button"
                                variant="body2"
                                onClick={() =>
                                  ContractPdfGenerate({
                                    date: new Date(contract.createdAt),
                                    id: contract.contractId,
                                    ip: contract.ipAddress,
                                    name: `${appState.user.firstName} ${appState.user.lastName}`
                                  })
                                }
                              >
                                Download Contract
                              </Link>
                            </>
                          }
                        />
                      </ListItem>
                    </List>
                  </Box>
                ))
              : null}
          </CardContent>
        </Card>
      </Paper>
    </>
  )
}
