import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useAppContext } from '../../context/appContext'
import {
  Card,
  CardContent,
  Grid,
  Paper,
  CardHeader
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx'
import Icon from '../common/FontAwesomeIcon'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#F5F7F9'
  },
  h1: {
    fontSize: 70,
    fontWeight: 'bold',
    color: '#FFF',
    letterSpacing: 0.5,
    marginTop: 50
  },
  h1p1: {
    fontSize: 22,
    color: '#fff',
    lineHeight: '130%',
    fontWeight: 'normal'
  },
  heading: {
    fontSize: 18,
    color: '#1957a6'
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170',
    minHeight: 150,
    padding: 8,
    position: 'relative',
  },
  title: {
    fontSize: 28,
    color: '#1957a6'
  },
  accountTitle: {
    fontSize: '.40em',
    fontWeight: 800,
    color: '#3d5170'
  },
  greeting: {
    fontSize: 18,
    color: '#1957a6',
    marginTop: '16vh',
    marginBottom: '3vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: '14vh'
    }
  },
  faIcon: {
    fontSize: 36,
    color: 'Dodgerblue'
  },
  faInfo: {
    fontSize: 20,
    color: 'Dodgerblue',
    position: 'absolute',
    right: 8,
    '@media (max-width:576px)': {
      fontSize: 30,
    },
  },
  divider: {
    background: 'DodgerBlue',
    marginBottom: 20
  }
}))

export default function Returns({ className }) {
  const { appState } = useAppContext()

  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} xl={3} lg={3} md={6}>
      <Paper elevation={3}>
        <Card className={clsx(classes.card, className)}>
          <Tooltip interactive enterTouchDelay={150} leaveTouchDelay={3000} arrow title="The annualized return of your solar credits">
            <Icon icon={faInfoCircle} className={classes.faInfo} />
          </Tooltip>
          <CardHeader
            avatar={
              <FontAwesomeIcon icon={faChartLine} className={classes.faIcon} />
            }
            title="Return"
            classes={{
              title: classes.title
            }}
          />
          <Divider className={classes.divider} />
          <CardContent style={{ marginTop: '-2vh' }}>
            {appState.user.returnPercentage <= 0 ? (
              <div style={{ fontSize: 14 }}>
                Your first transaction hasn't posted to your account yet.
              </div>
            ) : (
              parseFloat(appState.user.returnPercentage).toFixed(2) + '%'
            )}
          </CardContent>
        </Card>
      </Paper>
    </Grid>
  )
}
