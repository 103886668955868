import React, { useEffect } from 'react'
import {
  Typography,
  Paper,
  Grid,
  CssBaseline,
  FormLabel,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'
import useStepper from '../../context/useStepper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLandmark } from '@fortawesome/pro-light-svg-icons'
import { useAppContext } from '../../context/appContext'

export default function BankInfo(props) {
  const { fields, updateFields, setState } = useStepper()
  const { appState } = useAppContext()

  useEffect(() => {
    const {
      bankAccountType,
      bankName = '',
      bankAccountNumber,
      bankBranch,
      bankLocation
    } = appState.user

    let bank = fields.banks.find(o => o.name === bankName)
    let locations = []

    if (bank) {
      Object.keys(bank.locations).forEach(function(key) {
        locations.push(bank.locations[key].name)
      })
    }

    setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        bankAccountType,
        bankName,
        bankAccountNumber,
        bankBranch,
        bankLocations: locations,
        bankLocation
      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = event => {
    setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        bankAccountType: event.target.value
      }
    }))
  }

  const handleSelectBankChange = e => {
    let bank = fields.banks.find(o => o.name === e.target.value)
    let locations = []
    Object.keys(bank.locations).forEach(function(key) {
      locations.push(bank.locations[key].name)
    })
    setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [e.target.name]: e.target.value,
        bankCode: bank.bank_code,
        bankLocations: locations,
        bankLocation: '',
        bankBranch: ''
      }
    }))
  }

  const handleSelectLocationChange = e => {
    let bankName = fields.banks.find(o => o.name === fields.bankName)
    let bankBranch = ''
    Object.keys(bankName.locations).forEach(function(key) {
      if (bankName.locations[key].name === e.target.value) {
        bankBranch = bankName.locations[key].branch
      }
    })

    setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [e.target.name]: e.target.value,
        bankBranch
      }
    }))
  }

  return (
    <>
      <CssBaseline />
      <Paper style={{ padding: 20, marginTop: 20 }} elevation={2}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ padding: 16 }}
        >
          <Typography
            variant="h4"
            align="center"
            component="h1"
            style={{ color: '#1957a6' }}
            gutterBottom
          >
            <FontAwesomeIcon icon={faLandmark} />
            {'   '}Bank Info
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ paddingBottom: 10 }}
        >
          <Grid item xs={12} lg={8} xl={8} md={12}>
            <Alert severity="warning">
              Your bank account info is needed to process the payment for your
              panels and to deposit your monthly solar credits.{' '}
              <b>
                Cloud Solar will not process your payment without first making
                contact with you to verify your information.
              </b>{' '}
              We securely store and encrypt all sensitive user data.
            </Alert>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} lg={8} xl={8} md={12}>
            <FormLabel component="legend">Account Type</FormLabel>
            <RadioGroup
              aria-label="bankAccountType"
              name="bankAccountType"
              value={fields.bankAccountType}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="Checking"
                control={<Radio />}
                label="Checking"
              />
              <FormControlLabel
                value="Savings"
                control={<Radio />}
                label="Savings"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} lg={8} xl={8} md={12}>
            <TextField
              fullWidth
              disabled
              required
              name="bankAccountName"
              type="text"
              label="Bank Account Holder's Name"
              variant="outlined"
              // onChange={e => updateFields(e)}
              value={`${appState.user.firstName} ${appState.user.lastName}`}
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={8}>
            <TextField
              onChange={e => handleSelectBankChange(e)}
              name="bankName"
              value={fields.bankName}
              label="Bank Name"
              variant="outlined"
              fullWidth
              required
              select
              InputLabelProps={{
                shrink: true
              }}
              disabled={fields.bankInfoSkipped}
            >
              {fields.bankNames.map((item, idx) => (
                <MenuItem
                  key={idx}
                  value={item}
                  style={{ padding: 5, fontSize: 16 }}
                >
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={8} xl={8}>
            <TextField
              value={fields.bankLocation}
              onChange={e => handleSelectLocationChange(e)}
              name="bankLocation"
              label="Bank Location"
              variant="outlined"
              fullWidth
              select
              required
              InputLabelProps={{
                shrink: true
              }}
              disabled={fields.bankInfoSkipped || !fields.bankLocations?.length}
            >
              {fields.bankLocations.map((item, idx) => (
                <MenuItem
                  key={idx}
                  value={item}
                  style={{ padding: 5, fontSize: 16 }}
                >
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={8} xl={8}>
            <TextField
              fullWidth
              required
              disabled
              name="bankBranch"
              type="text"
              label="Bank Branch"
              variant="outlined"
              value={fields.bankBranch}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={8}>
            <TextField
              fullWidth
              required
              name="bankAccountNumber"
              type="text"
              label="Bank Account Number"
              variant="outlined"
              onChange={e => updateFields(e)}
              value={fields.bankAccountNumber}
              disabled={fields.bankInfoSkipped}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
