import React from 'react'
import Header from '../header'
import { makeStyles } from '@material-ui/core/styles'
import ListContracts from './ListContracts'
import AddContracts from './AddContracts'
import MuiAlert from '@material-ui/lab/Alert'
import { Container, CssBaseline, Grid, Snackbar } from '@material-ui/core'
import useDashboard from '../../context/useDashboard'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    marginTop: '12vh',
    height: '100%'
  },
  appBar: {
    backgroundColor: 'none'
  }
})

export default function Contracts() {
  document.body.classList.remove(
    'ourproject',
    'question',
    'howItWork',
    'getStarted',
    'about-us',
    'contact-us',
    'log-in',
    'home'
  )
  document.body.classList.add('dashboard')

  const classes = useStyles()
  const { snackBarOpen, closeSnackBar } = useDashboard()

  return (
    <div className={classes.root}>
      <Header />
      <CssBaseline />
      <Container>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          // key={'top' + 'center'}
          open={snackBarOpen}
          autoHideDuration={6000}
          onClose={closeSnackBar}
        >
          <Alert onClose={closeSnackBar} severity="success">
            Panels successfully purchased!
          </Alert>
        </Snackbar>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={12}
            sm={12}
            xl={6}
            lg={6}
            md={6}
            style={{ marginTop: '5vh' }}
          >
            <ListContracts />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            xl={6}
            lg={6}
            md={6}
            style={{ marginTop: '5vh' }}
          >
            <AddContracts />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
