import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useAppContext } from '../../context/appContext'
import {
  Card,
  CardContent,
  Grid,
  Paper,
  CardHeader,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFluxCapacitor, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import clsx from 'clsx'
import Icon from '../common/FontAwesomeIcon'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#F5F7F9'
  },
  h1: {
    fontSize: 70,
    fontWeight: 'bold',
    color: '#FFF',
    letterSpacing: 0.5,
    marginTop: 50
  },
  h1p1: {
    fontSize: 22,
    color: '#fff',
    lineHeight: '130%',
    fontWeight: 'normal'
  },
  heading: {
    fontSize: 18,
    color: '#1957a6'
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170',
    minHeight: 150,
    padding: 8,
    position: 'relative'
  },
  title: {
    fontSize: 26,
    color: '#1957a6'
  },
  accountTitle: {
    fontSize: '.40em',
    fontWeight: 800,
    color: '#3d5170'
  },
  greeting: {
    fontSize: 18,
    color: '#1957a6',
    marginTop: '16vh',
    marginBottom: '3vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: '14vh'
    }
  },
  faIcon: {
    fontSize: 32,
    color: 'Dodgerblue'
  },
  faInfo: {
    fontSize: 20,
    color: 'Dodgerblue',
    position: 'absolute',
    right: 8,
    '@media (max-width:576px)': {
      fontSize: 30
    }
  },
  divider: {
    background: 'DodgerBlue',
    marginBottom: 20
  }
}))

export default function SystemSize({ className }) {
  const { appState } = useAppContext()
  // const { returnsTotal } = useDashboard()

  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} xl={3} lg={3} md={6}>
      <Paper elevation={3}>
        <Card className={clsx(classes.card, className)}>
          <Tooltip
            interactive
            enterTouchDelay={150}
            leaveTouchDelay={3000}
            arrow
            title="The total panels and kWp which we use to calculate your solar credits"
          >
            <Icon icon={faInfoCircle} className={classes.faInfo} />
          </Tooltip>
          <CardHeader
            avatar={
              <FontAwesomeIcon
                icon={faFluxCapacitor}
                className={classes.faIcon}
              />
            }
            title="System Size"
            classes={{
              title: classes.title
            }}
          />
          <Divider className={classes.divider} />
          <CardContent
            style={{
              marginTop: '-2.5vh',
              marginBottom: '.5vh',
              fontSize: 22,
              paddingBottom: '2.5vh'
            }}
          >
            <Typography style={{ color: '#3d5170', fontWeight: 700 }}>
              Total panels: {appState.user.panelTotal}
            </Typography>
            <Typography style={{ color: '#3d5170', fontWeight: 700 }}>
              kWp total: {appState.user.kwpTotal}
            </Typography>
          </CardContent>
        </Card>
      </Paper>
    </Grid>
  )
}
