import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import styled from 'styled-components'
import { css } from '@emotion/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import BeatLoader from 'react-spinners/BeatLoader'

import Header from '../header'
import PasswordDialog from '../dialogs/PasswordDialog'
import ChangePasswordDialog from '../dialogs/ChangePasswordDialog'
import { useAppContext } from '../../context/appContext'
import useStepper from '../../context/useStepper'

import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  OutlinedInput,
  InputLabel,
  Button as MuiButton,
  Paper,
  // Divider as MuiDivider,
  Grid,
  // Link,
  Typography
} from '@material-ui/core'
import { spacing } from '@material-ui/system'

const Button = styled(MuiButton)(spacing)

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

// const Divider = styled(MuiDivider)(spacing)

const styles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3))]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    justify: 'center',
    alignItems: 'center'
  },
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  infoMessage: {
    '& > svg': {
      marginRight: theme.spacing(1)
    }
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing()
  },
  submit: {
    height: 50,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    fontWeight: 700,
    fontSize: 18
  }
})

function Login(props) {
  document.body.classList.remove(
    'ourproject',
    'question',
    'getStarted',
    'about-us',
    'contact-us',
    'howItWork',
    'dashboard'
  )
  document.body.classList.add('log-in')

  const {
    appState,
    setAppState,
    updateMessageState,
    hideMessageState
  } = useAppContext()
  const { fields, resendCode } = useStepper()
  const [passwordOpen, setPasswordOpen] = useState(false)
  const [changePasswordOpen, setChangePasswordOpen] = useState(false)
  const [openLoader, setOpenLoader] = useState(false)
  const [user, setUser] = useState(null)
  const [state, setState] = useState({
    fields: {
      email: '',
      password: '',
      displayMessage: false,
      messageContent: '',
      passwordReset: false,
      color: '#4F8A10',
      backgroundColor: '#DFF2BF',
      icon: <CheckCircleOutlineIcon></CheckCircleOutlineIcon>,
      openLoader: false,
      userNotConfirmed: false
    }
  })

  useEffect(() => {
    return () => hideMessageState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function validateForm() {
    return state.fields.email.length > 0 && state.fields.password.length > 0
  }

  const handlePasswordOpen = () => {
    setPasswordOpen(true)
  }

  const handlePasswordClose = () => {
    setPasswordOpen(false)
  }

  const handleChangePasswordClose = () => {
    setChangePasswordOpen(false)
  }
  const handleLoggedUser = user => {
    setOpenLoader(true)
    if (user.attributes['custom:signupCompleted'] === 'true') {
      setAppState(appState => ({
        ...appState,
        user: {
          ...appState.user,
          isAuthenticated: true
        },
        isLoading: true,
        isAuthenticating: false,
        update: !appState.update
      }))
      setOpenLoader(false)
      props.history.push('/')
    } else {
      setAppState(appState => ({
        ...appState,
        isAuthenticating: false,
        isLoading: false,
        update: !appState.update,
        user: {
          ...appState.user,
          isAuthenticated: true,
          isSignedUp: false,
          sub: user.attributes.sub,
          firstName: user.attributes.given_name,
          email: user.attributes.email,
          activeStep:
            user.attributes['custom:activeStep'] === undefined ||
            user.attributes['custom:activeStep'] === null
              ? 0
              : parseInt(user.attributes['custom:activeStep']),
          activeSubStep:
            parseInt(user.attributes['custom:activeStep']) > 0 &&
            parseInt(user.attributes['custom:activeStep']) <= 5
              ? 99
              : user.attributes['custom:activeSubStep'] === undefined ||
                user.attributes['custom:activeSubStep'] === null
              ? 0
              : parseInt(user.attributes['custom:activeSubStep'])
        }
        // update: !appState.update
      }))
      setOpenLoader(false)
      props.history.push('/get-started/')
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setOpenLoader(true)
    try {
      const user = await Auth.signIn(state.fields.email, state.fields.password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(user)
        setOpenLoader(false)
        setChangePasswordOpen(true)
      } else {
        handleLoggedUser(user)
      }
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        setAppState(appState => ({
          ...appState,
          user: {
            ...appState.user,
            email: state.fields.email || fields.email,
            password: state.fields.password || fields.password,
            UserNotConfirmedException: true,
            activeSubStep: 1
          }
        }))
        resendCode(state.fields.email)
        props.history.push('/get-started/')
      } else if (e.code === 'LimitExceededException') {
        updateMessageState(
          e.message,
          <ErrorOutlineIcon />,
          '#D8000C',
          '#FFD2D2'
        )
      } else {
        // const message =
        //   "Oh no something went wrong but we'll get it fixed asap. Please try again soon."
        const message = e.message
        updateMessageState(message, <ErrorOutlineIcon />, '#D8000C', '#FFD2D2')
      }
      setOpenLoader(false)
      setAppState(appState => ({
        ...appState,
        isAuthenticating: false,
        isLoading: false
      }))
    }
  }

  const { classes } = props
  return (
    !appState.isAuthenticating && (
      <div id="wrapper">
        <Header />
        <Container fixed className="container">
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            direction="row"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/* {!appState.isLoading ? ( */}
              <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper} elevation={3}>
                  {appState.fields.displayMessage ? (
                    <div
                      style={{
                        margin: [15, 0],
                        padding: 12,
                        color: appState.fields.color,
                        backgroundColor: appState.fields.backgroundColor,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      className={classes.infoMessage}
                    >
                      {appState.fields.icon}
                      {appState.fields.messageContent}
                    </div>
                  ) : null}
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography
                    variant="h5"
                    style={{ color: '#1957a6', fontWeight: 700 }}
                  >
                    Login to your account
                  </Typography>
                  {/* <img
                  alt="Cloud solar Logo"
                  src="../../images/logo.png"
                  align="center"
                /> */}
                  {/* <hr /> */}
                  <Dialog
                    style={{ fontColor: '#1070E9' }}
                    open={openLoader}
                    onClose={() => setOpenLoader(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle disableTypography>
                      <Typography
                        variant="h4"
                        style={{ color: '#1957a6', fontWeight: 700 }}
                      >
                        Logging in to CloudSolar...
                      </Typography>
                    </DialogTitle>

                    <DialogContent style={{ textAlign: 'center' }}>
                      <BeatLoader
                        size={50}
                        color={'#1070E9'}
                        loading={true}
                        css={override}
                      />
                    </DialogContent>
                  </Dialog>
                  <form onSubmit={handleSubmit}>
                    {/* <Divider
                    className={classes.submit}
                    variant="fullWidth"
                    light={false}
                  /> */}
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                    >
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <OutlinedInput
                        autoFocus
                        id="email"
                        name="email"
                        label="email"
                        type="email"
                        value={state.fields.email}
                        onChange={e =>
                          setState({
                            ...state,
                            fields: {
                              ...state.fields,
                              email: e.target.value.toLowerCase()
                            }
                          })
                        }
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      type="password"
                      value={state.fields.password}
                      onChange={e =>
                        setState({
                          ...state,
                          fields: {
                            ...state.fields,
                            password: e.target.value
                          }
                        })
                      }
                    >
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <OutlinedInput
                        name="password"
                        type="password"
                        id="password"
                        label="password"
                      />
                    </FormControl>
                    <Button
                      type="submit"
                      isloading={appState.isLoading ? 1 : 0}
                      disabled={!validateForm()}
                      fullWidth
                      variant="contained"
                      color="primary"
                      endIcon={<ExitToAppIcon>Log In</ExitToAppIcon>}
                      className={classes.submit}
                    >
                      Log In
                    </Button>
                    <ChangePasswordDialog
                      open={changePasswordOpen}
                      onClose={handleChangePasswordClose}
                      updateMessage={updateMessageState}
                      aria-labelledby="form-dialog-title"
                      user={user}
                      onSubmit={handleLoggedUser}
                    />
                    <Grid container>
                      <Grid item xs>
                        <Link to="#" onClick={handlePasswordOpen}>
                          Forgot password?
                        </Link>
                        <PasswordDialog
                          open={passwordOpen}
                          onClose={handlePasswordClose}
                          updateMessage={updateMessageState}
                          aria-labelledby="form-dialog-title"
                        />
                      </Grid>
                      <Grid item>
                        <Link to="/get-started/" variant="body2">
                          {"Don't have an account?"}
                        </Link>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </main>
              {/* ) : null} */}
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  )
}

export default withStyles(styles)(Login)
