import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
// import withStyles from '@material-ui/styles/withStyles'
import { Typography, Paper, Grid, MenuItem } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
// import Card from '@material-ui/core/Card'
import useStepper from '../../context/useStepper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow } from '@fortawesome/pro-light-svg-icons'
import { makeStyles } from '@material-ui/core/styles'
import { useAppContext } from '../../context/appContext'

const useStyles = makeStyles(theme => ({
  root: {},
  inputRoot: {
    '&$disabled': {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#1956a6'
    }
  },
  disabled: {}
}))

export default function Address(props) {
  const classes = useStyles()

  const { fields, updateFields, setState } = useStepper()
  const { appState } = useAppContext()

  useEffect(() => {
    const {
      address = '',
      city = '',
      // country,
      zipPostal = '',
      parishProvinceState = ''
    } = appState.user
    setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        address,
        city,
        // country,
        zipPostal,
        parishProvinceState
      }
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = e => {
    updateFields(e)
  }

  return (
    <div>
      {/* <CssBaseline /> */}

      <Paper style={{ padding: 20 }} elevation={3}>
        <Typography
          variant="h4"
          align="center"
          component="h1"
          style={{ color: '#1957a6' }}
          // gutterBottom
        >
          <FontAwesomeIcon icon={faLocationArrow} />
          {'   '}Your Address
        </Typography>
        <Typography
          variant="caption"
          align="center"
          component="h1"
          style={{ color: '#1957a6', marginBottom: 20 }}
          gutterBottom
        >
          *We're currently only open to residents of Barbados. Please{' '}
          <a href="https://www.cloud.solar/contact-us/">contact us </a>
          if you would like to get on our waiting list.
        </Typography>
        <Grid
          container
          alignItems="center"
          justifyContent={'center'}
          spacing={2}
          style={{ marginBottom: 10 }}
        >
          <Grid item xs={12} lg={8} xl={8} md={8}>
            <TextField
              autoFocus
              fullWidth
              required
              name="address"
              type="text"
              label="Address"
              variant="outlined"
              onChange={handleChange}
              value={fields.address}
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={8} md={8}>
            <TextField
              name="city"
              fullWidth
              required
              type="text"
              label="City"
              variant="outlined"
              onChange={handleChange}
              value={fields.city}
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={8} md={8}>
            <TextField
              select
              name="parishProvinceState"
              fullWidth
              required
              type="text"
              label="Parish"
              variant="outlined"
              onChange={handleChange}
              value={fields.parishProvinceState}
            >
              <MenuItem
                value="Christ Church"
                style={{ padding: 5, fontSize: 16 }}
              >
                Christ Church
              </MenuItem>
              <MenuItem value="St. Andrew" style={{ padding: 5, fontSize: 16 }}>
                St. Andrew
              </MenuItem>
              <MenuItem value="St. George" style={{ padding: 5, fontSize: 16 }}>
                St. George
              </MenuItem>
              <MenuItem value="St. James" style={{ padding: 5, fontSize: 16 }}>
                St. James
              </MenuItem>
              <MenuItem value="St. John" style={{ padding: 5, fontSize: 16 }}>
                St. John
              </MenuItem>
              <MenuItem value="St. Joseph" style={{ padding: 5, fontSize: 16 }}>
                St. Joseph
              </MenuItem>
              <MenuItem value="St. Lucy" style={{ padding: 5, fontSize: 16 }}>
                St. Lucy
              </MenuItem>
              <MenuItem
                value="St. Michael"
                style={{ padding: 5, fontSize: 16 }}
              >
                St. Michael
              </MenuItem>
              <MenuItem value="St. Peter" style={{ padding: 5, fontSize: 16 }}>
                St. Peter
              </MenuItem>
              <MenuItem value="St. Philip" style={{ padding: 5, fontSize: 16 }}>
                St. Philip
              </MenuItem>
              <MenuItem value="St. Thomas" style={{ padding: 5, fontSize: 16 }}>
                St. Thomas
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} lg={8} xl={8} md={8}>
            <TextField
              name="zipPostal"
              fullWidth
              required
              type="text"
              label="Postal Code"
              variant="outlined"
              onChange={handleChange}
              value={fields.zipPostal}
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={8} md={8}>
            <TextField
              fullWidth
              required
              disabled
              name="country"
              type="text"
              label="Country"
              variant="outlined"
              onChange={handleChange}
              value={fields.country}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled
                }
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
