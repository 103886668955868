import React, { useState } from 'react'
import { AppBar, Toolbar, Grid, Hidden, Container } from '@material-ui/core'
import { Link } from 'react-router-dom'
import HeaderMenuForMobile from './HeaderMenuForMobile'
import LoginHeader from './LoginHeader'
import './index.css'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: '#FFF',
    padding: theme.spacing(1),
    maxHeight: 75
    // marginTop: 10
  }
}))

export default function Header() {
  const [className] = useState('mainClass')

  const classes = useStyles()
  return (
    <>
      <div id="header" className={className}>
        {/* <div className="MuiContainer-root container MuiContainer-maxWidthLg"> */}
        {/* <div className="header-top"> */}
        <AppBar position="static" className={classes.appBar} elevation={1}>
          <Toolbar>
            <Container>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={9} sm={10} lg={3} xl={3} md={3}>
                  <Link to="/">
                    <img
                      className="menuLogo"
                      alt="logo"
                      src="../../images/logo.svg"
                    />
                  </Link>
                </Grid>
                <Hidden only={['sm', 'xs']}>
                  <Grid
                    className="mainNav"
                    item
                    xs={10}
                    sm={10}
                    lg={3}
                    xl={6}
                    md={6}
                  ></Grid>
                  <Grid
                    item
                    md={3}
                    container
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <div className="headerButton">
                      <LoginHeader />
                    </div>
                  </Grid>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                  <Grid item xs={3} sm={2} md={3} className="text-center">
                    <HeaderMenuForMobile />
                  </Grid>
                </Hidden>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </div>
    </>
  )
}
