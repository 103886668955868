import React, { useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, Grid, Paper } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { makeStyles } from '@material-ui/core/styles'
import { useAppContext } from '../../context/appContext'
import numeral from 'numeral'
import { Bar } from 'react-chartjs-2'
import format from 'date-fns/format'
import compareAsc from 'date-fns/compareAsc'
import parse from 'date-fns/parse'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '../common/FontAwesomeIcon'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 28,
    color: '#1957a6'
  },
  divider: {
    background: 'DodgerBlue',
    marginBottom: 20
  },
  faInfo: {
    fontSize: 20,
    color: 'Dodgerblue',
    position: 'absolute',
    right: 8,
    '@media (max-width:576px)': {
      fontSize: 30
    }
  }
}))

export default function HistoricalAccountValue() {
  const [selectedFromDate, setSelectedFromDate] = useState()
  const [selectedToDate, setSelectedToDate] = useState()
  const { appState } = useAppContext()
  const classes = useStyles()
  let labels = []
  const options = {
    maintainAspectRatio: true,
    legend: {
      display: true
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem) {
          return numeral(tooltipItem.yLabel).format('$0.00')
        }
      }
    }
  }

  let transactionsTotal = []
  let monthlyReturn = []
  let total = 0
  let months = []
  const transactions = appState.user.transactions
  const filterMonths = date => {
    return date >= selectedFromDate && date <= selectedToDate
  }
  const filterAmounts = unfilteredMap => {
    let date = null
    let filteredArray = []
    for (const [key, value] of unfilteredMap.entries()) {
      date = parse(value.period, 'M/dd/yyyy', new Date())
      if (date >= selectedFromDate && date <= selectedToDate) {
        filteredArray.push(value)
      }
    }
    filteredArray.reverse()
    return filteredArray
  }

  const res = Array.from(
    transactions.reduce(
      (m, { period, amount }) => m.set(period, (m.get(period) || 0) + amount),
      new Map()
    ),
    ([period, amount]) => ({ period, amount })
  )
  // console.log(res)
  let filteredMonths = []
  let lastReturnMonth
  let firstReturnMonth
  try {
    transactions.map(transaction => {
      let date = parse(transaction.period, 'M/dd/yyyy', new Date())
      months.push(date)
    })
    filteredMonths = months.filter(
      (date, i, self) =>
        self.findIndex(d => d.getTime() === date.getTime()) === i
    )
    filteredMonths.sort(compareAsc)
    firstReturnMonth = filteredMonths[0]
    lastReturnMonth = filteredMonths[filteredMonths.length - 1]
    labels = filteredMonths
      .filter(filterMonths)
      .map(m => format(new Date(m), 'MMM yy'))
    filterAmounts(res).map(e => {
      monthlyReturn.push(e.amount)
      transactionsTotal.push((total += e.amount))
    })
  } catch (e) {
    console.log(e)
  }
  useEffect(() => {
    setSelectedFromDate(firstReturnMonth)
    setSelectedToDate(lastReturnMonth)
  }, [])

  const data = {
    labels,
    datasets: [
      {
        label: 'Monthly Credits',
        data: monthlyReturn
      },
      {
        label: 'Solar Credits Total',
        data: transactionsTotal,
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(0,123,255,0.1)',
        borderColor: 'rgba(0,123,255,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(30,144,255 ,1 )',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: 'rgb(0,123,255)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 6,
        pointRadius: 5,
        pointHitRadius: 10
      }
    ]
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ marginBottom: 20 }}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} lg={12}>
          <Paper elevation={3}>
            <Card style={{ padding: 8, position: 'relative' }}>
              <Tooltip
                interactive
                enterTouchDelay={150}
                leaveTouchDelay={3000}
                arrow
                title="A graphical image of your total solar credits along with a breakdown by month"
              >
                <Icon icon={faInfoCircle} className={classes.faInfo} />
              </Tooltip>
              <CardHeader
                avatar={
                  <FontAwesomeIcon
                    icon={faChartLine}
                    style={{ fontSize: 36, color: 'Dodgerblue' }}
                  />
                }
                title="Historical Account Value"
                classes={{
                  title: classes.title
                }}
              />
              <Divider className={classes.divider} />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">
                  <KeyboardDatePicker
                    views={['year', 'month']}
                    label="From date"
                    margin="normal"
                    value={selectedFromDate}
                    onChange={setSelectedFromDate}
                  />
                  <KeyboardDatePicker
                    views={['year', 'month']}
                    label="To date"
                    margin="normal"
                    value={selectedToDate}
                    onChange={setSelectedToDate}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <CardContent>
                <TransactionChart data={data} options={options} />
              </CardContent>{' '}
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
const TransactionChart = ({ data, options }) => {
  return (
    <>
      <Bar data={data} options={options}></Bar>
    </>
  )
}
