import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from '@material-ui/core/styles'
import { BrowserRouter as Router } from 'react-router-dom'

// import App from './App'
import App from './App'
import Amplify from 'aws-amplify'
import config from './config/amplifyConfig'
import * as serviceWorker from './serviceWorker'
// import * as Sentry from '@sentry/browser'
// Sentry.init({
//   dsn:
//     'https://43e298926d3f46fcade47a7dbfcc22b5@o386841.ingest.sentry.io/5221543'
// })
import { initSentry } from './context/errorLib'

initSentry()

require('dotenv').config()
const theme = createTheme({
  palette: {
    primary: {
      main: '#1957a6'
    },
    secondary: {
      main: '#fbb116'
    }
  },
  typography: {
    fontFamily: 'Muli',
    useNextVariants: true
  }
})

responsiveFontSizes(theme)

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'contracts',
        endpoint: config.apiGateway.contractsURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'email',
        endpoint: config.apiGateway.emailURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'leads',
        endpoint: config.apiGateway.leadsURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'rates',
        endpoint: config.apiGateway.ratesURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'returns',
        endpoint: config.apiGateway.returnsURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'withdrawals',
        endpoint: config.apiGateway.withdrawalsURL,
        region: config.apiGateway.REGION
      }
    ]
  }
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
