import React, { useEffect, useMemo } from 'react'
import { Typography, Paper, Grid, CssBaseline } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import useStepper from '../../context/useStepper'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/pro-light-svg-icons'
import { useAppContext } from '../../context/appContext'
import { Bar } from 'react-chartjs-2'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import './Signup.css'

export default function SelectPanels() {
  const { fields, handleInvestmentChange, setState } = useStepper()
  const { appState } = useAppContext()
  const matches = useMediaQuery('(max-width:600px)')

  useEffect(() => {
    const { initialPanelAmount, initPurchaseAmount, initialKwp } = appState.user

    setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        panels: initialPanelAmount
          ? parseInt(initialPanelAmount, 10)
          : fields.panels,
        price: initPurchaseAmount
          ? parseFloat(initPurchaseAmount, 10)
          : fields.price,
        kWp: initialKwp ? parseFloat(initialKwp) : fields.kWp
      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const AmountSlider = createTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: '#52af77',
          height: 8
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: '#fff',
          border: '2px solid currentColor',
          marginTop: -8,
          marginLeft: -12,
          '&:focus,&:hover,&$active': {
            boxShadow: 'inherit'
          }
        },
        active: {},
        valueLabel: {
          left: 'calc(-50% + 14px)',
          top: -22,
          '& *': {
            background: 'transparent',
            color: '#000'
          }
        },
        track: {
          height: 8,
          borderRadius: 4
        },
        rail: {
          height: 8,
          borderRadius: 4
        }
      }
    }
  })

  const chartData = useMemo(() => {
    let data = [fields.dollarReturn]

    for (let i = 1; i < 25; i++) {
      const lastYear = data[i - 1]
      const minus = data[0] * 0.006 * i

      data.push(lastYear + (data[0] - minus))
    }
    return data
  }, [fields.dollarReturn])

  const data = {
    labels: Array(25)
      .fill()
      .map((_, index) => index + 1),
    fill: 'start',
    datasets: [
      {
        label: 'Projected 25 Year Return',
        data: chartData,
        backgroundColor: 'rgba(251,177,22,1)',
        borderColor: 'rgba(251,177,22,1)',
        pointBackgroundColor: '#ffffff',
        pointHoverBackgroundColor: 'rgb(251,177,22)',
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      }
    ]
  }

  const options = {
    // maintainAspectRatio: false,
    legend: {
      display: true
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem) {
          return numeral(tooltipItem.yLabel).format('$0,0.00')
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(label, index, labels) {
              return numeral(label).format('$0,0.0a')
            }
          },
          scaleLabel: {
            display: !matches,
            labelString: 'BBD $'
          }
        }
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: 'transparent'
          },
          scaleLabel: {
            display: !matches,
            labelString: 'Years'
          }
        }
      ]
    }
  }

  return (
    <div>
      <CssBaseline />
      <Paper
        style={{ padding: 16 }}
        // style={{ padding: 16, minHeight: 400, maxHeight: 400 }}
        elevation={3}
      >
        <Typography
          variant="h4"
          align="center"
          component="h1"
          style={{ color: '#1957a6', marginBottom: 40 }}
          gutterBottom
        >
          <FontAwesomeIcon icon={faDollarSign} />
          {'   '}Select Digital Solar Panels
          {/* <Divider className={classes.divider} /> */}
        </Typography>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item xs={12} lg={10} xl={10} md={10}>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: '#000', fontWeight: 600 }}
            >
              Selected number of panels
              {` ${fields.panels} | ${fields.kWp} kWp`}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ color: '#000', fontWeight: 600 }}
            >
              Price {numeral(fields.price).format('$0,0')}
            </Typography>
            <ThemeProvider theme={AmountSlider}>
              <Slider
                valueLabelDisplay="off"
                defaultValue={1}
                value={fields.panels}
                onChange={handleInvestmentChange('panels')}
                step={1}
                min={1}
                max={30}
              />
            </ThemeProvider>

            <div className="chart-step">
              <Bar data={data} options={options} />
            </div>

            <Grid
              item
              style={{
                marginTop: 16,
                alignContent: 'left',
                alignItems: 'left'
              }}
            >
              <Typography
                variant="body1"
                gutterBottom
                style={{ color: '#000', fontWeight: 600 }}
              >
                Estimated Monthly Solar Credits:{' '}
                {numeral(fields.dollarReturn / 12).format('$0,0.00')}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                style={{ color: '#000', fontWeight: 600 }}
              >
                Estimated Year 1 Solar Credits:{' '}
                {numeral(fields.dollarReturn).format('$0,0.00')}
              </Typography>
              <div style={{ marginBottom: 20 }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ color: '#000', fontWeight: 600 }}
                >
                  *Projected Return:{' '}
                  {numeral(fields.percentageReturn).format('0.00%')}
                </Typography>

                <Typography
                  variant="subtitle2"
                  style={{ fontSize: '0.675rem' }}
                >
                  <p>
                    *This projected return is indicative only, and is not a
                    guaranteed or a minimum return.
                  </p>
                  <p>
                    Solar credits paid to a client via their dashboard are from
                    a licensed pool of solar panels owned by multiple investors,
                    which are likely to be located on several licensed sites.
                  </p>
                  <p>
                    The actual output pooled from each site is affected by
                    weather, climate, orientation, energy production achieved
                    and the other risks which are site dependent.
                  </p>
                  <p>
                    The solar credits paid to a client are not set to any one
                    site - it is intended that the credits from each site will
                    be pooled to minimise a clients' exposure to any one site.
                    Also, the solar credits will differ from year to year,
                    particularly as the output of panels degrades slightly from
                    year to year; and also depending on the level of new
                    installs CloudSolar undertakes to expand its solar pool.
                  </p>
                  <p>
                    The projected return calculated on our web site pages is
                    based on the number of solar panels purchased at the
                    indicated purchase price for a service contract life of 25
                    years; with a system energy output detailed in our pool
                    performance page at a specific nominal kWh per month per 1
                    kWp of solar panels purchased. Solar pool performance is
                    based on actual power sales data from installed solar sites,
                    that are positioned in different orientations with a 10 - 15
                    degrees axis tilt, with 100% of the system output sold at
                    the exisitng Feed In Tariff (FIT) rates, and 100%
                    availability of all systems (unless a system is
                    decommissioned whereby its output is not used in the
                    calculation of the solar pool); solar panel degradation
                    calculated at 0.6% per annum, and with management,
                    operations and maintenance costs of 20% of gross solar
                    revenue deducted from the clients' output calculations.
                  </p>
                  <p>
                    The actual earnings from each site will differ from the
                    above calculations with minimal risk, and CloudSolar
                    believes this is a reasonable indicative forecast of the
                    potential returns from the solar pool based on historic and
                    currently available information. It should be noted that
                    past performance is not indicative of future performance.
                  </p>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

// InvestmentLevel.propTypes = {
//   classes: PropTypes.object.isRequired
// }
