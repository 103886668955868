import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography
} from '@material-ui/core'
import { useAppContext } from '../../context/appContext'
import numeral from 'numeral'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faReceipt } from '@fortawesome/pro-light-svg-icons'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '../common/FontAwesomeIcon'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 8,
    minHeight: 254,
    position: 'relative'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 26,
    color: '#1957a6'
  },
  contract: {
    fontSize: 16,
    color: '#1957a6',
    marginBottom: 10
  },
  pos: {
    marginBottom: 12
  },
  faIcon: {
    fontSize: 32,
    color: 'Dodgerblue'
  },
  faInfo: {
    fontSize: 20,
    color: 'Dodgerblue',
    position: 'absolute',
    right: 8,
    '@media (max-width:576px)': {
      fontSize: 30
    }
  },
  colorDefault: {
    backgroundColor: 'Dodgerblue'
  },
  small: {
    fontSize: 12,
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  divider: {
    background: 'DodgerBlue',
    marginBottom: 20
  }
}))

export default function ListTransactions() {
  const { appState } = useAppContext()

  const classes = useStyles()
  return (
    <div>
      <Paper style={{ minHeight: 216 }} elevation={3}>
        <Card className={classes.root} elevation={0}>
          <Tooltip
            interactive
            enterTouchDelay={150}
            leaveTouchDelay={3000}
            title="The most recent transactions in your portfolio with the posting dates"
          >
            <Icon icon={faInfoCircle} className={classes.faInfo} />
          </Tooltip>
          <CardHeader
            avatar={
              <FontAwesomeIcon icon={faReceipt} className={classes.faIcon} />
            }
            title="Transactions"
            classes={{
              title: classes.title
            }}
          />
          <Divider className={classes.divider} />
          <CardContent>
            {appState.user.transactions !== undefined &&
            appState.user.transactions.length > 0 ? (
              <List dense>
                {appState.user.transactions
                  .slice(0, 6)
                  .map((transaction, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar
                          style={{ marginLeft: -20 }}
                          className={classes.small}
                          classes={{
                            colorDefault: classes.colorDefault
                          }}
                        >
                          {index + 1}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ marginLeft: -20 }}
                        primary={numeral(transaction.amount).format('$0,0.00')}
                        secondary={[
                          'Posted on: ',
                          <Moment format="MM/DD/YYYY" key={index}>
                            {transaction.dateCompleted}
                          </Moment>
                        ]}
                      />
                    </ListItem>
                  ))}
              </List>
            ) : (
              <>
                <Typography variant="body1">
                  Your first transaction will be posted to your account on the
                  21st business day of the following month after your account
                  has been activated.
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
      </Paper>
    </div>
  )
}
