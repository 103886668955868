import React, { useEffect, useState } from 'react'
import { API, Auth } from 'aws-amplify'
import { withRouter } from 'react-router-dom'
import Routes from './Routes'
import { AppContext } from './context/appContext'
import { StepperProvider } from './context/StepperContext'
import { DashboardProvider } from './context/DashboardContext'
import ErrorBoundary from './components/errorBoundary/ErrorBoundary'
import BeatLoader from 'react-spinners/BeatLoader'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Layout from './Layout'
import './App.css'
import './css/Fonts.css'
import './css/res.css'

export const initAppState = {
  cognitoUser: {},
  update: false,
  isAuthenticating: true,
  isLoading: true,
  showPurchaseAlert: false,
  withdrawalAmount: 0,
  stepLoading: false,
  user: {
    isAuthenticated: false,
    isSignedUp: false,
    isConfirmed: false,
    accountMessage: '',
    sub: '',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    parishProvinceState: '',
    country: '',
    zipPostal: '',
    phone: '',
    email: '',
    password: '',
    idUrl: '',
    idVerified: false,
    idFiileType: '',
    residencyFileType: '',
    activeStep: 99,
    activeSubStep: 0,
    UserNotConfirmedException: false,
    bankAccountName: '',
    bankAccountNumber: '',
    residencyFileUrl: '',
    bankName: '',
    bankLocation: '',
    bankLocations: [],
    bankBranch: '',
    bankTerms: '',
    bankInfoSkipped: false,
    bankVerified: false,
    bankAccountVerified: false,
    initPurchaseAmount: 0,
    initialPanelAmount: 0,
    initialKwp: 0,
    panelLimit: 0,
    initialPricePerKw: 0,
    panelTotal: 0,
    creditsTotal: 0,
    kwpTotal: 0,
    returnsTotal: 0,
    contracts: [],
    transactions: [],
    contractsTotal: 0,
    returncount: 0,
    returnPercentage: 0,
    chartCreditsTotal: 0
  },
  fields: {
    messageContent: '',
    icon: '',
    color: '',
    backgroundColor: '',
    displayMessage: false
  }
}

function App() {
  const [appState, setAppState] = useState(initAppState)

  const updateMessageState = (message, icon, color, backgroundColor) => {
    setAppState(appState => ({
      ...appState,
      fields: {
        ...appState.fields,
        messageContent: message,
        icon,
        color,
        backgroundColor,
        displayMessage: true
      }
    }))
  }

  const hideMessageState = () => {
    setAppState(appState => ({
      ...appState,
      fields: {
        displayMessage: false,
        messageContent: '',
        icon: '',
        color: '',
        backgroundColor: ''
      }
    }))
  }

  const goToSteps = (step = 0, subStep = 0) => {
    setAppState(appState => ({
      ...appState,
      user: {
        ...appState.user,
        activeStep: step,
        activeSubStep: subStep
      }
    }))
  }

  const setStepLoading = () => {
    setAppState(appState => ({
      ...appState,
      stepLoading: true
    }))
  }

  const hideStepLoading = () => {
    setAppState(appState => ({
      ...appState,
      stepLoading: false
    }))
  }

  async function getContracts() {
    try {
      let contracts = await API.get('contracts', '/contracts/user')
      return contracts.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    } catch (e) {
      console.log(e)
      updateMessageState(
        e.message,
        <ErrorOutlineIcon></ErrorOutlineIcon>,
        '#D8000C',
        '#FFD2D2'
      )
      return []
    }
  }

  function getTransactions(userName) {
    try {
      return API.get('returns', `/returns/getTransactionsForUser/${userName}`)
    } catch (e) {
      updateMessageState(
        e.message,
        <ErrorOutlineIcon></ErrorOutlineIcon>,
        '#D8000C',
        '#FFD2D2'
      )
    }
  }

  async function onLoad() {
    let transactionsTotal = 0
    let returnPercentage = 0
    let listContracts = []
    let listTransactions = []
    try {
      const authUser = await Auth.currentAuthenticatedUser({
        bypassCache: true
      })
      if (authUser) {
        const user = authUser['attributes']
        //Temporarily removing this. Will see how it behaves if you refresh or login while at earlier steps
        // if (user['custom:signupCompleted']) {
        listContracts = await getContracts()
        listTransactions = await getTransactions(user.sub)
        let contractIds = []
        let returnIds = []
        let contractsTotal = 0
        let groupedTransactions = {}

        //group transactions by contract
        if (listTransactions.length > 0) {
          listTransactions.forEach(transaction => {
            if (transaction.contractId in groupedTransactions) {
              groupedTransactions[transaction.contractId].push(
                transaction.amount
              )
            } else {
              groupedTransactions[transaction.contractId] = [transaction.amount]
            }
            returnIds.push(transaction.period)
            contractIds.push(transaction.contractId)
            transactionsTotal += parseFloat(transaction.amount)
          })
        }
        //calculate contract totals
        let contractObjectTotals = {}
        if (listContracts !== undefined) {
          listContracts.forEach(contract => {
            if (contract.contractId in groupedTransactions) {
              contractObjectTotals[contract.contractId] = parseInt(
                contract.amount
              )
            }
          })
          //calculate returns
          let returnPercentages = []
          for (const returnAmount in groupedTransactions) {
            let contractTotal = 0
            for (const contractId in contractObjectTotals) {
              if (returnAmount === contractId) {
                contractTotal = contractObjectTotals[contractId]
              }
            }
            returnPercentages.push(
              (((groupedTransactions[returnAmount].reduce((a, b) => a + b, 0) /
                groupedTransactions[returnAmount].length) *
                12) /
                contractTotal) *
                100
            )
          }

          returnPercentage =
            returnPercentages.length === 1
              ? returnPercentages[0]
              : returnPercentages.reduce((a, b) => a + b, 0) /
                returnPercentages.length
        }

        setAppState(appState => ({
          ...appState,
          isLoading: false,
          isAuthenticating: false,
          stepLoading: false,
          user: {
            ...appState.user,
            isAuthenticated: true,
            isConfirmed: user.email_verified,
            accountMessage: user['custom:accountMessage'],
            sub: user.sub || '',
            firstName: user.given_name,
            lastName: user.family_name,
            address: user.address,
            city: user['custom:city'],
            parishProvinceState: user['custom:parishProvinceState'],
            country: user['custom:country'],
            zipPostal: user['custom:zipPostal'],
            phone: user.phone_number,
            email: user.email,
            idUrl:
              user['custom:idUrl'] !== undefined ? user['custom:idUrl'] : '',
            idVerified:
              user['custom:idVerified'] !== undefined
                ? user['custom:idVerified']
                : false,
            idFileType: user['custom:idFileType'],
            residencyFileType: user['custom:residencyFileType'],
            activeStep:
              user['custom:activeStep'] === undefined ||
              user['custom:activeStep'] === null
                ? 0
                : parseInt(user['custom:activeStep']),
            activeSubStep: user.UserNotConfirmedException
              ? 1
              : parseInt(user['custom:activeStep']) > 0 &&
                parseInt(user['custom:activeStep']) <= 5
              ? 99
              : user['custom:activeSubStep'] === undefined ||
                user['custom:activeSubStep'] === null
              ? 0
              : parseInt(user['custom:activeSubStep']),
            // updateMessageState,
            isSignedUp: user['custom:signupCompleted'] || false,
            bankAccountName: user['custom:bankAccountName'] || '',
            bankAccountNumber: user['custom:bankAccountNumber'] || '',
            bankAccountType: user['custom:bankAccountType'] || '',
            residencyFileUrl:
              user['custom:residencyFileUrl'] !== undefined
                ? user['custom:residencyFileUrl']
                : '',
            bankName: user['custom:bankName'] || '',
            bankLocation: user['custom:bankLocation'] || '',
            bankBranch: user['custom:bankBranch'] || '',
            bankCode: user['custom:bankCode'] || '',
            bankTerms: user['custom:bankTerms'] || false,
            initialPricePerKw: user['custom:initialPricePerKw'] || 5700,
            initialPanelAmount: user['custom:initialPanelAmount'],
            initPurchaseAmount: user['custom:initPurchaseAmount'],
            initialKwp: user['custom:initialKwp'],
            bankInfoSkipped: user['custom:bankInfoSkipped'] || true,
            bankVerified: user['custom:bankVerified'],
            bankAccountVerified:
              user['custom:bankAccountVerified'] !== undefined
                ? user['custom:bankAccountVerified']
                : false,
            panelLimit: parseInt(user['custom:panelLimit']),
            kwpTotal: user['custom:totalKwp'] || 0.0,
            returnsTotal: transactionsTotal,
            contracts: listContracts,
            transactions: listTransactions.length > 0 ? listTransactions : [],
            contractsTotal: contractsTotal || 0,
            totalContracts: user['custom:totalContracts'] || 1,
            returnPercentage: returnPercentage || 0,
            panelTotal: user['custom:totalPanels'] || 0,
            creditsTotal: user['custom:creditsTotal'] || 0
            // chartCreditsTotal: chartCreditsTotal
          }
        }))
      }
    } catch (e) {
      console.log(e)
      setAppState(appState => ({
        ...appState,
        user: {
          ...appState.user,
          isAuthenticated: false
        },
        isLoading: false,
        isAuthenticating: false
      }))
      // if (e !== 'not authenticated') {
      //   updateMessageState(
      //     e.message,
      //     <ErrorOutlineIcon></ErrorOutlineIcon>,
      //     '#D8000C',
      //     '#FFD2D2'
      //   )
      // }
    }
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.update])

  return appState.isAuthenticating | appState.isLoading ? (
    <>
      <div style={{ textAlign: 'center', paddingTop: '200px' }}>
        <BeatLoader size={35} color={'DodgerBlue'} />
      </div>
    </>
  ) : (
    <div>
      <ErrorBoundary>
        <AppContext.Provider
          value={{
            appState,
            setAppState,
            updateMessageState,
            hideMessageState,
            goToSteps,
            setStepLoading,
            hideStepLoading
          }}
        >
          <StepperProvider>
            <DashboardProvider>
              <Layout>
                <Routes />
              </Layout>
            </DashboardProvider>
          </StepperProvider>
        </AppContext.Provider>
      </ErrorBoundary>
    </div>
  )
}

export default withRouter(App)
