import React, { useState } from 'react'
import { Card, Divider, Paper, Typography, CardHeader } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faExclamationCircle,
  faLandmark
} from '@fortawesome/pro-light-svg-icons'
import { useAppContext } from '../../../context/appContext'
import { green, orange } from '@material-ui/core/colors'
import DisplayBankInfo from './displayBankInfo'
import EditBankAccount from './editBankAccount'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'DodgerBlue',
    marginBottom: 20
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170',
    padding: 16
    // minHeight: 130
  },
  title: {
    fontSize: 22,
    color: 'DodgerBlue'
  },
  faIcon: {
    fontSize: 40,
    color: 'DodgerBlue'
  },
  faIconVerified: {
    fontSize: 40,
    color: green[500]
  },
  faIconNotVerified: {
    fontSize: 40,
    color: orange[500]
  }
}))

export default function Bank(props) {
  const { appState } = useAppContext()
  const bBankInfoVerified = appState.user.bankAccountVerified === 'bankVerified'
  const [edit, setEdit] = useState({
    on: false,
    text: 'Need to edit your bank account info?'
  })

  const classes = useStyles()

  return (
    <>
      <Paper elevation={3}>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <FontAwesomeIcon icon={faLandmark} className={classes.faIcon} />
            }
            title="Bank Info"
            classes={{
              title: classes.title
            }}
          />
          <Divider className={classes.root} />
          <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
            <FontAwesomeIcon
              icon={bBankInfoVerified ? faCheck : faExclamationCircle}
              className={
                bBankInfoVerified
                  ? classes.faIconVerified
                  : classes.faIconNotVerified
              }
            />{' '}
            {bBankInfoVerified
              ? 'Your bank info has been verified'
              : 'We are in the process of verifying your bank account.'}
          </Typography>
          <Typography variant="body2" gutterBottom style={{ marginBottom: 20 }}>
            {edit.text}
            {'  '}
            {!edit.on ? (
              <EditIcon
                onClick={() =>
                  setEdit(state => ({
                    on: !state.on,
                    text: 'Cancel edits'
                  }))
                }
                style={{ marginTop: -20 }}
              />
            ) : (
              <CloseIcon
                onClick={() =>
                  setEdit(state => ({
                    on: !state.on,
                    text: 'Need to edit your bank account info?'
                  }))
                }
                style={{ marginTop: -20 }}
              />
            )}
          </Typography>
          <>
            {edit.on ? (
              <EditBankAccount
                closeEdit={() =>
                  setEdit(state => ({
                    on: !state.on,
                    text: 'Need to edit your bank account info?'
                  }))
                }
              />
            ) : (
              <DisplayBankInfo />
            )}
          </>
        </Card>
      </Paper>
    </>
  )
}
