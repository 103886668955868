import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import AppliedRoute from './components/auth/AppliedRoute'
import AuthenticatedRoute from './components/auth/AuthenticatedRoute'
import UnauthenticatedRoute from './components/auth/UnauthenticatedRoute'
// import RedirectSignUpRoute from './components/auth/RedirectSignUpRoute'
// import Dashboard from './components/dashboard'
import Dashboard from './components/dashboard'
import Contracts from './components/dashboard/Contracts'
import Profile from './components/dashboard/Profile'
import Login from './components/login'
import PasswordDialog from './components/dialogs/PasswordDialog'
import SignupStepper from './components/signup/Stepper'
import NotFound from './components/not-found'
import Privacy from './components/terms/Privacy'
import Terms from './components/terms/Terms'
import DSA from './components/terms/DSA'
import PAD from './components/terms/PAD'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

const Scroll = withRouter(ScrollToTop)

export default function Routes() {
  return (
    <Scroll>
      <Switch>
        <AuthenticatedRoute exact path="/" component={Dashboard} />
        <UnauthenticatedRoute exact path="/login/" component={Login} />
        <UnauthenticatedRoute
          exact
          path="/reset-password/"
          component={PasswordDialog}
        />
        <AppliedRoute exact path="/get-started/" component={SignupStepper} />
        <AuthenticatedRoute exact path="/contracts/" component={Contracts} />
        <AuthenticatedRoute exact path="/profile/" component={Profile} />
        <AppliedRoute exact path="/privacy/" component={Privacy} />
        <AppliedRoute exact path="/terms/" component={Terms} />
        <AppliedRoute exact path="/dsa/" component={DSA} />
        <AppliedRoute exact path="/pad/" component={PAD} />
        <Route component={NotFound} />
      </Switch>
    </Scroll>
  )
}
