import React, { useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Paper,
  Typography,
  CardHeader
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faExclamationCircle,
  faPassport
} from '@fortawesome/pro-light-svg-icons'
import useStepper from '../../../context/useStepper'
import { useAppContext } from '../../../context/appContext'
import { Auth } from 'aws-amplify'
import swal from '@sweetalert/with-react'
import { green, orange } from '@material-ui/core/colors'
import { Widget } from '@uploadcare/react-widget'
import { pdfjs, Document, Page } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const useStyles = makeStyles(theme => ({
  root: {
    background: 'DodgerBlue',
    marginBottom: 20
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170',
    padding: 16
    // minHeight: 130
  },
  pdfDocument: {
    display: 'flex',
    justifyContent: 'center',
    margin: 0
  },
  title: {
    fontSize: 22,
    color: 'DodgerBlue'
  },
  faIcon: {
    fontSize: 40,
    color: 'DodgerBlue'
  },
  faIconVerified: {
    fontSize: 40,
    color: green[500]
  },
  faIconNotVerified: {
    fontSize: 40,
    color: orange[500]
  }
}))

export default function Id(props) {
  const [
    ,
    // numPages
    setNumPages
  ] = useState(null)
  const [
    pageNumber
    // setPageNumber
  ] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  const { appState, setAppState } = useAppContext()
  const { sendEmail } = useStepper()
  const [state, setState] = useState({
    hideButton: true,
    idUrl: '',
    idFileType: ''
  })

  const bIdVerified = appState.user.idVerified === 'idVerified'

  const handleChange = async info => {
    setState(state => ({
      ...state,
      hideButton: false,
      idUrl: info.cdnUrl,
      idFileType: info.mimeType
    }))
  }

  const updateId = async id => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      const updatedUser = await Auth.updateUserAttributes(currentUser, {
        'custom:idUrl': state.idUrl,
        'custom:idFileType': state.idFileType,
        'custom:accountMessage': ''
      })
      if (updatedUser === 'SUCCESS') {
        setAppState(appState => ({
          ...appState,
          user: {
            ...appState.user,
            idUrl: state.idUrl,
            idFileType: state.idFiletype
          },
          update: !state.update
        }))
        setState(state => ({
          ...state,
          hideButton: true,
          idUrl: ''
        }))
        swal('Success!', 'Your id has been updated.', 'success')
        const data = {
          name: `${appState.user.firstName} ${appState.user.lastName}`,
          message: `${appState.user.firstName} ${appState.user.lastName} just uploaded a replacement ID. Please verify ASAP.`,
          type: 'userAccountUpdated'
        }
        await sendEmail(data)
      } else {
        swal(
          'Oh no!',
          "Something went wrong. We'll look into the issue as soon as possible.",
          'error'
        )
      }
    } catch (e) {
      swal(
        'Oh no!',
        "Something went wrong. We'll look into the issue as soon as possible.",
        'error'
      )
    }
  }

  const classes = useStyles()

  return (
    <>
      <Paper elevation={3}>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <FontAwesomeIcon icon={faPassport} className={classes.faIcon} />
            }
            title="Identification"
            classes={{
              title: classes.title
            }}
          />
          <Divider className={classes.root} />
          <Typography variant="h6" gutterBottom>
            <FontAwesomeIcon
              icon={bIdVerified ? faCheck : faExclamationCircle}
              className={
                bIdVerified ? classes.faIconVerified : classes.faIconNotVerified
              }
            />{' '}
            {bIdVerified
              ? 'Your identification has been verified'
              : "We haven't verified your identification yet"}
          </Typography>
          {!bIdVerified ? (
            <>
              <Typography
                variant="body1"
                align="left"
                gutterBottom
                style={{ marginBottom: 20 }}
              >
                Need to upload a replacement identification?{' '}
                <Widget
                  publicKey="85f4e36ea46be8ad63f0"
                  id="file"
                  previewStep="true"
                  crop
                  onChange={info => handleChange(info)}
                  tabs="file url gdrive gphotos dropbox evernote onedrive box"
                  // clearable
                />
              </Typography>
            </>
          ) : (
            <></>
          )}
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => updateId()}
            className={classes.nextButton}
            style={{
              visibility: state.hideButton ? 'hidden' : 'visible',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            Update my ID
          </Button>
          <CardContent>
            {appState.user.idFileType === 'application/pdf' ? (
              <Document
                file={appState.user.idUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                className={classes.pdfDocument}
              >
                <Page pageNumber={pageNumber} scale="0.9" renderMode="svg" />
              </Document>
            ) : (
              <>
                <img src={appState.user.idUrl} alt="ID" />
              </>
            )}
          </CardContent>
        </Card>
      </Paper>
    </>
  )
}
