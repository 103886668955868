import React, { useState } from 'react'
import { Button, TextField, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useStepper from '../../../context/useStepper'
import { useAppContext } from '../../../context/appContext'
import { red } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  loadingBlock: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#fff',
    cursor: 'wait',
    opacity: 0.6,
    zIndex: 2
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  redTxt: {
    color: red[500]
  }
}))

export default function DialogVerifyEmail({
  open = false,
  toggle,
  showSuccess,
  clickToVerify
}) {
  const classes = useStyles()
  const { sendEmail } = useStepper()

  const { appState, setAppState } = useAppContext()

  const [isLoading, setIsLoading] = useState(false)
  const [code, setCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorRes, setErrorRes] = useState(null)

  const resetState = () => {
    setIsLoading(false)
    setCode('')
    setErrorMessage('')
    setErrorRes(null)
  }

  const onHandleVerify = async () => {
    setErrorRes(null)
    if (!code?.length) {
      setErrorMessage('Please enter verify code.')
      return
    }
    setIsLoading(true)
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code)
      const data = {
        email: appState.user.email,
        name: appState.user.firstName,
        type: 'emailVerified'
      }
      await sendEmail(data)
      setAppState({
        ...appState,
        user: {
          ...appState.user,
          isConfirmed: true
        }
      })
      resetState()
      showSuccess()
      toggle()
    } catch (error) {
      setIsLoading(false)
      setErrorRes(error)
    }
  }

  const onHandleResendCode = async () => {
    setErrorRes(null)
    setIsLoading(true)
    try {
      await Auth.verifyCurrentUserAttribute('email')
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setErrorRes(error)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (isLoading) {
          return
        }
        toggle()
        resetState()
      }}
      aria-labelledby="verify-your-email-address"
    >
      {isLoading ? (
        <div className={classes.loadingBlock}>
          <CircularProgress size={24} className={classes.buttonProgress} />
        </div>
      ) : null}
      <DialogTitle id="form-dialog-title">
        Verify Your Email Address
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please check your email inbox to get your verification code and type
          here:
        </DialogContentText>

        {errorRes?.message ? (
          <DialogContentText className={classes.redTxt}>
            {errorRes.message}
          </DialogContentText>
        ) : null}

        <TextField
          autoFocus
          margin="dense"
          label="Verify code"
          type="text"
          fullWidth
          required
          name="confirmationCode"
          onChange={event => {
            setCode(event.target.value)
            setErrorMessage('')
          }}
          value={code}
          disabled={isLoading}
          error={!!errorMessage?.length}
          helperText={errorMessage?.length ? errorMessage : ''}
        />

        <Button
          onClick={onHandleResendCode}
          color="primary"
          disabled={isLoading}
        >
          Resend code
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            toggle()
            resetState()
          }}
          color="primary"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button onClick={onHandleVerify} color="primary" disabled={isLoading}>
          Verify
        </Button>
      </DialogActions>
    </Dialog>
  )
}
