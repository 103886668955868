import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Fix issue Tooltip and FontAwesomeIcon:
// https://github.com/FortAwesome/react-fontawesome/issues/199
export const Icon = React.forwardRef((props, ref) => {
  return <FontAwesomeIcon forwardedRef={ref} {...props}/>;
});

export default Icon;
